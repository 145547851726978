import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/primeng';
import { ChargeType, CreditorFinancialDetail, CreditorChargeTypeDetails , TranType } from '../../models/financials';
import { TaxType } from '../../models/TariffCode';
import { BusinessEntity, Role , Currency, Creditor } from 'src/app/digi-business-entity/models/business-entity';
import { NotificationService } from '../../services/notification.service';
import { BusinessEntityService } from '../../../digi-business-entity/services/business-entity.service';
import { TaxTypeService } from '../../services/tax-type.service';
import { ChargeTypeService } from '../../services/charge-type.service';
import { TranTypeService } from '../../services/tran-type.service';
import { InvoiceService } from '../../services/invoice.service';
import { ClearingFile } from '../../models/clearingFile';
import { ExportFile } from '../../models/exportFile';
import { ExBondFile } from '../../models/exBondFile';
import { ValidationResult } from '../../services/validation.service';
import { AuthorisationService } from '../../../../subjects/authorisation-behaviour-subject';
import { ClearingFileService } from '../../services/clearing-file.service';
import { ExportFileService } from '../../services/export-file.service';
import { ExBondFileService } from '../../../digi-exbond/services/ex-bond-file.service';
import {CompanyService} from '../../services/company.service';

@Component({
  selector: 'digi-creditors-capture',
  templateUrl: './creditors-capture.component.html',
  styles: [`
    .ui-steps .ui-steps-item {
      width: 25%;
    }`],
  encapsulation: ViewEncapsulation.None

})
export class CreditorsCaptureComponent implements OnInit, OnDestroy {
  taxTypeSubscription: Subscription;
  chargeTypeSubscription: Subscription;
  tranTypeSubscription: Subscription;
  creditorParentEntity: BusinessEntity;
  creditorEntity: Creditor;
  searchFileType: string;
  searchedFile: ClearingFile | ExportFile | ExBondFile;
  searchedMawbRef: string;
  selectedDocumentNumber: string;
  filteredSearchFiles: any[];
  filteredCreditorEntities = [];
  filteredTaxTypes: any[];
  filteredTranTypes: any[];
  filteredChargeTypes: any[];
  cols: any[];
  fileTypeOptions: any[];
  generatedFiles: any[];
  selectedCreditorFinancialDetail: CreditorFinancialDetail;
  chargeTypeDetailsSelection: CreditorChargeTypeDetails[];
  selectedChargeTypeDetails: CreditorChargeTypeDetails;
  display = false;
  waybillSearch = false;
  disableButton: boolean;
  vat: number;
  private invoicesSubscription: Subscription;
  private branchCode: string;

  constructor(private router: Router,
              private businessEntityService: BusinessEntityService,
              private taxTypeService: TaxTypeService,
              private tranTypeService: TranTypeService,
              private clearingFileService: ClearingFileService,
              private exportFileService: ExportFileService,
              private exBondFileService: ExBondFileService,
              private chargeTypeService: ChargeTypeService,
              private messageService: NotificationService,
              private authorisationService: AuthorisationService,
              private companyService: CompanyService,
              private invoiceService: InvoiceService) {
  }

  ngOnInit() {
    this.fileTypeOptions = [
          {'label': '', 'value': ''}, {'label': 'Clearing', 'value': 'clearing'}, {'label': 'Export', 'value': 'export'}, {'label': 'ExBond', 'value': 'exbond'}
          ];
    this.cols = [
          {header: 'Invoice No.'},
          {header: 'Charge Type', width: '17%'},
          {header: 'Charge Amount'},
          {header: 'Captured Amount'},
          {header: 'Invoice VAT'},
          {header: '', width: '9%'}
        ];

    this.getCompanyVat();
    this.selectedCreditorFinancialDetail = new CreditorFinancialDetail();
  }

  confirm() {
    this.selectedCreditorFinancialDetail.confirmed = true;
    this.saveCreditorEntity();
  }

  generateCreditorsInvoiceReport() {
    let currentUser = "";
    this.authorisationService.getLoggedInUser().subscribe(user => currentUser = user.firstName + " " + user.lastName)

    this.disableButton = true;
    this.invoicesSubscription = this.invoiceService.generateCreditorsInvoiceReport(this.creditorParentEntity.code, currentUser, this.selectedCreditorFinancialDetail.documentNumber).subscribe(data => {
      this.generatedFiles = data._embedded.supportingDocuments;
      this.showDialog();
      this.disableButton = false;
    }, error => {
      this.disableButton = false;
      this.messageService.errorNotify(error.error.message, error.error.errors);
    });
  }

  generatePaymentRequisitionReport() {
    let currentUser = "";
    this.authorisationService.getLoggedInUser().subscribe(user => currentUser = user.firstName + " " + user.lastName)

    this.disableButton = true;
    this.invoicesSubscription = this.invoiceService.generatePaymentRequisitionReport(this.creditorParentEntity.code, currentUser, this.selectedCreditorFinancialDetail.documentNumber).subscribe(data => {
      this.generatedFiles = data._embedded.supportingDocuments;
      this.showDialog();
      this.disableButton = false;
    }, error => {
      this.disableButton = false;
      this.messageService.errorNotify(error.error.message, error.error.errors);
    });
  }

  onRowDelete(rowIndex: number) {
    this.chargeTypeDetailsSelection.splice(rowIndex, 1);
    this.chargeTypeDetailsSelection = [...this.chargeTypeDetailsSelection];
    if (this.chargeTypeDetailsSelection.length === 0) {
      this.addNewChargeTypeDetails();
    } else {
      this.selectedChargeTypeDetails = this.chargeTypeDetailsSelection[0];
    }
    this.selectedCreditorFinancialDetail.creditorChargeTypeDetails = this.chargeTypeDetailsSelection;
    this.calculateBalanceAmounts();
  }

  addNewChargeTypeDetails() {
    this.createNewChargeTypeDetails();
    if (this.chargeTypeDetailsSelection) {
      this.chargeTypeDetailsSelection.push(this.selectedChargeTypeDetails);
    } else {
      this.chargeTypeDetailsSelection = [this.selectedChargeTypeDetails];
    }
    this.selectedCreditorFinancialDetail.creditorChargeTypeDetails = this.chargeTypeDetailsSelection;
    this.clearSearchCriteria();
  }

  createNewChargeTypeDetails() {
    this.searchFileType = '';
    this.filteredSearchFiles = [];
    this.searchedFile = null;
    this.selectedChargeTypeDetails = new CreditorChargeTypeDetails();
    this.selectedChargeTypeDetails.taxType = this.selectedCreditorFinancialDetail.defaultTaxType;
    this.selectedChargeTypeDetails.invoiceNumber = 'N.A.'
    this.tranTypeSubscription = this.tranTypeService.findTranTypesByCodeStartWith('dis').subscribe(tranTypes => {
      this.selectedChargeTypeDetails.tranType = tranTypes[0];
    });
  }

  showDialog() {
    this.display = true;
  }

  updateBalanceTotal() {
    this.selectedCreditorFinancialDetail.balanceTotalValue = this.selectedCreditorFinancialDetail.totalInvoiceValue;
    this.calculateBalanceAmounts()
  }

  calculateBalanceAmounts() {
    if (this.selectedChargeTypeDetails && this.selectedChargeTypeDetails.taxType && this.selectedChargeTypeDetails.taxType.code.trim() === 'VAT') {
      if (!this.vat) {
        this.getCompanyVat();
      }
      if (this.selectedChargeTypeDetails.invoiceNumber === 'N.A.' || this.selectedChargeTypeDetails.localTax && this.selectedChargeTypeDetails.localTax > 0) {
        if (this.selectedChargeTypeDetails.sourceAmount && this.selectedChargeTypeDetails.sourceAmount.toString() === "") {
          this.selectedChargeTypeDetails.sourceTax = 0;
        } else {
          this.selectedChargeTypeDetails.sourceTax = Number((Number(this.selectedChargeTypeDetails.sourceAmount ? this.selectedChargeTypeDetails.sourceAmount : 0) * this.vat).toFixed(2));
        }
      }
    }

    const totalCapturedValue = this.selectedCreditorFinancialDetail.creditorChargeTypeDetails ? this.selectedCreditorFinancialDetail.creditorChargeTypeDetails.reduce((s, a) => (s + Number(a.sourceAmount ? a.sourceAmount : 0) + Number(a.sourceTax ? a.sourceTax : 0)), 0) : 0;
    if (!isNaN(totalCapturedValue)) {
      this.selectedCreditorFinancialDetail.balanceCapturedValue = totalCapturedValue;
      if (this.selectedCreditorFinancialDetail.balanceTotalValue) {
        this.selectedCreditorFinancialDetail.balanceCurrentValue = this.selectedCreditorFinancialDetail.balanceTotalValue - totalCapturedValue;
      }
    }
  }

  searchTaxTypeCode(event) {
    this.taxTypeSubscription = this.taxTypeService.findTaxTypeByCodeStartWith(event.query).subscribe(taxTypes => {
      this.filteredTaxTypes = taxTypes;
    });
  }

  searchTranType(event) {
    this.tranTypeSubscription = this.tranTypeService.findTranTypesByCodeStartWith(event.query).subscribe(tranTypes => {
      this.filteredTranTypes = tranTypes;
    });
  }

  searchChargeType(event) {
    this.chargeTypeSubscription = this.chargeTypeService.findChargeTypesByCodeStartWith(event.query).subscribe(chargeTypes => {
      this.filteredChargeTypes = chargeTypes;
    });
  }

  selectCurrency(value: Currency) {
    this.selectedCreditorFinancialDetail.currency = value;
  }

  search(event) {
    this.selectedDocumentNumber = null;
    this.businessEntityService.findBusinessEntityStartsWithAndRoleType(event.query, 11).subscribe(data => {
      this.filteredCreditorEntities = data;
    });
  }

  searchFile(fileNumber) {
    if (this.searchFileType === 'clearing') {
      this.clearingFileService
        .findByClearingFileNumberAndSequence(fileNumber.query.toUpperCase(), 0)
        .subscribe(data => {
          this.filteredSearchFiles = data;
      });
    } else if (this.searchFileType === 'export') {
      this.exportFileService
        .findByClearingFileNumberAndSequence(fileNumber.query.toUpperCase(), 0)
        .subscribe(data => {
          this.filteredSearchFiles = data;
      });
    } else {
      this.exBondFileService
        .findByClearingFileNumberAndSequence(fileNumber.query.toUpperCase(), 0)
        .subscribe(data => {
        this.filteredSearchFiles = data;
      });
    }
  }

  searchDocumentNumber(event) {
    this.selectedDocumentNumber = event.query;
    this.businessEntityService.findBusinessEntityCreditorDocumentNumber(event.query).subscribe(data => {
      this.filteredCreditorEntities = data;
    });
  }

  updateCustomsOffice(value) {
    if (this.creditorEntity) {
      this.selectedCreditorFinancialDetail.customOffice = value;
    }
  }

  searchWaybillReference() {
    let foundFileNumber = null;
    if (this.searchFileType === 'clearing') {
      this.clearingFileService.findClearingFileNumbersByMawbNo(this.searchedMawbRef, 'DMY').subscribe(data => {
        foundFileNumber = data[0];
        if (foundFileNumber) {
          this.clearingFileService
          .findByClearingFileNumberAndSequence(foundFileNumber, 0)
          .subscribe(data => {
            this.searchedFile = data[0];
          });
        }
      });
    } else if (this.searchFileType === 'export') {
      this.exportFileService.findClearingFileNumbersByMawbNo(this.searchedMawbRef, 'DMY').subscribe(data => {
        foundFileNumber = data[0];
        if (foundFileNumber) {
          this.exportFileService
          .findByClearingFileNumberAndSequence(foundFileNumber, 0)
          .subscribe(data => {
            this.searchedFile = data[0];
          });
        }
      });
    } else if (this.searchFileType === 'exbond') {
      // No MAWB No. on Exbonds
    }
  }

  loadFromInvoice() {
    if (this.searchedFile && this.searchFileType) {
      this.invoicesSubscription = this.invoiceService.getInvoices(this.searchedFile).subscribe(
        invoices => {
          if (invoices && invoices.length) {
            if (this.chargeTypeDetailsSelection.length === 1 && !this.selectedChargeTypeDetails.sourceAmount) {
              this.chargeTypeDetailsSelection = [];
            }
            invoices.forEach(inv => {
              let recoverableCharges = inv.lineDetails
                    .filter(line => line.tranType.code.trim() === 'Rec');
              let hasRecoverableCharges = recoverableCharges && recoverableCharges.length > 0;
              recoverableCharges.forEach(charge => {
                let chargeTypeDetail = new CreditorChargeTypeDetails();
                chargeTypeDetail.fileNumber = this.searchedFile.clearingFileNumber;
                chargeTypeDetail.mawbNo = this.searchedFile.transportDocument.mawbNo;
                chargeTypeDetail.invoiceNumber = inv.invoiceNo;
                chargeTypeDetail.chargeType = charge.chargeType;
                chargeTypeDetail.taxType = charge.taxType;
                this.tranTypeSubscription = this.tranTypeService.findTranTypesByCodeStartWith('dis').subscribe(tranTypes => {
                  chargeTypeDetail.tranType = tranTypes[0];
                });
                chargeTypeDetail.sourceAmount = 0;
                chargeTypeDetail.sourceTax = 0;
                chargeTypeDetail.chargeAmount = charge.localAmount;
                chargeTypeDetail.localAmount = charge.localAmount;
                chargeTypeDetail.localTax = charge.localTax;

                if (this.chargeTypeDetailsSelection) {
                  this.chargeTypeDetailsSelection.push(chargeTypeDetail);
                } else {
                  this.chargeTypeDetailsSelection = [chargeTypeDetail];
                }
              });
              if (hasRecoverableCharges) {
                this.selectedCreditorFinancialDetail.creditorChargeTypeDetails = this.chargeTypeDetailsSelection;
                this.calculateBalanceAmounts();
                this.selectedChargeTypeDetails = this.selectedCreditorFinancialDetail.creditorChargeTypeDetails[0];
              } else {
                this.messageService.errorNotify(
                  'Warning',
                  ['No recoverable charges found'],
                );
              }
            });
          } else {
            this.messageService.errorNotify(
              'Warning',
              ['No invoices found'],
            );
          }
          this.clearSearchCriteria();
        }
      );
    } else {
      this.messageService.errorNotify(
        'Warning',
        ['Cannot search invoices - Please enter both file type and file/MAWB or BOL number'],
      );
      this.clearSearchCriteria();
    }
  }

  clearSearchCriteria() {
    this.searchedFile = null;
    this.searchFileType = null;
    this.waybillSearch = false;
  }

  isConfirmed() {
    return this.selectedCreditorFinancialDetail && this.selectedCreditorFinancialDetail.confirmed;
  }

  isBalanced() {
    return this.selectedCreditorFinancialDetail && this.selectedCreditorFinancialDetail.balanceCurrentValue === 0;
  }

  saveCreditorEntity() {
      const validation = this.validate();
      if (validation.isValid) {
        let indexToUpdate = this.creditorParentEntity.roles.findIndex(role => role.roleType === 11);
        this.selectedCreditorFinancialDetail.creditorChargeTypeDetails = this.chargeTypeDetailsSelection;
        if (this.creditorEntity.creditorFinancialDetails && this.creditorEntity.creditorFinancialDetails.length > 0) {
          let indexOfCurrentFinDetails = this.creditorEntity.creditorFinancialDetails.findIndex(details => details.documentNumber === this.selectedCreditorFinancialDetail.documentNumber);
          if (indexOfCurrentFinDetails !== undefined && indexOfCurrentFinDetails >= 0) {
            this.creditorEntity.creditorFinancialDetails[indexOfCurrentFinDetails] = this.selectedCreditorFinancialDetail;
          } else {
            this.creditorEntity.creditorFinancialDetails.push(this.selectedCreditorFinancialDetail);
          }
        } else {
          this.creditorEntity.creditorFinancialDetails = [this.selectedCreditorFinancialDetail];
        }
        this.creditorParentEntity.roles[indexToUpdate] = this.creditorEntity;
        this.businessEntityService.saveBusinessEntity(this.creditorParentEntity)
          .subscribe(data => {
              this.creditorEntity = data.roles.find(role => role.roleType === 11);
              this.messageService.successNotify('Business Entity Saved');            },
            error => {
            },
          );
      } else {
        this.addFailure(validation.messages);
        return;
      }
  }

  newFinancialDetails() {
    this.clearSearchCriteria();
    this.selectedCreditorFinancialDetail = new CreditorFinancialDetail();
    this.addDefaultValues();
    this.chargeTypeDetailsSelection = [];
    this.selectedChargeTypeDetails = null;
  }

  onSelectCreditorEntity(businessEntity: BusinessEntity) {
    this.creditorParentEntity = businessEntity;
    this.creditorEntity = this.creditorParentEntity.roles.find(role => role.roleType === 11);
    if (!this.creditorEntity.creditorFinancialDetails) {
      this.newFinancialDetails();
    } else {
      if (this.selectedDocumentNumber) {
        this.selectedCreditorFinancialDetail = this.creditorEntity.creditorFinancialDetails.find(finDetails => finDetails.documentNumber === this.selectedDocumentNumber);
      } else {
        this.selectedCreditorFinancialDetail = this.creditorEntity.creditorFinancialDetails.find(finDetails => finDetails.balanceCurrentValue !== 0);
        if (!this.selectedCreditorFinancialDetail) {
          this.newFinancialDetails();
        }
        this.selectedDocumentNumber = this.selectedCreditorFinancialDetail.documentNumber;
      }
      if (this.selectedCreditorFinancialDetail.creditorChargeTypeDetails) {
        this.chargeTypeDetailsSelection = this.selectedCreditorFinancialDetail.creditorChargeTypeDetails;
        this.selectedChargeTypeDetails = this.chargeTypeDetailsSelection[0];
      }
    }
  }

  addDefaultValues(){
    if (this.creditorEntity.currency) {
      this.selectedCreditorFinancialDetail.currency = this.creditorEntity.currency;
    }
    if (this.creditorEntity.defaultTaxType) {
      this.selectedCreditorFinancialDetail.defaultTaxType = this.creditorEntity.defaultTaxType;
    }
    this.authorisationService.getLoggedInUser().subscribe(user => {
      if (user && user.name) {
        this.selectedCreditorFinancialDetail.userCode = user.name;
      }
    });
  }

  getCompanyVat() {
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        this.vat = companies[0].companyDefaults.vat
      }
    });
  }

  selectChargeTypeDetails(value) {
    this.searchFileType = '';
    this.filteredSearchFiles = [];
    this.searchedFile = null;
    this.selectedChargeTypeDetails = value.data;
  }

  validate(): ValidationResult {
    const validation: ValidationResult = {
      isValid: true,
      messages: []
    };
    if (!this.creditorEntity || !this.creditorParentEntity.code) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please select a Creditor');
      return validation;
    }

    if (!this.selectedCreditorFinancialDetail.documentNumber) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please enter Document Number');
    }

    if (!this.selectedCreditorFinancialDetail.documentDate) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please enter Document Date');
    }

    if (!this.selectedCreditorFinancialDetail.financialDate) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please select a Financial Date');
    }

    if (!this.selectedCreditorFinancialDetail.totalInvoiceValue) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please enter Total Invoice');
    }

    if (!this.selectedCreditorFinancialDetail.homeValue) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please enter Home Value');
    }

    if (!this.selectedCreditorFinancialDetail.currency) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please enter Currency');
    }

    if (!this.selectedCreditorFinancialDetail.confirmDate) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please enter Confirm Date');
    }

    if (!this.selectedCreditorFinancialDetail.userCode) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please enter User Code');
    }

    if (!this.selectedCreditorFinancialDetail.defaultTaxType) {
      validation.isValid = false;
      validation.messages.push('Cannot save creditor invoice, Please enter Default Tax Type');
    }

    if (this.selectedCreditorFinancialDetail.creditorChargeTypeDetails && this.selectedCreditorFinancialDetail.creditorChargeTypeDetails.length > 0) {
      this.selectedCreditorFinancialDetail.creditorChargeTypeDetails.forEach(chargeTypeDetail => {

        if (!chargeTypeDetail.fileNumber) {
          validation.isValid = false;
          validation.messages.push('Cannot save creditor invoice, Line Details File Number is required');
        }

        if (!chargeTypeDetail.chargeType) {
          validation.isValid = false;
          validation.messages.push('Cannot save creditor invoice, Line Details Charge Type is required');
        }

        if (!chargeTypeDetail.tranType) {
          validation.isValid = false;
          validation.messages.push('Cannot save creditor invoice, Line Details tran Type is required');
        }

        if (!chargeTypeDetail.taxType) {
          validation.isValid = false;
          validation.messages.push('Cannot save creditor invoice, Line Details Tax Type is required');
        }

        if (!chargeTypeDetail.sourceAmount && chargeTypeDetail.sourceAmount !== 0) {
          validation.isValid = false;
          validation.messages.push('Cannot save creditor invoice, Line Details Source Amount is required');
        }
      });
    }

    return validation;
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  ngOnDestroy() {
    if (this.taxTypeSubscription) {
      this.taxTypeSubscription.unsubscribe();
    }
    if (this.chargeTypeSubscription) {
      this.chargeTypeSubscription.unsubscribe();
    }
    if (this.tranTypeSubscription) {
      this.tranTypeSubscription.unsubscribe();
    }
    if (this.invoicesSubscription) {
      this.invoicesSubscription.unsubscribe();
    }
  }
}

