import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import * as moment from 'moment';
import { BusinessEntity, Role, Debtor, Country, Currency} from 'src/app/digi-business-entity/models/business-entity';
import { Invoice, InvoiceTransactionDetails, InvoiceTransaction } from 'src/app/digi-common/models/financials';
import { Branch } from 'src/app/digi-common/models/company';
import { CompanyService } from '../../services/company.service';
import { NotificationService } from '../../services/notification.service';
import { BusinessEntityService } from '../../../digi-business-entity/services/business-entity.service';
import { PaymentsReportService } from '../../services/payments-report.service';
import { ValidationResult } from '../../services/validation.service';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'digi-debtors-transactions',
  templateUrl: './debtors-transactions.component.html',
  styles: [`
    .ui-steps .ui-steps-item {
      width: 25%;
    }`],
  providers: [ConfirmationService],
  encapsulation: ViewEncapsulation.None

})
export class DebtorsTransactionsComponent implements OnInit, OnDestroy {
  debtorParentEntity: BusinessEntity;
  debtorEntity: Debtor;
  filteredDebtorEntities = [];
  invoicesSelection: Invoice[];
  selectedInvoice: Invoice;
  transactionsSelection: InvoiceTransaction[];
  selectedTransaction: any;
  cols: any[];
  transactionCols: any[];
  transactionTypes: any[];
  tranTypeToAdd: string;
  journalDescriptionToAdd: string;
  journalReferenceToAdd: string;
  tranAmountToAdd: number;
  shouldUpdateDebtorCreditBalance = false;
  activeIndex = 0;
  fromDate: string;
  toDate: string;
  pdfSrc: string;
  disableButton: boolean;
  generatedFiles: any[];
  private branchCode: string;
  private reportSubscription: Subscription;
  private invoicesSubscription: Subscription;

  constructor(private businessEntityService: BusinessEntityService,
              private messageService: NotificationService,
              private confirmationService: ConfirmationService,
              private invoiceService: InvoiceService,
              private paymentsReportService: PaymentsReportService,
              private companyService: CompanyService) {
  }

  ngOnInit() {
    this.cols = [
      {header: 'Invoice Date'},
      {header: 'File Ref.'},
      {header: 'Invoice No.'},
      {header: 'Invoice Amount'},
      {header: 'O/S Amount'}
    ];
    this.transactionCols = [
      {header: 'Transaction Date'},
      {header: 'Transaction Type'},
      {header: 'Amount'}
    ];
    this.transactionTypes = [
      {'label': '', 'value': ''}, {'label': 'PAYMENT', 'value': 'PAYMENT'}, {'label': 'REVERSAL', 'value': 'REVERSAL'}, {'label': 'CR JOURNAL', 'value': 'CR JOURNAL'}, {'label': 'DR JOURNAL', 'value': 'DR JOURNAL'}
    ];
    this.clearDebtor();
    this.companyService.getCompany().subscribe(companies => {
      if (companies && companies.length) {
        const branch: Branch = companies[0].branches[0];
        if (branch.creditLimitActive) {
          this.shouldUpdateDebtorCreditBalance = true;
        }
      }
    });
  }

  search(event) {
    this.businessEntityService.findBusinessEntityStartsWithAndRoleType(event.query, 1).subscribe(data => {
      this.filteredDebtorEntities = data;
    });
  }

  saveTransactions() {
    this.invoicesSubscription = this.invoiceService.saveInvoiceTransactions(this.selectedInvoice).subscribe(
      data => {
        this.messageService.successNotify('Transaction Saved Successfully');
      }
    );
  }

  clearDebtor() {
    this.debtorParentEntity = null;
    this.debtorEntity = new Debtor();
    this.invoicesSelection = [];
    this.transactionsSelection = [];
  }

  getInvoiceBalance(invoice) {
    let isLocalInv = invoice.currency.code === 'ZAR';
    if (!invoice.paidAmount) {
      return isLocalInv ? invoice.localAmount : invoice.foreignAmount;
    } else {
      return Number(isLocalInv ? invoice.localAmount - invoice.paidAmount : invoice.foreignAmount - invoice.paidAmount).toFixed(2);
    }
  }

  handleIndexChange(event: any) {
    if (event.index === 1) {
      this.activeIndex = 1;
    } else if (event.index === 0) {
      this.activeIndex = 0;
    }
    this.pdfSrc = null;
  }

  addNewTransaction() {
    const validation = this.validate();
    if (validation.isValid) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to add this transaction to invoice: ' + this.selectedInvoice.invoiceNo + '?',
        accept: () => {
          const transaction = new InvoiceTransaction();
          transaction.transactionType = this.tranTypeToAdd;
          transaction.transactionAmount = this.tranAmountToAdd;
          transaction.transactionDate = moment().format('DD/MM/YYYY');

          if (this.tranTypeToAdd === 'CR JOURNAL' || this.tranTypeToAdd === 'DR JOURNAL') {
            transaction.journalReference = this.journalReferenceToAdd;
            transaction.journalDescription = this.journalDescriptionToAdd;
          }
          if (this.transactionsSelection && this.transactionsSelection.length > 0) {
            this.transactionsSelection.push(transaction);
          } else {
            this.transactionsSelection = [transaction];
          }
          if (this.tranTypeToAdd === 'PAYMENT' || this.tranTypeToAdd === 'CR JOURNAL') {
            this.selectedInvoice.paidAmount = this.selectedInvoice.paidAmount + Number(this.tranAmountToAdd);
            if (this.shouldUpdateDebtorCreditBalance) {
              let newBalance = Number(this.debtorEntity.creditBalance) + Number(this.tranAmountToAdd);
              this.debtorEntity.creditBalance = String(newBalance.toFixed(2));
            }
          } else {
            this.selectedInvoice.paidAmount = this.selectedInvoice.paidAmount - Number(this.tranAmountToAdd);
            if (this.shouldUpdateDebtorCreditBalance) {
              let newBalance = Number(this.debtorEntity.creditBalance) - Number(this.tranAmountToAdd);
              this.debtorEntity.creditBalance = String(newBalance.toFixed(2));
            }
          }

          this.selectedInvoice.transactionDetails.zarAmount = this.getInvoiceBalance(this.selectedInvoice);
          this.selectedInvoice.transactions = this.transactionsSelection;
          let indexToUpdate = this.debtorParentEntity.roles.findIndex(role => role.roleType === 1);
          this.debtorParentEntity.roles[indexToUpdate] = this.debtorEntity;
          this.businessEntityService.saveBusinessEntity(this.debtorParentEntity)
          .subscribe(data => {
              this.onSelectDebtorEntity(data);
              this.messageService.successNotify('Debtors Credit Balance Updated');
            },
            error => {
            },
          );
          this.saveTransactions();
          this.clearTransaction();
        }
      });
    } else {
      this.addFailure(validation.messages);
      return;
    }
  }

  clearTransaction() {
    this.tranTypeToAdd = '';
    this.tranAmountToAdd = null;
    this.journalReferenceToAdd = null;
    this.journalDescriptionToAdd = null;
  }

  selectInvoice(invoice) {
    this.selectedInvoice = invoice;
    if (!this.selectedInvoice.transactionDetails) {
      const transactionDetails = new InvoiceTransactionDetails();
      transactionDetails.zarAmount = this.getInvoiceBalance(this.selectedInvoice);
      transactionDetails.currencyCode = this.selectedInvoice.currency.code;
      this.selectedInvoice.transactionDetails = transactionDetails;
    }
    if (this.selectedInvoice.transactions) {
      this.transactionsSelection = this.selectedInvoice.transactions;
    }
  }

  hasValidDetails() {
    return this.tranTypeToAdd && this.tranTypeToAdd !== '' && this.tranAmountToAdd && this.tranAmountToAdd > 0;
  }

  selectTransaction(value) {

  }

  onSelectDebtorEntity(businessEntity: BusinessEntity) {
    this.debtorParentEntity = businessEntity;
    this.invoicesSubscription = this.invoiceService.findInvoicesByDebtorCode(this.debtorParentEntity.code).subscribe(
      invoices => {
        this.clearTransaction();
        this.invoicesSelection = [];
        this.transactionsSelection = [];
        this.debtorEntity = this.debtorParentEntity.roles.find(role => role.roleType === 1);
        if (invoices) {
          if (invoices.importInvoices) {
            this.invoicesSelection.push(...invoices.importInvoices)
          }
          if (invoices.exportInvoices) {
            this.invoicesSelection.push(...invoices.exportInvoices)
          }
          if (invoices.exbondInvoices) {
            this.invoicesSelection.push(...invoices.exbondInvoices)
          }
          this.selectInvoice(this.invoicesSelection[0]);
        } else {
          this.messageService.errorNotify(
            'Warning',
            ['No invoices found'],
          );
        }
      }
    );
  }

  selectCurrency(value: Currency): void {
    this.debtorEntity.currency = value;
  }

  selectCountry(value: Country): void {
    this.debtorEntity.country = value;
  }

  validate(): ValidationResult {
    const validation: ValidationResult = {
      isValid: true,
      messages: []
    };
    if ((this.tranTypeToAdd === 'REVERSAL' || this.tranTypeToAdd === 'DR JOURNAL') && Number(this.tranAmountToAdd) > this.getInvoiceBalance(this.selectedInvoice)) {
      validation.isValid = false;
      validation.messages.push('Cannot process transaction - reversal or debit journal amounts cannot exceed the outstanding balance');
      return validation;
    }
    if ((this.tranTypeToAdd === 'CR JOURNAL' || this.tranTypeToAdd === 'DR JOURNAL') && (!this.journalDescriptionToAdd || !this.journalReferenceToAdd)) {
      validation.isValid = false;
      validation.messages.push('Cannot process journal - please provide  reference and description');
      return validation;
    }
    return validation;
  }

  generate() {
    this.disableButton = true;
    this.reportSubscription = this.paymentsReportService.generateDebtorsStatementReports(this.fromDate ? this.fromDate : null, this.toDate ? this.toDate : null, this.debtorParentEntity && this.debtorParentEntity.code ? this.debtorParentEntity.code : null, this.debtorParentEntity && this.debtorEntity.currency ? this.debtorEntity.currency.code.trim() : 'ZAR').subscribe(data => {
      this.pdfSrc = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`;
    },
    error => {
      this.messageService.errorNotify(error.error.message, error.error.errors);
      this.pdfSrc = null;
      this.disableButton = false;
    });
  }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  ngOnDestroy() {
    if (this.invoicesSubscription) {
      this.invoicesSubscription.unsubscribe();
    }
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
  }
}

