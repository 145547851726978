<p-dialog header="Clearing Instructions Details..."
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [width]="1000"
          [minY]="70"
          [minWidth]="200"
          [baseZIndex]="10000"
          [contentStyle]="{'height':'600px'}"
          (onHide)="onHide($event)">
  <div class="ui-fluid">
    <div class="ui-g">
      <p-scrollPanel [style]="{width: '100%', height: '600px'}">
        <div class="ui-g-12">
          <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-2">
              <div class="ui-inputgroup">
               <span class="md-inputfield">
                  <input type="text" pInputText value="{{selectedClearingInstruction?.procedureCategoryCode}}">
              <label>Category</label>
            </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-10">
              <div class="ui-inputgroup">
               <span class="md-inputfield">
                  <input type="text" pInputText value="{{selectedClearingInstruction?.pcDescription}}">
               </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-2">
              <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text" pInputText value="{{selectedClearingInstruction?.customsProcedureCode}}">
                <label>Procedure</label>
              </span>
                <span class="ui-inputgroup-addon" (click)="customsProceduresPopup()"><i
                  class="fa fa-chevron-down"></i></span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-10">
              <div class="ui-inputgroup">
              <span class="md-inputfield">
                <input type="text" pInputText value="{{selectedClearingInstruction?.customsProcDesc}}">
              </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-2">
              <div class="ui-inputgroup">
               <span class="md-inputfield">
                  <input type="text" pInputText [(ngModel)]="selectedClearingInstruction.previousCustomsProcedureCode">
                  <label>Prev. Proc. Code</label>
            </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-10">
              <div class="ui-inputgroup">
               <span class="md-inputfield">
                 <input type="text" pInputText [(ngModel)]="selectedClearingInstruction.previousMrnNumber">
                  <label>Prev. MRN Number</label>
               </span>
              </div>
            </div>

            <div class="ui-g-12 ui-md-2">
              <div class="ui-inputgroup">
              <span class="md-inputfield">
                  <input type="text" pInputText value="{{selectedClearingInstruction?.customsPurpose?.customsPurposeCode}}">
                <label>Purpose</label>
              </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-10">
              <div class="ui-inputgroup">
               <span class="md-inputfield">
                  <input type="text" pInputText value="{{selectedClearingInstruction?.customsPurpose?.customsPurposeName}}">
               </span>
              </div>
            </div>
            <div class="ui-g-12 ui-md-4">
              <digi-country-of-origin [country]="selectedClearingInstruction?.destination"
                                      label='Destination'
                                      (onSelect)="selectCountryOfOrigin($event)"></digi-country-of-origin>
            </div>
            <div class="ui-g-12 ui-md-4">
              <p-checkbox binary="true"
                          name="customVatRequired"
                          value="customVatRequired"
                          label="Customs VAT required?"
                          (onChange)="setCustomsVatRequired($event)"
                          [ngModel]="selectedClearingInstruction?.customVatRequired"></p-checkbox>
            </div>
            <div class="ui-g-12 ui-md-4">
              <p-checkbox binary="true"
                          name="consolidateBoeLines"
                          value="consolidateBoeLines"
                          label="Consolidate BOE Lines?"
                          (onChange)="setConsolidateBoELines($event)"
                          [ngModel]="selectedClearingInstruction?.consolidateBoeLines"></p-checkbox>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="isRITRIB()">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <p-panel header="R.I.B./R.I.T. Details">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                        <div class="ui-inputgroup">
                          <span class="ui-float-label">
                          <p-autoComplete id="portDetails" [ngModel]="selectedClearingInstruction?.portDetails"
                                          field="label"
                                          [suggestions]="filteredPortDetails"
                                          (completeMethod)="search($event)"
                                          [autoHighlight]="true"
                                          (onSelect)="selectPort($event)"
                                          minLength="3"
                                          required=true>
                            <ng-template let-option pTemplate="item">
                              <div>{{option.code}}: {{option.name}}</div>
                            </ng-template>
                          </p-autoComplete>
                             <label for="portDetails">R.I.B./R.I.T. to Port</label>
                              </span>
                          <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
                        </div>
                        <div *ngIf="!this.selectedClearingInstruction.portDetails?.code"
                             class="ui-message ui-messages-error ui-corner-all">This field is required</div>
                      </span>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="isRITRIB()">
              <div class="ui-fluid">
                <div class="ui-g">
                  <div class="ui-g-12">
                    <p-panel header="Customs Office To/Exit">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <digi-customs-office [customOffice]="selectedClearingInstruction.customOffice"
                                               [isRequired]=true
                                               (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
                        </div>
                      </div>
                    </p-panel>
                  </div>
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="isRITRIB()">
              <p-panel header="Degrouping Depot">
                <digi-app-degrouping></digi-app-degrouping>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-3" *ngIf="isRITRIB() || isWarehouse() || isXe() || isXIB()">
              <p-panel header="Removal Transport Code">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                        <div class="ui-inputgroup">
                          <span class="ui-float-label">
                          <p-autoComplete id="removalTransportMethod" [(ngModel)]="selectedClearingInstruction.removalTransportMethod"
                                          field="description"
                                          [suggestions]="methods"
                                          (completeMethod)="searchRemovalTransportMethod($event)"
                                          [autoHighlight]="true"
                                          [dropdown]="true"
                                          (onSelect)="selectRemovalTransportMethod($event)"
                                          minLength="3"
                                          required=true>
                            <ng-template let-option pTemplate="item">
                              <div>{{option.description}}</div>
                            </ng-template>
                          </p-autoComplete>
                              </span>
                        </div>
                      </span>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="isXRW() || isXIB()">
              <p-panel header="Destination Warehouse Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="selectedClearingInstruction.destinationWarehouse"
                                                   [roleName]="'Warehouse'"
                                                   [roleID]="13"
                                                   [showAddress]="true"
                                                   [isNomination]="false"
                                                   (selectedBusinessEntity)="selectDestinationWarehouse($event)"
                      >
                      </digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
              <p-panel header="To Importer Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="selectedClearingInstruction.toImporterConsignee"
                                                   [roleName]="'Importer'"
                                                   [isNomination]="false"
                                                   [roleID]="6"
                                                   [showAddress]="true"
                                                   (selectedBusinessEntity)="selectToImporter($event)"
                      ></digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-3">
              <p-panel header="Transport Mode">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12">
                    <span class="md-inputfield">
                        <div class="ui-inputgroup">
                          <span class="ui-float-label">
                          <p-autoComplete id="transportMethod" [(ngModel)]="selectedClearingInstruction.transportMethod"
                                          field="description"
                                          [suggestions]="methods"
                                          (completeMethod)="searchRemovalTransportMethod($event)"
                                          [autoHighlight]="true"
                                          [dropdown]="true"
                                          (onSelect)="selectTransportMethod($event)"
                                          minLength="3"
                                          required=true>
                            <ng-template let-option pTemplate="item">
                              <div>{{option.description}}</div>
                            </ng-template>
                          </p-autoComplete>
                              </span>
                        </div>
                      </span>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="isRITRIB() || isWarehouse()">
              <p-panel header="Importer Consignee Details">
                <digi-clearing-instruction-importer-consignee></digi-clearing-instruction-importer-consignee>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="fileType==='exports'&&isXe()">
              <p-panel header="Exporter Consignor Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="selectedClearingInstruction.exporterConsignor"
                                                   [roleName]="'Exporter Consignor'"
                                                   [roleID]="3"
                                                   [showAddress]="true"
                                                   [businessEntities]="exporterConsignors"
                                                   (selectedBusinessEntity)="selectExporterConsignor($event)"
                      >
                      </digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="isRITRIB()">
              <p-panel header="Consignee Details">
                <digi-consignee></digi-consignee>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="fileType==='exports'&&isXe()">
              <p-panel header="Warehouse Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="selectedClearingInstruction.warehouse"
                                                   [roleName]="'Warehouse'"
                                                   [roleID]="13"
                                                   [showAddress]="true"
                                                   [businessEntities]="warehouses"
                                                   (selectedBusinessEntity)="selectWarehouse($event)"
                      >
                      </digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-12 ui-md-6" *ngIf="isRIB() || isWarehouse()">
              <p-panel header="Warehouse Details">
                <digi-app-warehouse-details
                  [code]="selectedClearingInstruction?.warehouse?.code"></digi-app-warehouse-details>
              </p-panel>
            </div>
            <ng-container *ngIf="fileType==='imports'">
              <div class="ui-g-12 ui-md-6" *ngIf="isRoadRemovalTransportMethod()">
                <p-panel header="Guarantor Bond Details">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <digi-guarantor></digi-guarantor>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-12 ui-md-6" *ngIf="isRoadRemovalTransportMethod()">
                <p-panel header="Removal Bond Details">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <digi-app-remover></digi-app-remover>
                        <div class="ui-g-12">
                        <span class="md-inputfield">
                            <input type="text" tooltipPosition="top" pInputText>
                        <label>Sub Remover</label>
                      </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
            </ng-container>
            <ng-container *ngIf="fileType==='exports'">
              <div class="ui-g-12 ui-md-6" *ngIf="isRoadRemovalTransportMethod()">
                <p-panel header="Guarantor Bond Details">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <digi-business-entity-search [businessEntity]="selectedClearingInstruction.guarantor"
                                                     [roleName]="'Guarantor'"
                                                     [roleID]="30"
                                                     [showAddress]="true"
                                                     [isNomination]="false"
                                                     [businessEntities]="guarantors"
                                                     (selectedBusinessEntity)="selectGuarantor($event)"
                        >
                        </digi-business-entity-search>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-12 ui-md-6" *ngIf="isRoadRemovalTransportMethod()">
                <p-panel header="Removal Bond Details">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <digi-business-entity-search [businessEntity]="selectedClearingInstruction.remover"
                                                     [roleName]="'Remover'"
                                                     [roleID]="8"
                                                     [showAddress]="true"
                                                     [businessEntities]="removers"
                                                     [isNomination]="true"
                                                     (selectedBusinessEntity)="selectRemover($event)"
                        >
                        </digi-business-entity-search>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
            </ng-container>
            <div class="ui-g-4 ui-md-4" *ngIf="isXIR() || isXGR()">
              <p-panel header="Owner Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="selectedClearingInstruction.owner"
                                                   [roleName]="'Owner'"
                                                   [roleID]="6"
                                                   [showAddress]="true"
                                                   [isNomination]="false"
                                                   (selectedBusinessEntity)="selectOwner($event)"
                      >
                      </digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4" *ngIf="isXIR() || isXGR()">
              <p-panel header="Rebate User Details">
                <div class="ui-fluid">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <digi-business-entity-search [businessEntity]="selectedClearingInstruction.rebateUser"
                                                   [roleName]="'Rebate User'"
                                                   [roleID]="9"
                                                   [showAddress]="true"
                                                   [isNomination]="true"
                                                   [businessEntities]="rebateUsers"
                                                   (selectedBusinessEntity)="selectRebate($event)"
                      >
                      </digi-business-entity-search>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <div class="ui-g-4 ui-md-4" *ngIf="isIRGR()">
              <p-panel header="Rebate User">
                <digi-rebate-user></digi-rebate-user>
              </p-panel>
            </div>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="pi pi-times" (click)="onClose()" label="Close"></button>
    </div>
  </p-footer>
</p-dialog>
<p-dialog header="Select a customs Procedure..."
          [(visible)]="showCustomsProcedures"
          [modal]="true"
          [responsive]="true"
          [width]="900"
          [minWidth]="100"
          [minY]="20"
          [maximizable]="false"
          [baseZIndex]="10000">
  <p-table [columns]="cols" [value]="customsProcedures" selectionMode="single" [(selection)]="selected"
           [resizableColumns]="true" (onRowSelect)="onRowSelect($event)">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pResizableColumn>
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
        <td class="ui-resizable-column">
          <label>{{rowData.customsProcedureCode}}</label>
        </td>
        <td class="ui-resizable-column">
          <label>{{rowData.customsProcDesc}}</label>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="fa fa-close" (click)="customsProceduresPopupHide()"
              label="Close"></button>
    </div>
  </p-footer>
</p-dialog>
