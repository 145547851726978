var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var File = /** @class */ (function () {
    function File() {
    }
    return File;
}());
export { File };
var Correction = /** @class */ (function (_super) {
    __extends(Correction, _super);
    function Correction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Correction;
}(File));
export { Correction };
var BusinessEntity = /** @class */ (function () {
    function BusinessEntity() {
    }
    return BusinessEntity;
}());
export { BusinessEntity };
var Reference = /** @class */ (function () {
    function Reference() {
    }
    return Reference;
}());
export { Reference };
var ClearingInstruction = /** @class */ (function () {
    function ClearingInstruction() {
    }
    return ClearingInstruction;
}());
export { ClearingInstruction };
var CustomsPurpose = /** @class */ (function () {
    function CustomsPurpose() {
    }
    return CustomsPurpose;
}());
export { CustomsPurpose };
var TradeAgreement = /** @class */ (function () {
    function TradeAgreement() {
    }
    return TradeAgreement;
}());
export { TradeAgreement };
var TransportMethod = /** @class */ (function () {
    function TransportMethod() {
    }
    return TransportMethod;
}());
export { TransportMethod };
var RebateUser = /** @class */ (function () {
    function RebateUser() {
    }
    return RebateUser;
}());
export { RebateUser };
var Warehouse = /** @class */ (function () {
    function Warehouse() {
    }
    return Warehouse;
}());
export { Warehouse };
var Remover = /** @class */ (function () {
    function Remover() {
    }
    return Remover;
}());
export { Remover };
var Guarantor = /** @class */ (function () {
    function Guarantor() {
    }
    return Guarantor;
}());
export { Guarantor };
var Consignee = /** @class */ (function () {
    function Consignee() {
    }
    return Consignee;
}());
export { Consignee };
var Consignor = /** @class */ (function (_super) {
    __extends(Consignor, _super);
    function Consignor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Consignor;
}(Consignee));
export { Consignor };
var Address = /** @class */ (function () {
    function Address() {
    }
    return Address;
}());
export { Address };
var IncoTerm = /** @class */ (function () {
    function IncoTerm() {
    }
    return IncoTerm;
}());
export { IncoTerm };
var LocalAgent = /** @class */ (function () {
    function LocalAgent() {
    }
    return LocalAgent;
}());
export { LocalAgent };
var LocalAgentBond = /** @class */ (function () {
    function LocalAgentBond() {
    }
    return LocalAgentBond;
}());
export { LocalAgentBond };
var CustomOffice = /** @class */ (function () {
    function CustomOffice() {
    }
    return CustomOffice;
}());
export { CustomOffice };
var Debtor = /** @class */ (function () {
    function Debtor() {
    }
    return Debtor;
}());
export { Debtor };
var SupplierInvoice = /** @class */ (function () {
    function SupplierInvoice() {
    }
    return SupplierInvoice;
}());
export { SupplierInvoice };
var Certificate = /** @class */ (function () {
    function Certificate() {
    }
    return Certificate;
}());
export { Certificate };
var CertificateType = /** @class */ (function () {
    function CertificateType() {
    }
    return CertificateType;
}());
export { CertificateType };
var UserInterfaceDefaults = /** @class */ (function () {
    function UserInterfaceDefaults() {
    }
    return UserInterfaceDefaults;
}());
export { UserInterfaceDefaults };
var RunningTotals = /** @class */ (function () {
    function RunningTotals() {
    }
    return RunningTotals;
}());
export { RunningTotals };
var ContainerRunningTotals = /** @class */ (function () {
    function ContainerRunningTotals() {
    }
    return ContainerRunningTotals;
}());
export { ContainerRunningTotals };
var Supplier = /** @class */ (function () {
    function Supplier() {
    }
    return Supplier;
}());
export { Supplier };
var DefaultValues = /** @class */ (function () {
    function DefaultValues() {
    }
    return DefaultValues;
}());
export { DefaultValues };
var Country = /** @class */ (function () {
    function Country() {
    }
    return Country;
}());
export { Country };
var PurchaseTerm = /** @class */ (function () {
    function PurchaseTerm() {
    }
    return PurchaseTerm;
}());
export { PurchaseTerm };
var Currency = /** @class */ (function () {
    function Currency() {
    }
    return Currency;
}());
export { Currency };
var AdditionalUom = /** @class */ (function () {
    function AdditionalUom() {
    }
    return AdditionalUom;
}());
export { AdditionalUom };
var SupplierInvoiceLine = /** @class */ (function () {
    function SupplierInvoiceLine() {
    }
    return SupplierInvoiceLine;
}());
export { SupplierInvoiceLine };
var SchedulePart = /** @class */ (function () {
    function SchedulePart() {
    }
    return SchedulePart;
}());
export { SchedulePart };
var PurposeSchedulePart = /** @class */ (function () {
    function PurposeSchedulePart() {
    }
    return PurposeSchedulePart;
}());
export { PurposeSchedulePart };
var FormulaSchedulePart = /** @class */ (function () {
    function FormulaSchedulePart() {
    }
    return FormulaSchedulePart;
}());
export { FormulaSchedulePart };
var UserInterfaceCheckbox = /** @class */ (function () {
    function UserInterfaceCheckbox() {
    }
    return UserInterfaceCheckbox;
}());
export { UserInterfaceCheckbox };
var Document = /** @class */ (function () {
    function Document() {
    }
    return Document;
}());
export { Document };
var TransportDocument = /** @class */ (function (_super) {
    __extends(TransportDocument, _super);
    function TransportDocument() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TransportDocument;
}(Document));
export { TransportDocument };
var CrewMember = /** @class */ (function () {
    function CrewMember() {
    }
    return CrewMember;
}());
export { CrewMember };
var RouteDetails = /** @class */ (function () {
    function RouteDetails() {
    }
    return RouteDetails;
}());
export { RouteDetails };
var RateClass = /** @class */ (function () {
    function RateClass() {
    }
    return RateClass;
}());
export { RateClass };
var Commodity = /** @class */ (function () {
    function Commodity() {
    }
    return Commodity;
}());
export { Commodity };
var PackageDimensions = /** @class */ (function () {
    function PackageDimensions() {
    }
    return PackageDimensions;
}());
export { PackageDimensions };
var PackageDetails = /** @class */ (function () {
    function PackageDetails() {
    }
    return PackageDetails;
}());
export { PackageDetails };
var Information = /** @class */ (function () {
    function Information() {
    }
    return Information;
}());
export { Information };
var ExportTransportDocument = /** @class */ (function (_super) {
    __extends(ExportTransportDocument, _super);
    function ExportTransportDocument() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExportTransportDocument;
}(Document));
export { ExportTransportDocument };
var ForwardFile = /** @class */ (function () {
    function ForwardFile() {
    }
    return ForwardFile;
}());
export { ForwardFile };
var Transporter = /** @class */ (function () {
    function Transporter() {
    }
    return Transporter;
}());
export { Transporter };
var ContainerReleasedTo = /** @class */ (function () {
    function ContainerReleasedTo() {
    }
    return ContainerReleasedTo;
}());
export { ContainerReleasedTo };
var VoyageDetails = /** @class */ (function () {
    function VoyageDetails() {
    }
    return VoyageDetails;
}());
export { VoyageDetails };
var ContainerType = /** @class */ (function () {
    function ContainerType() {
    }
    return ContainerType;
}());
export { ContainerType };
var CustomsRadioCallSign = /** @class */ (function () {
    function CustomsRadioCallSign() {
    }
    return CustomsRadioCallSign;
}());
export { CustomsRadioCallSign };
var OceanLineDetails = /** @class */ (function () {
    function OceanLineDetails() {
    }
    return OceanLineDetails;
}());
export { OceanLineDetails };
var OceanLine = /** @class */ (function () {
    function OceanLine() {
    }
    return OceanLine;
}());
export { OceanLine };
var Freight = /** @class */ (function () {
    function Freight() {
    }
    return Freight;
}());
export { Freight };
var CollectPrepaid = /** @class */ (function () {
    function CollectPrepaid() {
    }
    return CollectPrepaid;
}());
export { CollectPrepaid };
var PreviousBoeDetails = /** @class */ (function () {
    function PreviousBoeDetails() {
    }
    return PreviousBoeDetails;
}());
export { PreviousBoeDetails };
var CustomsProcedure = /** @class */ (function () {
    function CustomsProcedure() {
    }
    return CustomsProcedure;
}());
export { CustomsProcedure };
var PortTo = /** @class */ (function () {
    function PortTo() {
    }
    return PortTo;
}());
export { PortTo };
var DepotTerminal = /** @class */ (function () {
    function DepotTerminal() {
    }
    return DepotTerminal;
}());
export { DepotTerminal };
var PortDetail = /** @class */ (function () {
    function PortDetail() {
    }
    return PortDetail;
}());
export { PortDetail };
var Measures = /** @class */ (function () {
    function Measures() {
    }
    return Measures;
}());
export { Measures };
var Solas = /** @class */ (function () {
    function Solas() {
    }
    return Solas;
}());
export { Solas };
var MasContainerType = /** @class */ (function () {
    function MasContainerType() {
    }
    return MasContainerType;
}());
export { MasContainerType };
var MasContainerSize = /** @class */ (function () {
    function MasContainerSize() {
    }
    return MasContainerSize;
}());
export { MasContainerSize };
var Container = /** @class */ (function () {
    function Container() {
    }
    return Container;
}());
export { Container };
var CargoType = /** @class */ (function () {
    function CargoType() {
    }
    return CargoType;
}());
export { CargoType };
var SealDetails = /** @class */ (function () {
    function SealDetails() {
    }
    return SealDetails;
}());
export { SealDetails };
var ContainerSpecification = /** @class */ (function () {
    function ContainerSpecification() {
    }
    return ContainerSpecification;
}());
export { ContainerSpecification };
var ContainerSize = /** @class */ (function () {
    function ContainerSize() {
    }
    return ContainerSize;
}());
export { ContainerSize };
var LocalForwarder = /** @class */ (function () {
    function LocalForwarder() {
    }
    return LocalForwarder;
}());
export { LocalForwarder };
var VesselsAgent = /** @class */ (function () {
    function VesselsAgent() {
    }
    return VesselsAgent;
}());
export { VesselsAgent };
var Airline = /** @class */ (function () {
    function Airline() {
    }
    return Airline;
}());
export { Airline };
var VehicleDetails = /** @class */ (function () {
    function VehicleDetails() {
    }
    return VehicleDetails;
}());
export { VehicleDetails };
var ValueBasedCharge = /** @class */ (function () {
    function ValueBasedCharge() {
    }
    return ValueBasedCharge;
}());
export { ValueBasedCharge };
var RateBasedCharge = /** @class */ (function () {
    function RateBasedCharge() {
    }
    return RateBasedCharge;
}());
export { RateBasedCharge };
var SundryCharge = /** @class */ (function () {
    function SundryCharge() {
    }
    return SundryCharge;
}());
export { SundryCharge };
var TariffCode = /** @class */ (function () {
    function TariffCode() {
    }
    return TariffCode;
}());
export { TariffCode };
var TariffFormula = /** @class */ (function () {
    function TariffFormula() {
    }
    return TariffFormula;
}());
export { TariffFormula };
var TariffParameter = /** @class */ (function () {
    function TariffParameter() {
    }
    return TariffParameter;
}());
export { TariffParameter };
var FormulaParameter = /** @class */ (function () {
    function FormulaParameter() {
    }
    return FormulaParameter;
}());
export { FormulaParameter };
var FormulaComputation = /** @class */ (function () {
    function FormulaComputation() {
    }
    return FormulaComputation;
}());
export { FormulaComputation };
var CustomsPurposeFile = /** @class */ (function () {
    function CustomsPurposeFile() {
    }
    return CustomsPurposeFile;
}());
export { CustomsPurposeFile };
var LocalAgentCreditor = /** @class */ (function () {
    function LocalAgentCreditor() {
    }
    return LocalAgentCreditor;
}());
export { LocalAgentCreditor };
var LocalAgentRegistrations = /** @class */ (function () {
    function LocalAgentRegistrations() {
    }
    return LocalAgentRegistrations;
}());
export { LocalAgentRegistrations };
var PaymentType = /** @class */ (function () {
    function PaymentType() {
    }
    return PaymentType;
}());
export { PaymentType };
var Permit = /** @class */ (function () {
    function Permit() {
    }
    return Permit;
}());
export { Permit };
var GuaranteeType = /** @class */ (function () {
    function GuaranteeType() {
    }
    return GuaranteeType;
}());
export { GuaranteeType };
var UniqueConsignmentReference = /** @class */ (function () {
    function UniqueConsignmentReference() {
    }
    return UniqueConsignmentReference;
}());
export { UniqueConsignmentReference };
var UCRPaymentTerm = /** @class */ (function () {
    function UCRPaymentTerm() {
    }
    return UCRPaymentTerm;
}());
export { UCRPaymentTerm };
var OverseasAgent = /** @class */ (function () {
    function OverseasAgent() {
    }
    return OverseasAgent;
}());
export { OverseasAgent };
