import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ClearingFile, Importer} from '../../../models/clearingFile';
import {ImporterService} from '../../../services/importer.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {ShareDataService} from '../../../services/share-data.service';
import {Subscription} from 'rxjs';
import {CompanyService} from '../../../services/company.service';
import {Branch} from '../../../models/company';

@Component({
  selector: 'digi-importer',
  templateUrl: './importer.component.html',
  styleUrls: ['./importer.component.css']
})
export class ImporterComponent implements OnInit, OnDestroy {
  clearingFile: ClearingFile;
  @Output() selected = new EventEmitter();
  @Output() changed = new EventEmitter();
  importerAddresses = [];
  filteredImporters: Importer[];
  subscribe: Subscription;

  constructor(private importerService: ImporterService,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private shareDataService: ShareDataService,
              private companyService: CompanyService) {
  }

  ngOnInit() {
    this.subscribe = this.clearingFileBehaviourSubject
      .getClearingFile()
      .subscribe(cf => {
        this.clearingFile = cf;
        if (!this.clearingFile.importer.code) {
          this.companyService.getCompany().subscribe(companies => {
            if (companies && companies.length && !this.clearingFile.importer.code) {
              const branch: Branch = this.clearingFile.branch ? companies[0].branches
                .find(el => el.code === this.clearingFile.branch) : companies[0].branches[0];
              if (branch.importerCode && branch.importerCode.trim() !== '') {
                this.importerService.findImporterNameOrCodeStartsWith(branch.importerCode).subscribe(data => {
                  if (data && data.length) {
                    this.select(data[0]);
                  }
                });
              }
            }
          });
        } else {
          this.findImporterFromRole(this.clearingFile);
        }
      });
  }

  search(event) {
    this.importerService.findImporterNameOrCodeStartsWith(event.query).subscribe(data => {
      this.filteredImporters = data;
    });
  }

  select(value) {
    this.clearingFile.importer = Object.assign({}, value);
    this.findImporterFromRole(this.clearingFile);
    // this.selected.emit(value);
  }


  private findImporterFromRole(clearingFile) {
    this.importerService.findImporterFromClearingFile(clearingFile)
      .subscribe(importerData => {
        this.importerAddresses = importerData[0].addresses;
      });
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
}
