import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'digi-fin-invdetails-total',
  templateUrl: './fin-invdetails-total.component.html',
  styleUrls: ['./fin-invdetails-total.component.scss']
})
export class FinInvdetailsTotalComponent implements OnInit {
  @Input() capturedAmount: number;
  @Input() invoiceAmount: number;
  constructor() { }

  ngOnInit() {}

  getBalance() {
      return (this.invoiceAmount - this.capturedAmount).toFixed(2);
  }
}
