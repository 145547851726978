var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import * as moment from 'moment';
import { Address, Airline, Certificate, CertificateType, ClearingInstruction, CollectPrepaid, Container, ContainerRunningTotals, ContainerSize, ContainerSpecification, ContainerType, Country, Currency, CustomOffice, CustomsPurpose, CustomsPurposeFile, CustomsRadioCallSign, Debtor, DefaultValues, DepotTerminal, ExportTransportDocument, File, ForwardFile, Freight, HasSundryCharge, IncoTerm, Information, LocalAgent, LocalAgentCreditor, LocalAgentRegistrations, LocalForwarder, Measures, OceanLine, OceanLineDetails, OverseasAgent, Permit, PortDetail, PortTo, PreviousBoeDetails, PurchaseTerm, RateBasedCharge, Reference, Remover, RunningTotals, SealDetails, Solas, SundryCharge, Supplier, SupplierInvoice, SupplierInvoiceLine, TariffCode, TariffFormula, TariffParameter, TradeAgreement, TransportDocument, TransportMethod, UserInterfaceCheckbox, UserInterfaceDefaults, ValueBasedCharge, VoyageDetails } from '../models/file';
import { ClearingFile, Importer, ReportFile, PaymentFile, ClearingInstructionFile } from '../models/clearingFile';
import { BehaviorSubject } from 'rxjs';
import { BillOfEntry, BillOfEntryPermit, Destination, ExBondBillOfEntry, Export, ExportBillOfEntry } from '../models/billOfEntries';
import { BillsOfEntry, ExBondBillsOfEntry, ExportBillsOfEntry } from '../models/billsOfEntry';
import { ExportConsignee, Exporter, ExportFile, ShippingOrder } from '../models/exportFile';
import { ReceivingBank } from '../../digi-business-entity/models/business-entity';
import { CompanyService } from './company.service';
import { AgentService } from './agent.service';
import { BusinessEntityService } from '../../digi-business-entity/services/business-entity.service';
import { CustomOfficeService } from './custom-office.service';
import { ExBondFile, Warehouse } from '../models/exBondFile';
import { AuthorisationService } from '../../../subjects/authorisation-behaviour-subject';
import { map } from 'rxjs/operators';
import { ForwardingFile, TrackingDetails, ForwardingTransportDocument } from '../models/ForwardingFile';
import { CurrencyService } from './currency.service';
import { CollectPrepaidService } from './collect-prepaid.service';
import * as i0 from "@angular/core";
import * as i1 from "./company.service";
import * as i2 from "./agent.service";
import * as i3 from "../../digi-business-entity/services/business-entity.service";
import * as i4 from "./custom-office.service";
import * as i5 from "../../../subjects/authorisation-behaviour-subject";
import * as i6 from "./currency.service";
import * as i7 from "./collect-prepaid.service";
var ShareDataService = /** @class */ (function () {
    function ShareDataService(companyService, localAgentService, businessEntityService, customOfficeService, authorisationService, currencyService, collectPrepaidService) {
        this.companyService = companyService;
        this.localAgentService = localAgentService;
        this.businessEntityService = businessEntityService;
        this.customOfficeService = customOfficeService;
        this.authorisationService = authorisationService;
        this.currencyService = currencyService;
        this.collectPrepaidService = collectPrepaidService;
    }
    ShareDataService.prototype.calcTotalOnFiles = function (file, fileType) {
        var sum = 0;
        if (fileType === 'imports') {
            file.transportDocuments.forEach(function (t) {
                t.supplierInvoices.forEach(function (s) {
                    sum += +s.totalGrossIncomeValue;
                });
            });
        }
        else {
            file.transportDocument.supplierInvoices.forEach(function (s) {
                sum += +s.totalGrossIncomeValue;
            });
        }
        if (sum) {
            return sum;
        }
        return 0;
    };
    ShareDataService.prototype.calcTotalOnFilesForExports = function (file) {
        var sum = 0;
        if (file && file.transportDocument && file.transportDocument.supplierInvoices) {
            file.transportDocument.supplierInvoices.forEach(function (s) {
                sum += +s.totalGrossIncomeValue;
            });
        }
        if (sum) {
            return sum;
        }
        return 0;
    };
    ShareDataService.prototype.buildClearingFile = function (transportMethod, isQuotation) {
        var _this = this;
        this.clearingFile = new ClearingFile();
        if (isQuotation) {
            this.clearingFile.quotation = true;
        }
        this.getBranch().subscribe(function (branch) { return _this.clearingFile.branch = branch; });
        this.clearingFile.reference = new Reference();
        this.getCurrentUser().subscribe(function (user) { return _this.clearingFile.reference.channel = user; });
        this.clearingFile.importer = new Importer();
        this.clearingFile.importer.address = new Address();
        this.clearingFile.debtor = new Debtor();
        this.clearingFile.debtor.address = new Address();
        this.clearingFile.supplier = new Supplier();
        this.clearingFile.supplier.address = new Address();
        this.clearingFile.localAgent = new LocalAgent();
        this.clearingFile.localAgent.address = new Address();
        this.clearingFile.localAgent.localAgentCreditors = new Array();
        this.clearingFile.localAgent.customsOfficeCreditors = new Array();
        this.clearingFile.localAgent.registrations = new Array();
        this.clearingFile.customOffice = new CustomOffice();
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = _this.clearingFile.branch ? companies[0].branches
                    .find(function (el) { return el.code === _this.clearingFile.branch; }) : companies[0].branches[0];
                if (branch && branch.localAgentCode && branch.localAgentCode.trim() !== '') {
                    _this.localAgentService
                        .findLocalAgentNameOrCodeStartsWith(branch.localAgentCode)
                        .subscribe(function (data) {
                        if (data && data.length) {
                            _this.clearingFile.localAgent = data[0];
                        }
                    });
                    if (branch.customsOfficeDefaults.length) {
                        var defaultCustomsOffice = branch.customsOfficeDefaults
                            .find(function (value) { return value.declarationType === 'import' && value.transportMethod.method === transportMethod; });
                        if (defaultCustomsOffice) {
                            _this.customOfficeService.findAllCustomsOfficeStartWith(defaultCustomsOffice.customsOfficeCode).subscribe(function (data) {
                                _this.clearingFile.customOffice = data[0];
                            });
                        }
                    }
                }
            }
        });
        this.clearingFile.incoTerm = new IncoTerm();
        this.clearingFile.assessmentDate = moment().format('DD/MM/YYYY');
        this.clearingFile.slaDate = moment().format('DD/MM/YYYY');
        this.clearingFile.clearingInstructions = [];
        this.clearingFile.clearingInstructions[0] = new ClearingInstruction();
        this.clearingFile.clearingInstructions[0].customsPurpose = new CustomsPurpose();
        this.clearingFile.clearingInstructions[0].customsPurpose.customsPurposeFile = new CustomsPurposeFile();
        this.clearingFile.clearingInstructions[0].transportMethod = new TransportMethod();
        this.clearingFile.clearingInstructions[0].ribOrRitportDetails = new PortDetail();
        this.clearingFile.transportDocuments = new Array();
        var deliveryInstruction = new ReportFile();
        deliveryInstruction.title = 'Delivery Instruction';
        var fileCover = new ReportFile();
        deliveryInstruction.title = 'File Cover';
        var provisionalPayment = new PaymentFile();
        provisionalPayment.title = 'Provisional Payment';
        var clearingInstruction = new ClearingInstructionFile();
        clearingInstruction.title = 'Clearing Instruction';
        this.clearingFile.reportFiles = [deliveryInstruction, fileCover];
        this.clearingFile.paymentFiles = [provisionalPayment];
        this.clearingFile.clearingInstructionFiles = [clearingInstruction];
        return this.clearingFile;
    };
    ShareDataService.prototype.buildExportFile = function (transportMethod) {
        var _this = this;
        this.exportFile = new ExportFile();
        this.getBranch().subscribe(function (branch) { return _this.exportFile.branch = branch; });
        this.exportFile.reference = new Reference();
        this.getCurrentUser().subscribe(function (user) { return _this.exportFile.reference.channel = user; });
        this.exportFile.exporter = new Exporter();
        this.exportFile.exporter.address = new Address();
        this.exportFile.debtor = new Debtor();
        this.exportFile.debtor.address = new Address();
        this.exportFile.exportConsignee = new ExportConsignee();
        this.exportFile.exportConsignee.address = new Address();
        this.exportFile.localAgent = new LocalAgent();
        this.exportFile.localAgent.address = new Address();
        this.exportFile.localAgent.localAgentCreditors = new Array();
        this.exportFile.localAgent.customsOfficeCreditors = new Array();
        this.exportFile.localAgent.registrations = new Array();
        this.exportFile.customOffice = new CustomOffice();
        this.exportFile.transportDocument = this.addTransportDocument('exports');
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = _this.exportFile.branch ? companies[0].branches
                    .find(function (el) { return el.code === _this.exportFile.branch; }) : companies[0].branches[0];
                if (branch.localAgentCode && branch.localAgentCode.trim() !== '') {
                    _this.exportFile.localAgent = { code: branch.localAgentCode };
                }
                if (branch.exporterCode) {
                    _this.exportFile.exporter = { code: branch.exporterCode };
                }
                if (branch.customsOfficeDefaults) {
                    var defaultCustomsOffice = branch.customsOfficeDefaults
                        .find(function (value) { return value.declarationType === 'export' && value.transportMethod.method === transportMethod; });
                    if (defaultCustomsOffice) {
                        _this.customOfficeService.findAllCustomsOfficeStartWith(defaultCustomsOffice.customsOfficeCode).subscribe(function (data) {
                            _this.exportFile.customOffice = data[0];
                        });
                    }
                }
            }
        });
        this.exportFile.incoTerm = new IncoTerm();
        this.exportFile.assessmentDate = moment().format('DD/MM/YYYY');
        this.exportFile.slaDate = moment().format('DD/MM/YYYY');
        this.exportFile.clearingInstructions = [];
        this.exportFile.clearingInstructions[0] = new ClearingInstruction();
        this.exportFile.clearingInstructions[0].customsPurpose = new CustomsPurpose();
        this.exportFile.clearingInstructions[0].customsPurpose.customsPurposeFile = new CustomsPurposeFile();
        this.exportFile.clearingInstructions[0].transportMethod = new TransportMethod();
        return this.exportFile;
    };
    ShareDataService.prototype.buildExBondFile = function () {
        var _this = this;
        this.exBondFile = new ExBondFile();
        this.getBranch().subscribe(function (branch) { return _this.exBondFile.branch = branch; });
        this.exBondFile.reference = new Reference();
        this.getCurrentUser().subscribe(function (user) { return _this.exBondFile.reference.channel = user; });
        this.exBondFile.importer = new Importer();
        this.exBondFile.importer.address = new Address();
        this.exBondFile.debtor = new Debtor();
        this.exBondFile.debtor.address = new Address();
        this.exBondFile.localAgent = new LocalAgent();
        this.exBondFile.localAgent.address = new Address();
        this.exBondFile.localAgent.localAgentCreditors = new Array();
        this.exBondFile.localAgent.customsOfficeCreditors = new Array();
        this.exBondFile.localAgent.registrations = new Array();
        this.exBondFile.warehouse = new Warehouse();
        this.exBondFile.warehouse.address = new Address();
        this.exBondFile.customOffice = new CustomOffice();
        this.exBondFile.assessmentDate = moment().format('DD/MM/YYYY');
        this.exBondFile.slaDate = moment().format('DD/MM/YYYY');
        this.exBondFile.clearingInstructions = [];
        this.exBondFile.clearingInstructions[0] = new ClearingInstruction();
        this.exBondFile.clearingInstructions[0].customsPurpose = new CustomsPurpose();
        this.exBondFile.clearingInstructions[0].customsPurpose.customsPurposeFile = new CustomsPurposeFile();
        this.exBondFile.clearingInstructions[0].transportMethod = new TransportMethod();
        this.exBondFile.marksAndNumbers = new Array();
        this.exBondFile.endorsements = new Array();
        return this.exBondFile;
    };
    ShareDataService.prototype.addTransportDocument = function (type) {
        var _this = this;
        if (type === 'imports') {
            this.transportDocument = new TransportDocument();
            this.transportDocument.previousBOEDetails = new PreviousBoeDetails();
            this.transportDocument.previousBOEDetails.portTo = new PortTo();
            this.transportDocument.previousBOEDetails.customOffice = new CustomOffice();
        }
        else {
            if (type === 'exports') {
                this.transportDocument = new ExportTransportDocument();
            }
            else {
                this.transportDocument = new ForwardingTransportDocument();
            }
            this.transportDocument.exitPort = new PortDetail();
            this.transportDocument.notifyPartyAddress = new Address();
            this.transportDocument.coLoader = new LocalAgent();
            this.transportDocument.coLoader.address = new Address();
            this.transportDocument.overseasAgent = new OverseasAgent();
            this.transportDocument.overseasAgent.address = new Address();
            this.transportDocument.accountingInformation = new Information();
            this.transportDocument.handlingInformation = new Information();
        }
        this.transportDocument.airline = new Airline();
        this.transportDocument.freight = new Freight();
        this.transportDocument.freight.collectPrepaid = new CollectPrepaid();
        this.transportDocument.freight.currency = new Currency();
        this.transportDocument.freight.amount = 0;
        this.transportDocument.depotTerminal = new DepotTerminal();
        this.transportDocument.measures = new Measures();
        this.transportDocument.destinationPort = new PortDetail();
        this.transportDocument.finalDestinationPort = new PortDetail();
        this.transportDocument.departurePort = new PortDetail();
        this.transportDocument.docsIssuedAtPort = new PortDetail();
        this.transportDocument.dischargePort = new PortDetail();
        this.transportDocument.marksAndNumbers = new Array();
        this.transportDocument.endorsements = new Array();
        this.transportDocument.rateBasedCharges = new Array();
        this.transportDocument.valueBasedCharges = new Array();
        this.transportDocument.supplierInvoices = new Array();
        this.transportDocument.oceanLineDetails = new OceanLineDetails();
        this.transportDocument.oceanLineDetails.oceanLine = new OceanLine();
        this.transportDocument.oceanLineDetails.carrierCode = new OceanLine();
        this.transportDocument.voyageDetails = new VoyageDetails();
        this.transportDocument.voyageDetails.customsRadioCallSign = new CustomsRadioCallSign();
        this.transportDocument.voyageDetails.remover = new Remover();
        this.transportDocument.containers = new Array();
        this.transportDocument.containerRunningTotals = new ContainerRunningTotals();
        this.transportDocument.straight = true;
        this.transportDocument.localForwarder = new LocalForwarder();
        this.transportDocument.forwardFile = new ForwardFile();
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                if (type === 'imports') {
                    var branch = _this.clearingFile.branch ? companies[0].branches
                        .find(function (el) { return el.code === _this.clearingFile.branch; }) : companies[0].branches[0];
                    if (branch.freightCurrency) {
                        _this.currencyService.findCurrencyStartWith(branch.freightCurrency).subscribe(function (data) {
                            _this.transportDocument.freight.currency = data[0];
                        });
                    }
                    if (branch.freightAmount) {
                        _this.transportDocument.freight.amount = branch.freightAmount;
                    }
                    if (branch.freightCollectPrepaid) {
                        _this.collectPrepaidService.findCollectPrepaidStartWith(branch.freightCollectPrepaid).subscribe(function (data) {
                            _this.transportDocument.freight.collectPrepaid = data[0];
                        });
                    }
                }
                else {
                    var branch = _this.exportFile.branch ? companies[0].branches
                        .find(function (el) { return el.code === _this.exportFile.branch; }) : companies[0].branches[0];
                    if (branch.freightCurrency) {
                        _this.currencyService.findCurrencyStartWith(branch.freightCurrency).subscribe(function (data) {
                            _this.transportDocument.freight.currency = data[0];
                        });
                    }
                    if (branch.freightAmount) {
                        _this.transportDocument.freight.amount = branch.freightAmount;
                    }
                    if (branch.freightCollectPrepaid) {
                        _this.collectPrepaidService.findCollectPrepaidStartWith(branch.freightCollectPrepaid).subscribe(function (data) {
                            _this.transportDocument.freight.collectPrepaid = data[0];
                        });
                    }
                }
            }
        });
        return this.transportDocument;
    };
    ShareDataService.prototype.addContainer = function () {
        this.container = new Container();
        this.container.containerType = new ContainerType();
        this.container.solas = new Solas();
        this.container.containerSpecification = new ContainerSpecification();
        this.container.containerSpecification.containerSize = new ContainerSize();
        this.container.sealDetails = new SealDetails();
        this.container.terminal = new DepotTerminal();
        this.container.depot = new DepotTerminal();
        return this.container;
    };
    ShareDataService.prototype.addSupplierInvoice = function (selectedTransport) {
        this.supplierInvoice = new SupplierInvoice();
        this.supplierInvoice.supplier = new Supplier();
        this.supplierInvoice.supplier.address = new Address();
        this.supplierInvoice.currency = new Currency();
        this.supplierInvoice.defaultValues = new DefaultValues();
        this.supplierInvoice.defaultValues.country = new Country();
        this.supplierInvoice.defaultValues.purchaseTerm = new PurchaseTerm();
        this.supplierInvoice.userInterfaceDefaults = new UserInterfaceDefaults();
        this.supplierInvoice.runningTotals = new RunningTotals();
        this.supplierInvoice.lines = new Array();
        this.supplierInvoice.defaultValues.country.tradeAgreements = new Array();
        this.supplierInvoice.rateBasedCharges = new Array();
        this.supplierInvoice.valueBasedCharges = new Array();
        this.supplierInvoice.sequence = selectedTransport.supplierInvoices.length + 1;
        this.supplierInvoice.apportionWeightOnLines = true;
        this.supplierInvoice.certificate = new Certificate();
        this.supplierInvoice.certificate.type = new CertificateType();
        return this.supplierInvoice;
    };
    ShareDataService.prototype.addSupplierInvoiceLine = function (selectedInvoice, selectedInvoiceLine) {
        var invoiceLine;
        if (selectedInvoice.userInterfaceDefaults.autoRepeat && selectedInvoiceLine) {
            invoiceLine = __assign({}, selectedInvoiceLine);
        }
        else {
            invoiceLine = new SupplierInvoiceLine();
            invoiceLine.countryOfOrigin = __assign({}, selectedInvoice.defaultValues.country);
            invoiceLine.tradeAgreement = new TradeAgreement();
            invoiceLine.tariffCode = new TariffCode();
            invoiceLine.tariffCode.formula = new TariffFormula();
            invoiceLine.tariffCode.parameters = Array();
            invoiceLine.userInterfaceCheckbox = new UserInterfaceCheckbox();
            invoiceLine.discountPercent = 0;
            invoiceLine.discountValue = 0;
            invoiceLine.vdnPercent = 0;
            invoiceLine.additionalRebates = [];
            if (typeof (selectedInvoice.supplier.vdnMarkup) !== 'undefined') {
                invoiceLine.vdnPercent = selectedInvoice.supplier.vdnMarkup;
            }
            invoiceLine.permit = new Permit();
            invoiceLine.rooNumber = selectedInvoice.defaultValues.rooNumber;
            invoiceLine.previousBoeDetails = new PreviousBoeDetails();
        }
        if (selectedInvoice.userInterfaceDefaults.quickPart) {
            invoiceLine.userInterfaceCheckbox.quickPart = selectedInvoice.userInterfaceDefaults.quickPart;
        }
        if (selectedInvoice.userInterfaceDefaults.tariffMode) {
            invoiceLine.userInterfaceCheckbox.tariffMode = selectedInvoice.userInterfaceDefaults.tariffMode;
        }
        if (selectedInvoice.userInterfaceDefaults.autoClientOrderNumberClientRef) {
            invoiceLine.additionalClientReference = selectedInvoice.additionalClientReference;
        }
        if (selectedInvoice.userInterfaceDefaults.autoClientOrderNumberPart && selectedInvoiceLine) {
            invoiceLine.partNumber = selectedInvoiceLine.partNumber;
            invoiceLine.tariff = selectedInvoiceLine.tariff;
            invoiceLine.tariffCheckDigit = selectedInvoiceLine.tariffCheckDigit;
            invoiceLine.tariffDescription = selectedInvoiceLine.tariffDescription;
            invoiceLine.tariff = selectedInvoiceLine.tariff;
            invoiceLine.fullTariffDescription = selectedInvoiceLine.fullTariffDescription;
            invoiceLine.formula = selectedInvoiceLine.formula;
        }
        invoiceLine.lineNo = selectedInvoice.lines.length + 1;
        invoiceLine.ucrNumber = selectedInvoice.ucrNumber;
        if (selectedInvoice.ucr) {
            invoiceLine.ucrCreditTerms = selectedInvoice.ucr.paymentTerm.code;
            invoiceLine.ucrTransactionValue = selectedInvoice.ucr.transactionValue;
            invoiceLine.ucrTransactionValueCurrency = selectedInvoice.ucr.transactionCurrency.code;
        }
        return invoiceLine;
    };
    ShareDataService.prototype.addValueBasedCharge = function (hasSundryCharge) {
        var charge = new ValueBasedCharge();
        charge.currency = new Currency();
        charge.sundryCharge = new SundryCharge();
        var subject = new BehaviorSubject(charge);
        subject.subscribe(function (data) {
            charge = data;
        });
        subject.next(charge);
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = companies[0].branches[0];
                if (branch.defaultSundryChargeNonDutiable) {
                    charge.nonDutiableValue = true;
                    charge.nonDutiable = true;
                }
            }
        });
        hasSundryCharge.valueBasedCharges.push(charge);
        return hasSundryCharge.valueBasedCharges;
    };
    ShareDataService.prototype.addRateBasedCharge = function (hasSundryCharge) {
        var charge = new RateBasedCharge();
        charge.currency = new Currency();
        charge.sundryCharge = new SundryCharge();
        var subject = new BehaviorSubject(charge);
        subject.subscribe(function (data) {
            charge = data;
        });
        subject.next(charge);
        hasSundryCharge.rateBasedCharges.push(charge);
        return hasSundryCharge.rateBasedCharges;
    };
    ShareDataService.prototype.buildBillOfEntry = function (type) {
        if (type === 'imports') {
            this.billsOfEntry = new BillsOfEntry();
            this.billsOfEntry.boes[0] = new BillOfEntry();
            this.billsOfEntry.boes[0].importer = new Importer();
            this.billsOfEntry.boes[0].exporters = [];
            this.billsOfEntry.boes[0].lines = new Array();
            this.billsOfEntry.boes[0].export = new Export();
        }
        else if (type === 'exports') {
            this.billsOfEntry = new ExportBillsOfEntry();
            this.billsOfEntry.boes[0] = new ExportBillOfEntry();
            this.billsOfEntry.boes[0].exporter = new Exporter();
            this.billsOfEntry.boes[0].lines = new Array();
            this.billsOfEntry.boes[0].receivingBank = new ReceivingBank();
        }
        else {
            this.billsOfEntry = new ExBondBillsOfEntry();
            this.billsOfEntry.boes[0] = new ExBondBillOfEntry();
            this.billsOfEntry.boes[0].importer = new Importer();
            this.billsOfEntry.boes[0].lines = new Array();
        }
        this.billsOfEntry.boes[0].clearingInstruction = new ClearingInstruction();
        this.billsOfEntry.boes[0].markAndNumbers = [];
        this.billsOfEntry.boes[0].permits = [];
        this.billsOfEntry.boes[0].destination = new Destination();
        this.billsOfEntry.boes[0].permits[0] = new BillOfEntryPermit();
        this.billsOfEntry.boes[0].boeInvoiceDetails = new Array();
        return this.billsOfEntry.boes[0];
    };
    ShareDataService.prototype.buildForwardingFile = function (transportMethod) {
        var forwardingFile = new ForwardingFile();
        this.getBranch().subscribe(function (branch) { return forwardingFile.branch = branch; });
        forwardingFile.transportMethodCode = transportMethod;
        // @ts-ignore
        forwardingFile.masterBill = new ForwardingTransportDocument();
        forwardingFile.masterBill.freight = new Freight();
        forwardingFile.masterBill.freight.collectPrepaid = new CollectPrepaid();
        forwardingFile.masterBill.freight.currency = new Currency();
        forwardingFile.masterBill.destinationPort = new PortDetail();
        forwardingFile.masterBill.departurePort = new PortDetail();
        forwardingFile.masterBill.docsIssuedAtPort = new PortDetail();
        forwardingFile.masterBill.dischargePort = new PortDetail();
        forwardingFile.masterBill.measures = new Measures();
        forwardingFile.masterBill.oceanLineDetails = new OceanLineDetails();
        forwardingFile.masterBill.oceanLineDetails.oceanLine = new OceanLine();
        forwardingFile.masterBill.oceanLineDetails.carrierCode = new OceanLine();
        forwardingFile.masterBill.voyageDetails = new VoyageDetails();
        forwardingFile.masterBill.voyageDetails.customsRadioCallSign = new CustomsRadioCallSign();
        // @ts-ignore
        forwardingFile.masterBill.runningTotals = new RunningTotals();
        forwardingFile.reference = new Reference();
        forwardingFile.warehouse = new Warehouse();
        forwardingFile.customOffice = new CustomOffice();
        forwardingFile.masterBill.trackingDetails = new TrackingDetails();
        return forwardingFile;
    };
    ShareDataService.prototype.getBranch = function () {
        return this.authorisationService.getLoggedInUser().pipe(map(function (user) {
            if (user && user.attributes && user.attributes['branch']) {
                return user.attributes['branch'][0];
            }
        }));
    };
    ShareDataService.prototype.getCurrentUser = function () {
        return this.authorisationService.getLoggedInUser().pipe(map(function (user) {
            if (user) {
                if (user.firstName && user.lastName) {
                    return user.firstName + " " + user.lastName;
                }
                else {
                    return user.name;
                }
            }
            else {
                return "";
            }
        }));
    };
    ShareDataService.prototype.createShippingOrder = function (file, billOfEntry) {
        file.shippingOrder = new ShippingOrder();
        var transportDocument;
        if (file.type === 'ExportFile') {
            file.shippingOrder.exporter = file.exporter.name;
            transportDocument = file.transportDocument;
            file.shippingOrder.localPort = transportDocument.departurePort.name;
            file.shippingOrder.foreignPort = transportDocument.destinationPort.name;
        }
        else {
            file.shippingOrder.consignee = file.importer.name;
            transportDocument = file.transportDocuments[0];
            file.shippingOrder.localPort = transportDocument.destinationPort.name;
            file.shippingOrder.foreignPort = transportDocument.departurePort.name;
        }
        file.shippingOrder.vesselName = transportDocument.voyageDetails.customsRadioCallSign.vesselName;
        file.shippingOrder.voyageNumber = transportDocument.voyageDetails.voyageNo;
        file.shippingOrder.billOfLading = transportDocument.mawbNo;
        file.shippingOrder.transportMethodCode = 'ROA';
        file.shippingOrder.marksAndNumbers = transportDocument.marksAndNumbers;
        if (transportDocument.containers && transportDocument.containers.length) {
            file.shippingOrder.terminal = transportDocument.containers[0].terminal.name;
        }
        this.authorisationService.getLoggedInUser().subscribe(function (user) {
            file.shippingOrder.authorisedSignatory = user.name;
        });
        file.shippingOrder.boeNumber = billOfEntry.mrn;
        file.shippingOrder.boeDate = billOfEntry.releaseDate;
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = file.branch ? companies[0].branches
                    .find(function (el) { return el.code === file.branch; }) : companies[0].branches[0];
                if (branch) {
                    file.shippingOrder.accountNumber = file.type === 'ClearingFile' ? branch.landingOrderAccountNo : branch.shippingOrderAccountNo;
                }
            }
        });
    };
    ShareDataService.ngInjectableDef = i0.defineInjectable({ factory: function ShareDataService_Factory() { return new ShareDataService(i0.inject(i1.CompanyService), i0.inject(i2.AgentService), i0.inject(i3.BusinessEntityService), i0.inject(i4.CustomOfficeService), i0.inject(i5.AuthorisationService), i0.inject(i6.CurrencyService), i0.inject(i7.CollectPrepaidService)); }, token: ShareDataService, providedIn: "root" });
    return ShareDataService;
}());
export { ShareDataService };
