import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { BusinessEntity, Role, Debtor, Country, Currency} from 'src/app/digi-business-entity/models/business-entity';
import { NotificationService } from '../../services/notification.service';
import { BusinessEntityService } from '../../../digi-business-entity/services/business-entity.service';
import { ValidationResult } from '../../services/validation.service';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'digi-debtors-master',
  templateUrl: './debtors-master.component.html',
  styles: [`
    .ui-steps .ui-steps-item {
      width: 25%;
    }`],
  encapsulation: ViewEncapsulation.None

})
export class DebtorsMasterComponent implements OnInit, OnDestroy {
  statuses: SelectItem[];
  creditTerms: SelectItem[];
  includeFreightInCreditLimit: SelectItem[];
  debtorParentEntity: BusinessEntity;
  debtorEntity: Debtor;
  filteredDebtorEntities = [];
  disableButton: boolean;
  private branchCode: string;

  constructor(private businessEntityService: BusinessEntityService,
              private messageService: NotificationService) {
  }

  ngOnInit() {
        this.statuses = [
          { label: 'Active', value: 'Active'},
          { label: 'Hold', value: 'Hold'},
          { label: 'Suspend', value: 'Suspend'},
          { label: 'Provisional Credit Limit (PCL)', value: 'Provisional Credit Limit (PCL)'}
        ];
        this.creditTerms = [
          { label: 'Cur', value: 'Cur'},
          { label: '30 Days', value: '30 Days'},
          { label: '60 Days', value: '60 Days' },
          { label: '90 Days', value : '90 Days' },
          { label: '120 Days', value : '120 Days'}
        ];
        this.includeFreightInCreditLimit = [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' }
        ];
//         this.debtorEntity = new Debtor();
        this.clearDebtor();
  }

  search(event) {
    this.businessEntityService.findBusinessEntityStartsWithAndRoleType(event.query, 1).subscribe(data => {
      this.filteredDebtorEntities = data;
    });
  }

  saveDebtorEntity() {
      const validation = this.validate();
      if (validation.isValid) {
        let indexToUpdate = this.debtorParentEntity.roles.findIndex(role => role.roleType === 1);
        this.debtorParentEntity.roles[indexToUpdate] = this.debtorEntity;
        this.businessEntityService.saveBusinessEntity(this.debtorParentEntity)
          .subscribe(data => {
              this.debtorEntity = data.roles.find(role => role.roleType === 1);
              this.messageService.successNotify('Business Entity Saved');            },
            error => {
            },
          );
      } else {
        this.addFailure(validation.messages);
        return;
      }
  }

  clearDebtor() {
    this.debtorParentEntity = null;
    this.debtorEntity = new Debtor();
  }

  onSelectDebtorEntity(businessEntity: BusinessEntity) {
    this.debtorParentEntity = businessEntity;
    this.debtorEntity = this.debtorParentEntity.roles.find(role => role.roleType === 1);
  }

  selectCurrency(value: Currency): void {
    this.debtorEntity.currency = value;
  }

  selectCountry(value: Country): void {
    this.debtorEntity.country = value;
  }

  validate(): ValidationResult {
    const validation: ValidationResult = {
      isValid: true,
      messages: []
    };
    if (!this.debtorEntity || !this.debtorParentEntity || !this.debtorParentEntity.code) {
      validation.isValid = false;
      validation.messages.push('Cannot save debtor details, please select a Debtor');
      return validation;
    }
    if (!this.debtorEntity.currency) {
      validation.isValid = false;
      validation.messages.push('Cannot save debtor details, please enter currency');
    }
    if (!this.debtorEntity.country) {
      validation.isValid = false;
      validation.messages.push('Cannot save debtor details, please enter country of origin');
    }
    return validation;
  }

  addFailure(msg: any) {
    this.messageService.errorNotify(
      'Warning',
      msg,
    );
  }

  ngOnDestroy() {
//     if (this.invoicesSubscription) {
//       this.invoicesSubscription.unsubscribe();
//     }
  }
}

