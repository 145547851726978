<div class="ui-fluid">
  <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-6">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{selectedBillOfEntry?.numberOfPackages}}" readonly>
        <label> No Of Packages</label>
      </span>
    </div>
 <div class="ui-g-12 ui-md-6">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{selectedBillOfEntry?.numberOfPackages}}" readonly>
        <label> of</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12">
      <span class="md-inputfield">
        <input type="text" pInputText value="{{selectedBillOfEntry?.grossMass}}"
          readonly>
        <label> Gross Mass (KG)</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12">
      <span class="md-inputfield">
        <input type="text" pInputText value="0.00" readonly>
        <label> Bond Amount</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="fileType === 'imports'">
      <span class="md-inputfield">
        <input type="text" pInputText value="0.00" readonly>
        <label> PP Amount</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12">
      <span class="md-inputfield">
        <input type="text" pInputText
          value="{{selectedBillOfEntry?.totalRebateAmount}}" readonly>
        <label> Rebate Amount</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngIf="fileType === 'imports'">
      <span class="md-inputfield">
        <input type="text" pInputText value="0.00" readonly>
        <label>PEN Amount</label>
      </span>
    </div>
    <div class="ui-g-12 ui-md-12" *ngFor="let schedulePart of selectedBillOfEntry.schedulePartTotals" >
      <span class="md-inputfield" *ngIf="selectedBillOfEntry.customsProcedureCode!==40">
        <input type="text" pInputText value="{{isCancellation() ? 0 : schedulePart.totalValue}}" readonly>
        <label *ngIf="schedulePart.schedulePart.code === '12B'">1P2B</label>
        <label *ngIf="schedulePart.schedulePart.code !== '12B'">{{schedulePart.schedulePart.code}}</label>
      </span>
    </div>
  </div>
</div>
