import {Inject, Injectable} from '@angular/core';
import {ClearingFile} from '../models/clearingFile';
import {Container, Correction, RateBasedCharge, SupplierInvoice, ValueBasedCharge} from '../models/file';
import {ExportFile} from '../models/exportFile';
import {FileType} from '../models/enumerations';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import * as moment from 'moment';
import {ExBondFile} from '../models/exBondFile';

export interface ValidationResult {
  isValid: boolean;
  messages?: string[];
}

@Injectable({
  providedIn: 'root'
})

export class ValidationService {

  private component: any;

  setComponent(component: any) {
    this.component = component;
  }


  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
  }

  validateFileBasedOnStep(step: string, file: ClearingFile | ExportFile, fileType: FileType, errorsCallBack: (header: string, messages: string[]) => any) {
    let failed = false;
    switch (step) {
      case 'registration': {
        const result = this.validateRegistration(file, fileType);

        if (!result.isValid) {
          failed = errorsCallBack('Validation For Registration Failed', result.messages);
        }
        break;
      }
      case 'transportDocument': {
        if (file.transportDocument) {
          const result = this.validateTransportDocuments(file, fileType);
          if (!result.isValid) {
            failed = errorsCallBack('Validation For Transport Document Failed', result.messages);
          }
        }
        break;
      }
      case 'supplierInvoice': {
        let result = this.validateSupplierInvoicesIndependently(file, fileType); //Todo
        if (!result.isValid) {
          failed = errorsCallBack('Validation For Supplier Invoices Failed', result.messages);
        }

        if (!failed) {
          result = this.validateSupplierInvoiceLinesIndependently(file, fileType); //Todo
          if (!result.isValid) {
            failed = errorsCallBack('Validation For Supplier Invoice Lines Failed', result.messages);
          }
        }
        break;
      }
    }
    return failed;
  }
  validateRegistration(file: Correction |ClearingFile | ExportFile | ExBondFile, type: FileType): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    let isQuotation = file.quotation;
    if ((type === 'imports' || type === 'exbond' ) && !file.importer.code && !isQuotation) {
      this.fail(result, 'Please select Importer.');
    }
    if ((type === 'imports' || type === 'exports' || type === 'exbond')) {
        if (!file.reasons) {
          if (file.sequence !== 0 && file.clearingFileNumber ) {
              this.fail(result, 'Please enter reason for correction.');
          }
       }
    }
    if ( type === 'exbond' && !file.warehouse.code) {
      this.fail(result, 'Please select Warehouse.');
    }

    if (type === 'exports' && !file.exporter.code && !isQuotation) {
      this.fail(result, 'Please select Exporter.');
    }

    if (type === 'exports' && !file.exportConsignee.code && !isQuotation) {
      this.fail(result, 'Please select Export Consignee.');
    }

    if (!file.localAgent.code) {
      if ((type === 'imports' || type === 'exbond') && !isQuotation) {
        this.fail(result, 'Please select Clearing Agent.');
      } else if (!isQuotation) {
        this.fail(result, 'Please select Local Agent.');
      }
    } else {
      if ((!file.localAgent.customsRegCode || file.localAgent.customsRegCode === '') && !isQuotation) {
        if (type === 'imports') {
          this.fail(result, 'Clearing Agent is missing Customs Code. Please Reload Clearing Agent');
        } else {
          this.fail(result, 'Local Agent is missing Customs Code. Please Reload Local Agent');
        }
      }
    }

    if (!file.customOffice.districtOfficeCode && !isQuotation) {
      this.fail(result, 'Please select Custom Office.');
    }

    if (!file.clearingInstructions[0].customsPurpose.customsPurposeCode) {
      this.fail(result, 'Please select At least One Clearing Instruction.');
    }

    if (type !== 'exbond') {
      file.clearingInstructions.forEach(ci => {
        if (!ci.transportMethod || (ci.transportMethod && !ci.transportMethod.method)) {
          this.fail(result, `Please Select A Transport Mode for ${ci.customsPurpose.customsPurposeCode}`);
        }

        if (!ci.customsProcedureCode) {
          this.fail(result, `Please Select A Procedure Code for ${ci.customsPurpose.customsPurposeCode}`);
        }
      });
    }

    if (type === 'exports' && file.clearingInstructions[0].transportMethod.method === 'AIR' && file.transportDocument && !isQuotation) {

      if (file.transportDocument.shipmentType === 'BACK_TO_BACK') {
        if (!file.transportDocument.overseasAgent || (file.transportDocument.overseasAgent && !file.transportDocument.overseasAgent.code)) {
          this.fail(result, 'Please select Overseas Agent.');
        }
      }
    }

    return result;
  }

  public validateDateIsBefore(testDate: string): boolean {
    const date = moment(testDate, 'DD/MM/YYYY');
    const today = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY');
    return date.isBefore(today);
  }
  public validateCosting(allocatedLines) {
    const result: ValidationResult = {isValid: true, messages: []};
    this.validateCostingLinesUnits(allocatedLines, result);
    return result;
  }

  public validateCostingLinesUnits(allocatedLines, result) {
    const invalidLines = allocatedLines.filter(line => !line.costingValue);
    if (invalidLines.length > 0) {
      this.fail(result, 'The following costing line number(s) have an invalid unit cost: ['.concat(invalidLines.map(line => line.invoiceNumber.concat(' Line ').concat(line.lineNo)).join(', ').concat(']')));
    }
  }

  public validateTransportDocumentOnly(transportDocument, transportMethod, type: FileType) {
    const result: ValidationResult = {isValid: true, messages: []};
    this.validateTransportDocumentIndependently(transportDocument, transportMethod, result, type);
    return result;
  }

  private validateTransportDocumentIndependently(transportDocument, transportMethod, result, type: FileType) {

    if (!transportDocument) {
      this.fail(result, 'Please capture Transport Document');
    }
    if (type === 'imports' && !transportDocument.localForwarder.name && !transportDocument.straight) {
      this.fail(result, 'Please select Local Forwarder.');
    }

    if (!transportDocument.freight.currency.code) {
      this.fail(result, 'Please select currency on Freight.');
    }
    if (!transportDocument.freight.collectPrepaid.code) {
      this.fail(result, 'Please select Collect/Prepaid on Freight.');
    }
    if (!transportDocument.measures.numberOfPackages) {
      this.fail(result, 'Please enter Number Of Packages.');
    }
    if (!transportDocument.measures.grossWeight) {
      this.fail(result, 'Please enter Gross Weight.');
    }

    switch (transportMethod) {
      case 'SEA':
        if (!transportDocument.oceanLineDetails.oceanLine.code) {
          this.fail(result, 'No Ocean lines found.');
        }
        if (!transportDocument.oceanLineDetails.carrierCode.code) {
          this.fail(result, 'No Carrier Code found.');
        }
        if (!transportDocument.mawbNo) {
          this.fail(result, 'Please enter MOBL No.');
        }
        if (!transportDocument.hawbNo) {
          this.fail(result, 'Please enter HBOL No.');
        }
        if (!transportDocument.mawbDate) {
          this.fail(result, 'Please select SOB Date.');
        }
        if (!transportDocument.hawbDate) {
          this.fail(result, 'Please select HBOL Date.');
        }
        if (!transportDocument.voyageDetails.voyageNo) {
          this.fail(result, 'Please enter Voyage Number.');
        }
        if (!transportDocument.voyageDetails.customsRadioCallSign.radioCallSign) {
          this.fail(result, 'Please select Radio Call Sign.');
        }
        if (!transportDocument.voyageDetails.customsRadioCallSign.vesselName) {
          this.fail(result, 'Please enter Vessel Name.');
        }
        if (!transportDocument.departurePort.code) {
          this.fail(result, 'Please select Departure Port.');
        }
        if (!transportDocument.docsIssuedAtPort.code) {
          this.fail(result, 'Please select DocsIssued Port.');
        }
        if (!transportDocument.dischargePort.code) {
          this.fail(result, 'Please select Discharge Port.');
        }
        if (!transportDocument.destinationPort.code) {
          this.fail(result, 'Please select Destination Port.');
        }

        if (type === 'exports' && !transportDocument.exitPort.code) {
          this.fail(result, 'Please select Exit Port.');
        }
        break;
      case 'AIR':
        if (!transportDocument.airline.code) {
          this.fail(result, 'No Airlines found.');
        }
        if (!transportDocument.mawbNo) {
          this.fail(result, 'Please enter MAWB No.');
        } else if (transportDocument.validateMawbNo) {
          const checkDigit = this.calculateCheckDigit(transportDocument.mawbNo);
          const lastNumber = transportDocument.mawbNo.toString().substring(7, 8);
          const convertToNumber = +lastNumber;

          const validMawbNo = transportDocument.mawbNo.toString();
          if (validMawbNo.length !== 8) {
            this.fail(result, 'MAWB No should be at least 8 digits long');
          } else {
            if (checkDigit !== convertToNumber) {
              this.fail(result, 'Invalid Airline Check Digit.');
            }
          }
        }

        if (type === 'imports' && transportDocument.validateHawbNo && transportDocument.duplicateHawbNo) {
          this.fail(result, 'Duplicate HAWB Number found.');
        }

        if (!transportDocument.mawbDate) {
          this.fail(result, 'Please select MAWB Date.');
        }
        if (!transportDocument.hawbDate) {
          this.fail(result, 'Please select HAWB Date.');
        }
        if (!transportDocument.departureDate) {
          this.fail(result, 'Please select Departure Date.');
        }
        if (!transportDocument.arrivalDate) {
          this.fail(result, 'Please select Arrival Date.');
        }
        if (!transportDocument.flight) {
          this.fail(result, 'Please Enter Flight.');
        }
        if (!transportDocument.depotTerminal.code) {
          this.fail(result, 'Please select Degroup/Transit Depot.');
        }
        break;
      case 'ROA':
        if (!transportDocument.transporter || !transportDocument.transporter.transporterCode) {
          this.fail(result, 'No Transporter found.');
        }
        if (!transportDocument.mawbNo && type === 'imports') {
          this.fail(result, 'Please enter Manifest No.');
        }
        if (!transportDocument.hawbNo && type === 'imports') {
          this.fail(result, 'Please enter Waybill No.');
        }
        if (!transportDocument.mawbDate) {
          this.fail(result, 'Please select SOB Date.');
        }
        if (!transportDocument.hawbDate) {
          this.fail(result, 'Please select HBOL Date.');
        }
        if (!transportDocument.departurePort.code) {
          this.fail(result, 'Please select Departure Port.');
        }
        if (!transportDocument.docsIssuedAtPort.code) {
          this.fail(result, 'Please select DocsIssued Port.');
        }
        if (!transportDocument.dischargePort.code) {
          this.fail(result, 'Please select Discharge Port.');
        }
        if (!transportDocument.destinationPort.code) {
          this.fail(result, 'Please select Destination Port.');
        }
        break;
    }

    if (!this.isAllSundryValid(transportDocument.valueBasedCharges, transportDocument.rateBasedCharges)) {
      this.fail(result, 'Please ensure that all charges have currency and charge description.');
    }
  }

  validateTransportDocuments(file: Correction | ClearingFile | ExportFile, type: FileType): ValidationResult {
    const result: ValidationResult = {isValid: true, messages: []};
    if (file.quotation) {
      return result;
    }
    if (type === 'imports') {
      if (!file.transportDocuments || !file.transportDocuments.length) {
        this.fail(result, 'Please capture Transport Document');
        return result;
      }
    } else {
      if (!file.transportDocument) {
        this.fail(result, 'Please capture Transport Document');
        return result;
      }
    }
    if ((type === 'imports' || type === 'exports' || type === 'exbond')) {
      if (!file.reasons) {
        if (file.sequence !== 0 && file.clearingFileNumber ) {
          this.fail(result, 'Please enter reason for correction.');
        }
      }
    }

    const transportMethod = file.clearingInstructions[0].transportMethod.method;
    const transportDocuments = type === 'imports' ? file.transportDocuments : [file.transportDocument];
    transportDocuments.forEach(transportDocument => {
      this.validateTransportDocumentIndependently(transportDocument, transportMethod, result, type);
      switch (transportMethod) {
        case 'SEA':
          if ((transportDocument.containers.length > 0 && type === 'imports')) {
            transportDocument.containers.forEach((c: Container) => {
              if (!c.containerType.code) {
                this.fail(result, 'No Container Type found.');
              }
              if ((c.containerType.code
                && c.containerType.code.trim() !== 'BBK'
                && c.containerType.code.trim() !== 'BLK') && !c.containerNo) {
                this.fail(result, 'Please enter Container Number.');
              }
              if ((c.containerType.code
                && c.containerType.code.trim() !== 'BBK'
                && c.containerType.code.trim() !== 'BLK')
                && !c.containerSpecification.containerSize.code) {
                this.fail(result, 'Please select Container Size.');
              }
              if ((c.containerType.code
                && c.containerType.code.trim() !== 'BBK'
                && c.containerType.code.trim() !== 'BLK')
                && !transportDocument.measures.noOfContainers) {
                this.fail(result, 'Please enter No Of Containers.');
              }
              this.isTerminalDepotValid(result, c.terminal, c.depot);
            });
          }
          break;
        case 'ROA':
          if (transportDocument.containers.length > 0) {
            transportDocument.containers.forEach((c: Container) => {
              if (c.containerType.code && (c.containerType.code.trim() === 'LCL' || c.containerType.code.trim() === 'FCLG')
                && !c.containerSpecification.containerSize.code) {
                this.fail(result, 'Please select Container Size.');
              }
            });
          }
          break;
        case 'AIR':
          // Do nothing already validated in above call.
          break;
      }
    });

    return result;
  }

  private isTerminalDepotValid(result, terminal, depot) {
    let hasTerminal = false;
    let hasDepot = false;

    if (terminal && terminal.code) {
      hasTerminal = true;
    }
    if (depot && depot.code) {
      hasDepot = true;
    }

    if (hasTerminal && hasDepot) {
      this.fail(result, 'Please select only one of Terminal Or Depot.');
    }

    if (!hasTerminal && !hasDepot) {
      this.fail(result, 'Please select either a Terminal Or Depot.');
    }
  }

  validateSupplierInvoices(file: ClearingFile | ExportFile, type: FileType) { //Todo : called when naving to BOE
    const result: ValidationResult = {isValid: true, messages: []};
    if (type === 'imports') {
      if (!file.transportDocuments || !file.transportDocuments.length) {
        this.fail(result, 'Please capture Transport Document');
        return result;
      }

      file.transportDocuments.forEach(t => {
        if (!t.supplierInvoices || !t.supplierInvoices.length) {
          this.fail(result, 'Please capture Supplier Invoice');
          return result;
        }
      });
    } else {
      if (!file.transportDocument) {
        this.fail(result, 'Please capture Transport Document');
        return result;
      }

      if (!file.transportDocument.supplierInvoices || !file.transportDocument.supplierInvoices.length) {
        this.fail(result, 'Please capture Supplier Invoice');
        return result;
      }
    }

    return this.validateSupplierInvoicesIndependently(file, type);
  }

  validateSupplierInvoicesIndependently(file: ClearingFile | ExportFile, type: FileType) {
    const result: ValidationResult = {isValid: true, messages: []};
    if (file.quotation) {
      return result;
    }
    if (type === 'imports') {
      file.transportDocuments.forEach(t => {
        this.validateSupplierInvoicesOnTransportDocument(t.supplierInvoices, type, result, file.clearingInstructions[0].transportMethod.method, file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim());
      });
    } else {
      this.validateSupplierInvoicesOnTransportDocument(file.transportDocument.supplierInvoices, type, result, file.clearingInstructions[0].transportMethod.method, file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim());
    }
    return result;
  }

  validateSupplierInvoicesOnTransportDocument(supplierInvoices: SupplierInvoice[], type: FileType, result: ValidationResult, transportMethod: string, customsPurposeCode: string) {
    supplierInvoices.forEach(s => {
      if (!s.invoiceNumber) {
        this.fail(result, 'Please Enter Invoice Number');
      }
      if (!s.invoiceDate) {
        this.fail(result, 'Please Enter Invoice Date');
      }
      if (!s.defaultValues.country || !s.defaultValues.country.code) {
        this.fail(result, 'Please select Country.');
      }
      if (!s.defaultValues.purchaseTerm || !s.defaultValues.purchaseTerm.code) {
        this.fail(result, 'Please select Purchase Term.');
      }
      if (!s.currency || (s.currency && !s.currency.code)) {
        this.fail(result, 'Please select Currency.');
      }
      if (!s.totalGrossIncomeValue && ((customsPurposeCode !== 'WH' && customsPurposeCode !== 'WE') || type !== 'imports')) {
        this.fail(result, 'Please Enter Total Gross Value.');
      }
      if (!s.quantity && ((customsPurposeCode !== 'WH' && customsPurposeCode !== 'WE') || type !== 'imports')) {
        this.fail(result, 'Please Enter Quantity.');
      }
      if (type !== 'imports' && transportMethod === 'SEA') {
        if (!s.ucrNumber) {
          this.fail(result, 'Please Add UCR.');
        }
        if (s.supplier && !s.supplier.code) {
          this.fail(result, 'Please select Export Consignee.');
        }
      }

      const invoiceDate = moment(s.invoiceDate, 'DD/MM/YYYY');
      const today = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY');
      if (invoiceDate.isAfter(today)) {
        this.fail(result, 'Invoice date cannot be a future date');
      }
      const isSaveDisabled = this.component.isSaveDisabled();
      if (!isSaveDisabled) {
        // tslint:disable-next-line:max-line-length
        if (!this.isAllSundryValid(s.valueBasedCharges, s.rateBasedCharges) || !this.isValueBasedChargeOptionSelected(s.valueBasedCharges)) {
          this.fail(result, 'Please ensure that all charges have currency, charge description, and required options are selected.');
        }
    }
    });
  }

  validateSupplierInvoiceLines(file: Correction | ClearingFile | ExportFile, type: FileType) { //Todo : called on naving to BOE
    const result: ValidationResult = {isValid: true, messages: []};
    if (file.quotation) {
      return result;
    }
    if (type === 'imports') {
      if (!file.transportDocuments || !file.transportDocuments.length) {
        this.fail(result, 'Please capture Transport Document');
        return result;
      }
      if ((type === 'imports' || type === 'exports' || type === 'exbond')) {
        if (!file.reasons) {
          if (file.sequence !== 0 && file.clearingFileNumber ) {
            this.fail(result, 'Please enter reason for correction.');
          }
        }
      }
      file.transportDocuments.forEach(t => {
        if (!t.supplierInvoices || !t.supplierInvoices.length) {
          this.fail(result, 'Please capture Supplier Invoice');
          return result;
        }
        t.supplierInvoices.forEach(s => {
          if (!s.lines || !s.lines.length) {
            this.fail(result, 'Please capture Supplier Invoice Lines for invoice: ' + s.invoiceNumber);
            return result;
          }

          // if (s.lines.length) {
          //   if (s.lines.length && s.runningTotals && s.runningTotals.capturedValue !== +s.totalGrossIncomeValue) {
          //     this.fail(result, 'The Captured invoice value is not equal to the Total Invoice Value for invoice: ' + s.invoiceNumber);
          //   }
          // }
        });
      });
    } else {
      if (!file.transportDocument) {
        this.fail(result, 'Please capture Transport Document');
        return result;
      }

      if (!file.transportDocument.supplierInvoices || !file.transportDocument.supplierInvoices.length) {
        this.fail(result, 'Please capture Supplier Invoice');
        return result;
      }
      file.transportDocument.supplierInvoices.forEach(s => {
        if (!s.lines || !s.lines.length) {
          this.fail(result, 'Please capture Supplier Invoice Lines');
          return result;
        }
      });
    }

    if (!result.isValid) {
      return result;
    }

    return this.validateSupplierInvoiceLinesIndependently(file, type);
  }

  validateSupplierInvoiceLinesIndependently(file: ClearingFile | ExportFile, type: FileType) {
    const result: ValidationResult = {isValid: true, messages: []};
    if (type === 'imports') {
      file.transportDocuments.forEach(t => {
        this.validateSupplierInvoiceLinesOnTransportDocument(file, t.supplierInvoices, type, result);
      });
    } else {
      this.validateSupplierInvoiceLinesOnTransportDocument(file, file.transportDocument.supplierInvoices, type, result);
    }
    if (file.clearingInstructions.length > 1) {
      file.clearingInstructions.forEach(ci => {
        let matched = false;
        if (type === 'imports') {
          file.transportDocuments.forEach(t => {
            t.supplierInvoices.forEach(si => {
              si.lines.forEach(l => {
                if (!matched) {
                  matched = l.instructionUUID === ci.uuid;
                }
              });
            });
          });
        } else {
          file.transportDocument.supplierInvoices.forEach(si => {
            si.lines.forEach(l => {
              if (!matched) {
                matched = l.instructionUUID === ci.uuid;
              }
            });
          });
        }
        if (!matched) {
          this.fail(result, `Clearing Instruction [${ci.customsPurpose.customsPurposeCode}, ${ci.customsProcedureCode}] is not associated to any line.`);
        }
      });
    }
    return result;
  }

  validateSupplierInvoiceLinesOnTransportDocument(file: ClearingFile | ExportFile, supplierInvoices: SupplierInvoice[], type: FileType, result: ValidationResult) { //Todo
    if (file.quotation) {
      return result;
    }
    supplierInvoices.forEach(s => {
      if (s.lines.length > 0) {
        s.lines.forEach(l => {
          if (!l.tariffCode.code) {
            this.fail(result, `Please select Tariff on line #${l.lineNo} of invoice ${s.invoiceNumber}.`);
          }
          if (!l.invoiceGrossValue) {
            this.fail(result, `Please Enter Invoice Gross Value on line #${l.lineNo} of invoice ${s.invoiceNumber}.`);
          }
          if (!l.lineQuantity) {
            this.fail(result, `Please Enter Line Quantity on line #${l.lineNo} of invoice ${s.invoiceNumber}`);
          }
          if (!l.lineWeight) {
            this.fail(result, `Please Enter Line Weight on line #${l.lineNo} of invoice ${s.invoiceNumber}`);
          }
          if (file.clearingInstructions.length > 1 &&
            ((l.instructionIndex === null || l.instructionIndex === undefined) && !l.instructionUUID)
          ) {
            this.fail(result, `Please select Clearing Instruction To link on line #${l.lineNo} of invoice ${s.invoiceNumber}`);
          }
          if (type !== 'imports' && file.clearingInstructions[0].transportMethod.method === 'SEA') {
            if (!l.ucrNumber) {
              this.fail(result, `Please Add UCR on line #${l.lineNo} of invoice ${s.invoiceNumber}.`);
            }
          }
          if (l.additionalUoms && l.additionalUoms.length > 0) {
            const invalidEntry = l.additionalUoms.find(el => !el.value);
            if (invalidEntry) {
              this.fail(result, `Please add value for Additional UOM in line ${l.lineNo} of ${s.invoiceNumber}`);
            }
          }
          if (file.clearingInstructions[l.instructionIndex].customsPurpose.customsPurposeCode.trim() === 'WH' ||
            file.clearingInstructions[l.instructionIndex].customsPurpose.customsPurposeCode.trim() === 'WE') {
           if (!l.noOfPacks) {
             this.fail(result, `Please Add Number of Packs on line #${l.lineNo} of invoice ${s.invoiceNumber}.`);
           }
            if (!l.packType) {
              this.fail(result, `Please Add Pack Type on line #${l.lineNo} of invoice ${s.invoiceNumber}.`);
            }
          }
        });
      }
    });
  }

  fail(result: ValidationResult, message: string): any {
    result.isValid = false;
    result.messages.push(message);
  }

  calculateCheckDigit(mawbNo) {
    const firstSeven = mawbNo.toString().substring(0, 7);
    const convertToNumber = +firstSeven;
    const dividedNumber = convertToNumber / 7;
    const roundedValue = Math.floor(dividedNumber);
    const multipliedNumber = roundedValue * 7;
    return convertToNumber - multipliedNumber;
  }

  private isAllSundryValid(valueBasedCharges, rateBasedCharges): boolean {
    const valueBasedChargesMissingCurrency = valueBasedCharges
      .find(valueBasedCharge => valueBasedCharge.currency && !valueBasedCharge.currency.code);

    const rateBasedChargesMissingCurrency = rateBasedCharges
      .find(rateBasedCharge => rateBasedCharge.currency && !rateBasedCharge.currency.code);

    const isValueBasedChargesMissingChargeDescription = valueBasedCharges.find(v => !this.isValueBasedChargeDescriptionSelected(v));
    const isRateBasedChargesMissingChargeDescription = rateBasedCharges.find(r => !this.isRateBasedChargeDescriptionSelected(r));

    const isValueBasedChargesMissingAmount = valueBasedCharges.find(v => !v.amount);

    const isValueBasedChargesSundryChargeType = valueBasedCharges.find(v => !v.sundryCharge.code);

    return !(valueBasedChargesMissingCurrency || rateBasedChargesMissingCurrency
      || isValueBasedChargesMissingChargeDescription
      || isRateBasedChargesMissingChargeDescription
      || isValueBasedChargesMissingAmount
      || isValueBasedChargesSundryChargeType);
  }


  private isValueBasedChargeDescriptionSelected(v: ValueBasedCharge) {
    return v.nonDutiableValue || v.collect || v.dutiable || v.nonDutiable || v.inlandDutiable || v.inlandNonDutiable || v.inlandDestinationCountry || v.insurance || v.discount || v.freight;
  }

  private isRateBasedChargeDescriptionSelected(r: RateBasedCharge) {
    return r.dutiable || r.nonDutiable;
  }
  private isValueBasedChargeOptionSelected(valueBasedCharges: ValueBasedCharge[]): boolean {
    return valueBasedCharges.every(v => {
      const isOptionSelected = v.collect || v.dutiable || v.inlandDutiable || v.inlandNonDutiable || v.freight || v.discount || v.insurance || v.nonDutiable || v.inlandDestinationCountry;
      const isCollectAndOtherOptionSelected = v.collect && (v.dutiable || v.inlandDutiable || v.inlandNonDutiable || v.freight || v.discount || v.insurance || v.nonDutiable || v.inlandDestinationCountry);
      return isOptionSelected && (!v.collect || isCollectAndOtherOptionSelected);
    });
  }
}
