import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BusinessEntity, RoleAddress} from '../../../digi-business-entity/models/business-entity';
import {BusinessEntityService} from '../../../digi-business-entity/services/business-entity.service';
import {Address, PurchaseTerm} from '../../models/file';
import {BusinessEntitySearchService} from '../../services/business-entity-search.service';
import {ExportFileBehaviourSubject} from '../../../../subjects/exportfile-behaviour-subject';
import {Subscription} from 'rxjs';
import {WarehouseBehaviourSubject} from '../../../../subjects/warehouse-behaviour-subject';
import {DebtorBehaviourSubject} from '../../../../subjects/debtor-behaviour-subject';
import {NotificationService} from '../../services/notification.service';
import {ClearingInstructionBehaviorSubject} from '../../../../subjects/clearingInstruction-behavior-subject';

@Component({
  selector: 'digi-business-entity-search',
  templateUrl: './business-entity-search.component.html',
  styleUrls: ['./business-entity-search.component.scss']
})
export class BusinessEntitySearchComponent implements OnInit, OnChanges, OnDestroy {

  @Input() requiresValidation: boolean = true;
  @Input() roleID: number;
  @Input() roleName: string;
  @Input() showAddress: boolean;
  @Input() businessEntity;
  @Input() businessEntities;
  @Input() isDropdown = false;
  @Input() isNomination = true;
  @Input() declarantCode: string;
  @Input() declarantRoleId: number;
  @Input() canAdd = false;
  @Output() selectedBusinessEntity = new EventEmitter();
  addresses: Address[];
  filteredBusinessEntities: BusinessEntity[];

  businessEntityServiceSubscription: Subscription;
  businessEntitySearchServiceSubscription: Subscription;
  displayModal: boolean;

  constructor(
    private businessEntityService: BusinessEntityService,
    private businessEntitySearchService: BusinessEntitySearchService,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private warehouseBehaviourSubject: WarehouseBehaviourSubject,
    private debtorBehaviourSubject: DebtorBehaviourSubject,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private messageService: NotificationService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.businessEntity) {
      const currValue = changes.businessEntity.currentValue;
      if (currValue && currValue.code) {
        this.businessEntity = currValue;
        this.loadBusinessEntityDetails(currValue);
        if (!currValue.name) {
          this.businessEntityServiceSubscription = this.businessEntityService.findBusinessEntityStartsWithAndRoleType(currValue.code, this.roleID)
            .subscribe(
              businessEntities => this.select(businessEntities[0])
            );
        }
      }
    }
    if (changes.businessEntities) {
      this.filteredBusinessEntities = changes.businessEntities.currentValue;

      if (this.filteredBusinessEntities && this.filteredBusinessEntities.length === 1) {
        if (![5].includes(this.roleID)) {
          this.select(this.filteredBusinessEntities[0]);
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.businessEntitySearchServiceSubscription) {
      this.businessEntitySearchServiceSubscription.unsubscribe();
    }

    if (this.businessEntityServiceSubscription) {
      this.businessEntityServiceSubscription.unsubscribe();
    }
  }

  search(event: any) {
    if (!this.isNomination) {
      this.businessEntityServiceSubscription = this.businessEntityService.findBusinessEntityStartsWithAndRoleType(event.query, this.roleID)
        .subscribe(
          businessEntities => this.filteredBusinessEntities = businessEntities
        );
    } else if (this.filteredBusinessEntities) {
      this.filteredBusinessEntities = this.businessEntities.filter(be => be.code && be.code.toLowerCase().startsWith(event.query.toLowerCase()));
    }
  }

  select(value) {
    this.businessEntity = value;
    this.updateBusinessEntity(value);
  }

  openModal() {
    this.displayModal = true;
  }

  onBusinessEntityCreated(businessEntity: BusinessEntity) {
    this.displayModal = false;
    this.businessEntity = businessEntity;
    this.select(businessEntity);
  }

  private loadBusinessEntityDetails(businessEntityOrNomination: any) {
    this.businessEntitySearchServiceSubscription = this.businessEntitySearchService.findBusinessEntityNameAndRole(businessEntityOrNomination.code, this.roleID)
      .subscribe((businessEntities: BusinessEntity[]) => {
        this.updateBusinessEntityDetails(businessEntities, businessEntityOrNomination, false);
      });
  }

  private updateBusinessEntity(businessEntityOrNomination: any) {
    this.businessEntitySearchServiceSubscription = this.businessEntitySearchService.findBusinessEntityNameAndRole(businessEntityOrNomination.code, this.roleID)
      .subscribe((businessEntities: BusinessEntity[]) => {
        this.updateBusinessEntityDetails(businessEntities, businessEntityOrNomination);
        this.selectedBusinessEntity.emit(this.businessEntity);
      });
  }

  private updateBusinessEntityDetails(businessEntities: BusinessEntity[], businessEntityOrNomination: any, updateAddress: boolean = true) {
    if (!this.businessEntity.name) {
      this.businessEntity.name = businessEntities[0].name;
      this.businessEntity.label = businessEntities[0].label;
    }
    this.addresses = businessEntities[0].addresses;
    let address: Address;
    if (this.addresses && this.addresses.length) {
      if (this.addresses.length === 1) {
        address = this.addresses[0];
      } else {
        address = this.addresses.find(a => {
          const addressType = a.addressTypes.find(at => {
            return at.id === 'CUS';
          });
          if (addressType) {
            return true;
          } else {
            return false;
          }
        });
      }
      if (updateAddress) {
        this.businessEntity.address = address;
      }
    } else {
      this.businessEntity.address = new Address();
    }

    switch (this.roleID) {
      case 2: {
        this.businessEntity.country = businessEntities[0].country;
        this.businessEntity.termsOfPayment = businessEntities[0].termsOfPayment;
        this.businessEntity.hasBankingDetails = businessEntities[0].hasBankingDetails;
        this.businessEntity.commercialBankName = businessEntities[0].commercialBankName;
        this.businessEntity.commercialBankCode = businessEntities[0].commercialBankCode;
        this.businessEntity.commercialBankAccNo = businessEntities[0].commercialBankAccNo;
        this.businessEntity.commercialBankSwiftCode = businessEntities[0].commercialBankSwiftCode;
        if (!this.businessEntity.purchaseTerm || (this.businessEntity.purchaseTerm && !this.businessEntity.purchaseTerm.code)) {
          if (this.filteredBusinessEntities) {
            const consignee = this.filteredBusinessEntities.find(be => be.code === this.businessEntity.code);
            if (consignee) {
              this.businessEntity.defaultPurchaseTerm = consignee.defaultPurchaseTerm;
            }
          }
        }
        break;
      }
      case 4: {
        this.businessEntity.iataCode = businessEntities[0].iataCode;
        this.businessEntity.customsOfficeCreditors = businessEntities[0].customsOfficeCreditors;
        break;
      }
      case 6: {
        this.businessEntity.debtors = businessEntities[0].debtors;
        this.debtorBehaviourSubject.addDebtors(businessEntities[0].debtors);
        this.businessEntity.warehouses = businessEntities[0].warehouses;
        this.warehouseBehaviourSubject.addWarehouses(businessEntities[0].warehouses);
        this.businessEntity.rebateUsers = businessEntities[0].rebateUsers;
        this.clearingInstructionBehaviorSubject.addRebateUsers(businessEntities[0].rebateUsers);
        break;
      }
      case 9: {
        this.businessEntity.customsCode = businessEntities[0].customsCode;
        break;
      }
      case 12: {
        this.businessEntity.exportConsignees = businessEntities[0].exporterConsignees;
        this.exportFileBehaviourSubject.addExportConsignees(businessEntities[0].exporterConsignees);
        this.businessEntity.debtors = businessEntities[0].debtors;
        this.debtorBehaviourSubject.addDebtors(businessEntities[0].debtors);
        this.businessEntity.overseasAgents = businessEntities[0].overseasAgents;
        this.businessEntity.idNumber = businessEntities[0].idNumber;
        this.businessEntity.passportNumber = businessEntities[0].passportNumber;
        this.businessEntity.receivingBank = businessEntities[0].receivingBank;
        this.businessEntity.registrationType = businessEntities[0].registrationType;
        this.exportFileBehaviourSubject.addOverseasAgents(businessEntities[0].overseasAgents);
        this.exportFileBehaviourSubject.addExporterConsignor(businessEntities[0].exporterConsignors);
        this.exportFileBehaviourSubject.addWarehouses(businessEntities[0].warehouses);
        this.exportFileBehaviourSubject.addRemovers(businessEntities[0].removers);
        this.exportFileBehaviourSubject.addGuarantors(businessEntities[0].guarantors);
        this.businessEntity.currency = businessEntities[0].currency;
        break;
      }
      case 13: {
        this.businessEntity.customsWarehouseNumber = businessEntities[0].customsWarehouseNumber;
        break;
      }
      default: {
        break;
      }
    }
  }

  saveBusinessEntity() {
    this.businessEntity.registeredName = this.businessEntity.name;
    const role = {roleType: this.roleID};
    this.businessEntity.roles = [];
    this.businessEntity.roles.push(role);
    this.businessEntity.roles = [...this.businessEntity.roles];
    const address = Object.assign({}, this.businessEntity.address);
    this.businessEntityService.saveBusinessEntity(this.businessEntity)
      .subscribe(be => {
        this.businessEntity = be;
        if (address && address.line1) {
          this.businessEntity.addresses = [];
          this.businessEntity.addresses.push(address);
          this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(updatedBE => {
            this.businessEntity = updatedBE;
            const beRole = this.businessEntity.roles.find(el => el.roleType === this.roleID);
            const roleAddress = new RoleAddress();
            roleAddress.address = this.businessEntity.addresses[0];
            roleAddress.addressType = {id: 'CUS', description: 'Customs Address'};
            beRole.roleAddresses = [roleAddress];
            this.businessEntityService.saveBusinessEntity(this.businessEntity).subscribe(beWithRoleAddress => {
              this.businessEntity = beWithRoleAddress;
              this.messageService.successNotify(this.roleName + ' Saved');
              this.select(this.businessEntity);
            });
          });
        }
      }, error => {
        this.messageService.errorNotify(error.error.message, [error.error.errors]);
      });
  }

  updateCustomsCode(customsCode: string) {
    this.businessEntity.customsCode = customsCode;
    this.selectedBusinessEntity.emit(this.businessEntity);
  }
}
