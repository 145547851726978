import {Address, File, PreviousBoeDetails} from './file';
import {Importer} from './clearingFile';
import {BillOfEntryLine} from './billOfEntries';

export class ExBondFile extends File {
  importer?: Importer;
  warehouse?: Warehouse;
  lines?: ExBondLine[];
  marksAndNumbers?: string[];
  endorsements?: string[];
}

export class ExBondLine extends BillOfEntryLine {
}

export class Warehouse {
  code?: string;
  address?: Address;
  name?: string;
  customsCode?: string;
  taxRegNo?: string;
  label?: string;

  [k: string]: any;
}
