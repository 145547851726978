<div class="ui-g-12 ui-g-nopad">
  <div class="ui-g-12 ui-md-12">
    <div class="ui-g-6 ui-md-6">
      <digi-clearing-file-search *ngIf="fileType === 'imports'"></digi-clearing-file-search>
      <digi-export-file-search *ngIf="fileType === 'exports'"></digi-export-file-search>
    </div>
    <p-toolbar class="toolbar">
      <div class="ui-toolbar-group-right">
        <button *ngIf="invoice.confirmed" pButton type="button" label="Print"
                icon="fa fa-print"
                (click)="print()"></button>
        <button pButton type="button"
                [label]="showCreditNotePrintView?'Edit':'Print Preview'"
                [icon]="showCreditNotePrintView?'fa fa-pencil':'fa fa-print'"
                (click)="showCreditNotePrintView=!showCreditNotePrintView"></button>
        <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveCreditNote()"></button>
        <button pButton type="button" label="New" icon="fa fa-file"
                (click)="createNewCreditNote();showCreditNotePrintView=false"></button>
      </div>
    </p-toolbar>
  </div>
</div>
<div class="ui-g-12 ui-md-12">
  <p-table [columns]="creditNoteCols" [value]="creditNotes" selectionMode="single"
           columnResizeMode="expand"
           [(selection)]="creditNote" [paginator]="true" [rows]="5">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{col.header}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
      <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
        <td>{{selection.invoiceNo}}</td>
        <td>{{selection.creditNoteNo}}</td>
        <td>{{selection.invoiceDate}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<ng-container *ngIf="!showCreditNotePrintView">
  <div class="ui-g-4 ui-md-4">
    <p-panel header="Additional Credit Note Info">
      <div class="ui-g ui-fluid form-group">
        <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
                          <p-autoComplete [(ngModel)]="creditNote.reason"
                                          [dropdown]="true"
                                          field="label"
                                          [suggestions]="filteredReasons"
                                          (completeMethod)="searchReasons($event)"
                                          [autoHighlight]="true"
                                          (onSelect)="selectReason($event)"
                                          minLength="0">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                          <label>Credit Note Reason</label>
                        </span>
        </div>
        <div class="ui-g-12 ui-md-12">
            <textarea [(ngModel)]="creditNote.message" [rows]="5" [cols]="30" placeholder="Message"
                      autoResize="autoResize" pInputTextarea></textarea>
        </div>
        <div class="ui-g-12 ui-md-12">
          <p-checkbox [binary]="true" label="Is Copy?" [(ngModel)]="creditNote.copy"></p-checkbox>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-8 ui-md-8 line-details">
    <p-panel header="Invoice Details">
      <p-tree [style]="{'width':'100%'}" [value]="invoiceDetails" selectionMode="checkbox"
              [(selection)]="creditNoteDetails"
              (onNodeSelect)="updateCreditNoteLines()"
              (onNodeUnselect)="updateCreditNoteLines()"
      >
        <ng-template let-node pTemplate="default">
          <div class="ui-g">
            <div class="p-col">{{node.label}}</div>
            <div class="credit-note-amount local-amount">{{node.data && node.children ? calculateLocalAmountSumOfChildren(node) : ''}}</div>
            <div class="credit-note-amount local-tax">{{node.data && node.children ? calculateTaxSumOfChildren(node): ''}}</div>
          </div>
        </ng-template>
      </p-tree>
    </p-panel>
  </div>
  <div class="ui-g-12 ui-md-12">
    <p-table [value]="creditNote.lineDetails">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 15%">Charge Type</th>
          <th style="width:15%">Tran. Type</th>
          <th style="width:10%">Local Amount</th>
          <th style="width:10%">Local Tax</th>
          <th style="width:7%">Foreign Amount</th>
          <th style="width:7%">Foreign Tax</th>
          <th style="width:16%">Invoice Section</th>
          <th style="width:16%">Invoice Sub-section</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
        <tr #row>
          <td>
            {{ rowData.chargeType.description }}
          </td>
          <td>
            {{ rowData.tranType.description }}
          </td>
          <td>
            <input style="width: 100%;padding: 0" type="number" pInputText
                   [(ngModel)]="rowData.localAmount" (ngModelChange)="rowData.localTax=calculateLocalTax(rowData.taxType, rowData.localAmount)"
            />
          </td>
          <td>
            <input style="width: 100%;padding: 0" type="number" pInputText [(ngModel)]="rowData.localTax"/>
          </td>
          <td>
            <input style="width: 100%;padding: 0" type="number" pInputText
                   [(ngModel)]="rowData.foreignAmount"/>
          </td>
          <td>
            <input style="width: 50px;padding: 0" type="number" pInputText [(ngModel)]="rowData.foreignTax"/>
          </td>
          <td>
            {{ rowData.invoiceSection.description }}
          </td>
          <td>
            {{ rowData.invoiceSubSection }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>
<ng-container *ngIf="showCreditNotePrintView">
  <div class="ui-g-12 ui-g-nopad ui-md-12 border-thick print">
    <div class="ui-g-9 ui-md-9">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-12 ui-md-12">
          <span class="company-name">{{creditNote.companyName}}</span>
        </div>
        <div class="ui-g-3 ui-md-3 ui-fluid">
          <span>Vat No: {{creditNote.companyVatNo}}</span>
        </div>
        <div class="ui-g-3 ui-md-3 ui-fluid">
          <span>Reg: {{creditNote.companyReg}}</span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <span>Physical Address: {{creditNote.companyPhysicalAddress}}</span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <span>Postal Address: {{creditNote.companyPostalAddress}}</span>
        </div>
        <div class="ui-g-3 ui-md-3 ui-fluid">
          <span>Tel: {{creditNote.companyTel}}</span>
        </div>
        <div class="ui-g-3 ui-md-3 ui-fluid">
          <span>Fax: {{creditNote.companyFax}}</span>
        </div>
        <div class="ui-g-6 ui-md-6 ui-fluid">
          <span>Email: {{creditNote.companyEmail}}</span>
        </div>
      </div>
    </div>
    <div class="ui-g-3 ui-md-3">
      <div class="ui-g-12 ui-md-12 company-logo" [style.background-image]="logo"></div>
    </div>
    <hr class="ui-g-12 ui-md-12 ui-g-nopad border-thin">
    <div class="ui-g-5 ui-md-5">
      <div class="ui-g-8 ui-md-6">
        <span *ngIf="!creditNote.copy" class="document-title">Tax Credit Note</span>
        <span *ngIf="creditNote.copy" class="document-title">Copy Tax Credit Note</span>
      </div>
      <div *ngIf="file.debtor" class="ui-g-12 ui-md-12">
        To:
        <div class="ui-g-12 ui-md-12">
          {{file.debtor.name | uppercase}}
        </div>
        <div *ngIf="file.debtor.address && file.debtor.address.line1"
             class="ui-g-12 ui-md-12 no-padding-top-bottom">
          {{file.debtor.address.line1 | uppercase}}
        </div>
        <div *ngIf="file.debtor.address && file.debtor.address.line2"
             class="ui-g-12 ui-md-12 no-padding-top-bottom">
          {{file.debtor.address.line2 | uppercase}}
        </div>
        <div *ngIf="file.debtor.address && file.debtor.address.line3"
             class="ui-g-12 ui-md-12 no-padding-top-bottom">
          {{file.debtor.address.line3 | uppercase}}
        </div>
        <div *ngIf="file.debtor.address && file.debtor.address.line4"
             class="ui-g-12 ui-md-12 no-padding-top-bottom">
          {{file.debtor.address.line4 | uppercase}}
        </div>
        <div *ngIf="file.debtor.address && file.debtor.address.zipCode" class="ui-g-12 ui-md-12">
          {{file.debtor.address.zipCode}}
        </div>
      </div>
    </div>
    <div class="ui-g-7 ui-md-7">
      <div class="ui-g-6 ui-md-6">
        <div class="ui-g-12 ui-md-12 border-left border-right border-top border-bottom">
          <span>Date: {{creditNote.invoiceDate}}</span>
        </div>
        <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
          Client Account No.: {{ file.debtor.code }}
        </div>
        <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
          Client VAT No.: {{file.debtor.vatNo}}
        </div>
        <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
          Reference: {{ file.clearingFileNumber }}
        </div>
      </div>
      <div class="ui-g-6 ui-md-6">
        <div class="ui-g-12 ui-md-12 border-left border-right border-top border-bottom">
          <span>Page: 1</span>
        </div>
        <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
          <span>Client Reference: {{file.reference.referenceFileNo}}</span>
        </div>
        <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
          Invoice No.: {{ creditNote.creditNoteNo}}
        </div>
        <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
          <span>Alternate Reference: {{creditNote.altReference}}</span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-6 ui-md-6">
          THIS INVOICE IS PAYABLE ON OR BEFORE
        </div>
        <div class="ui-g-6 ui-md-6">
          <div class="ui-g-12 ui-md-12 border-thin">
            <span>{{creditNote.invoiceDueDate}}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="fileType!=='exbond'" class="ui-g-12 ui-md-12 no-padding-top-bottom">
      <div class="border-thin ui-g-12 ui-md-12">
        <div class="ui-g-4 ui-md-4">
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
              Master No : {{file.transportDocument.mawbNo}}
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
              House No : {{file.transportDocument.hawbNo}}
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom" *ngIf="fileType==='imports'">
              Supplier : {{file.transportDocument.supplierInvoices[0].supplier.name}}
            </div>
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom" *ngIf="fileType==='exports'">
              Consignee : {{file.transportDocument.supplierInvoices[0].supplier.name}}
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
              Container No : <span
              *ngIf="file.transportDocument.containers && file.transportDocument.containers[0]">{{file.transportDocument.containers[0].containerNo}}</span>
            </div>
          </div>
        </div>
        <div class="ui-g-4 ui-md-4">
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
              Port of Disch : <span
              *ngIf="file.transportDocument.destinationPort">{{file.transportDocument.destinationPort.name}}</span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div *ngIf="transportMethod==='AIR'" class="ui-g-12 ui-md-12 no-padding-top-bottom">
              Flight No : <span>{{file.transportDocument.flight}}</span>
            </div>
            <div *ngIf="transportMethod==='SEA'" class="ui-g-12 ui-md-12 no-padding-top-bottom">
              Voyage : <span>{{file.transportDocument.voyageDetails.voyageNo}}</span>
            </div>
          </div>
          <div *ngIf="transportMethod==='SEA'" class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
              Vessel Name :
              <span *ngIf="file.transportDocument.voyageDetails">
                      {{file.transportDocument.voyageDetails.customsRadioCallSign.vesselName}}</span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
              BOE No. : {{ selectedBillOfEntry.mrn }}
            </div>
          </div>
        </div>
        <div class="ui-g-4 ui-md-4">
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-8 ui-md-8 no-padding-top-bottom">
              Port of Load : {{file.transportDocument.departurePort.name}}
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-8 ui-md-8 no-padding-top-bottom">
              ETA/ETD : {{file.transportDocument.arrivalDate}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-g-12 ui-md-12 border-thick no-padding-top-bottom">
        <div class="ui-g-6 ui-md-6"></div>
        <div class="ui-g-2 ui-md-2">VAT</div>
        <div class="ui-g-2 ui-md-2">VAT code</div>
        <div class="ui-g-2 ui-md-2">Invoice Amount (Excl. VAT)</div>
      </div>
      <div *ngFor="let lineDetail of getCreditNoteLines()"
           class="ui-g-12 ui-md-12 border-bottom no-padding-top-bottom">
        <div class="ui-g-12 ui-md-12 no-padding-top-bottom"
             style="font-weight: bold">{{lineDetail.invoiceSection}}</div>
        <div *ngFor="let line of lineDetail.lines" class="ui-g-12 ui-md-12 no-padding-top-bottom">
          <div class="ui-g-3 ui-md-3 no-padding-top-bottom">
            <div *ngIf="line.invoiceSubSection" class="ui-g-10 ui-g-offset-2 no-padding-top-bottom">
              {{line.invoiceSubSection}}
            </div>
          </div>
          <div class="ui-g-3 ui-md-3"></div>
          <div class="ui-g-2 ui-md-2 no-padding-top-bottom">{{line.localTax === 0 ? getRounded(line.localTax) : getRounded(line.localTax)}}</div>
          <div class="ui-g-2 ui-md-2 no-padding-top-bottom">{{line.taxType.sarsTaxCode}}</div>
          <div class="ui-g-2 ui-md-2 no-padding-top-bottom">{{line.localAmount === 0 ? getRounded(line.localAmount) : getRounded(line.localAmount)}}</div>
        </div>
        <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
          <div class="ui-g-3 ui-md-3"></div>
          <div class="ui-g-3 ui-md-3 no-padding-top-bottom" style="font-weight: bold">
            Total {{lineDetail.invoiceSection}}
          </div>
          <div class="ui-g-2 ui-md-2" style="font-weight: bold">
            {{getCreditNoteTotal(lineDetail.lines, 'vat') === 0 ? getCreditNoteTotal(lineDetail.lines, 'vat') : getCreditNoteTotal(lineDetail.lines, 'vat')}}</div>
          <div class="ui-g-2 ui-md-2"></div>
          <div class="ui-g-2 ui-md-2"
               style="font-weight: bold">{{getCreditNoteTotal(lineDetail.lines, 'localAmount')}}</div>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
        <div class="ui-g-3 ui-md-3">
        </div>
        <div class="ui-g-3 ui-md-3">
        </div>
        <div class="ui-g-2 ui-md-2" style="font-weight: bold">Total (Excl. VAT)</div>
        <div class="ui-g-2 ui-md-2"></div>
        <div class="ui-g-2 ui-md-2" style="font-weight: bold">{{getCreditNoteTotal(null, 'totalExclVat')}}</div>
      </div>
      <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
        <div class="ui-g-6 ui-md-6">
        </div>
        <div class="ui-g-2 ui-md-2" style="font-weight: bold">Total VAT</div>
        <div class="ui-g-2 ui-md-2"></div>
        <div class="ui-g-2 ui-md-2" style="font-weight: bold">
          {{getCreditNoteTotal(null, 'totalVat') === 0 ? getCreditNoteTotal(null, 'totalVat'): getCreditNoteTotal(null, 'totalVat')}}</div>
      </div>
      <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
        <div class="ui-g-6 ui-md-6"></div>
        <div class="ui-g-6 ui-md-6 border-thin no-padding-top-bottom">
          <div class="ui-g-8 ui-md-8 " style="font-weight: bold">INVOICE TOTAL: ZAR</div>
          <div class="ui-g-4 ui-md-4" style="font-weight: bold">
            {{ creditNote.capturedAmount ? getRounded(creditNote.capturedAmount) : "" }}
          </div>
        </div>
      </div>
      <div class="ui-g-6 ui-md-6 ui-fluid">
        <span><u><b>Additional Information</b></u><br>This Credit Note refers to Invoice Number: {{creditNote.invoiceNo}}</span>
      </div>
      <div class="ui-md-12 ui-g-12">
        <div class="ui-g-12 ui-md-12 border-thick">
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="ui-g-12 ui-md-12"><strong>OUR BANKING DETAILS</strong></div>
            <div class="ui-g-4 ui-md-4" *ngFor="let detail of bankingDetails">
              <div class="ui-g-12 ui-md-12 ui-g-nopad">BANK: {{detail.bank}}</div>
              <div class="ui-g-12 ui-md-12 ui-g-nopad">BRANCH: {{detail.branchCode}}</div>
              <div class="ui-g-12 ui-md-12 ui-g-nopad">ACCOUNT NUMBER: {{detail.accountNo}}</div>
              <div class="ui-g-12 ui-md-12 ui-g-nopad">SWIFT: {{detail.swiftCode}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12"></div>
      <div class="ui-g-12 ui-md-12 ui-g-nopad border-thick">
        <div class="ui-g-3 ui-md-3"><b>TAX SUMMARY</b></div>
        <div class="ui-g-9 ui-md-9 ui-g-nopad">
          <div *ngFor="let taxType of getCreditNoteTaxSummary() | keyvalue: order" class="ui-g-3 ui-md-3">
            <b>{{taxType.key}}=</b> {{taxType.value === 0 ? getRounded(taxType.value) : getRounded(taxType.value)}}
          </div>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <b>All business is transacted in terms of our Standard Trading Terms (Copy available on request).
          Interest may charged at maximum permissible on all overdue amounts.</b>
      </div>
      <div class="ui-g-9 ui-md-9">
        <p-table [value]="[{}]" [rows]="2">
          <ng-template pTemplate="header">
            <tr>
              <th>Customs Value</th>
              <th>Gross Weight</th>
              <th>Volume</th>
              <th>No. of Pkgs</th>
              <th>No. of Containers</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body">
            <tr>
              <td>{{selectedBillOfEntry?.totalCustomsValue}}</td>
              <td>{{file.transportDocument?.measures.grossWeight}}
              <td>{{file.transportDocument?.measures.volume}}</td>
              <td>{{file.transportDocument?.measures.numberOfPackages}}</td>
              <td>{{file.transportDocument?.measures.noOfContainers}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="ui-g-3 ui-md-3">
        <div class="ui-g-12 ui-md-12">
          <span>Channel Controller: {{creditNote.channelController}}</span>
        </div>
        <div class="ui-g-12 ui-md-12">
          <span>Invoice Clerk: {{creditNote.invoiceClerk}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
