<div class="ui-g ui-g-nopad ui-fluid">

  <div *ngIf="disableButton" class="ui-g-12">
    <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
  </div>

  <div class="ui-g-1">
    <button pButton type="button" icon="fa fa-walking" pTooltip="Generate BOE"
            tooltipPosition="top"
            class="ui-button-secondary col-sm-12"
            (click)="generate()" icon="fa fa-play-circle" [disabled]="disableButton"></button>
    <br>
    <p-dialog header="Generated Documents" [(visible)]="display" [modal]="true"
              [responsive]="true" [width]="600"
              [minWidth]="200" [minY]="70"
              [maximizable]="true" [baseZIndex]="10000">
      <ng-template pTemplate="content" [ngIf]="generatedFiles">
        <p-accordion>
          <p-accordionTab class="white-toggle-icon" *ngFor="let data of sequences"
                          [header]="data.sequence?'Sequence '+data.sequence:'Customs Declaration Forms'">
            <li *ngFor="let generatedDocument of data.generatedFiles">
              <a class="uploaded-file-color"
                 [href]="generatedDocument._links.self.href" target="_blank">{{generatedDocument.fileName}}</a>
            </li>
          </p-accordionTab>
        </p-accordion>
      </ng-template>
      <p-footer>
        <button type="button" pButton icon="pi pi-close" (click)="display=false"
                label="Close"
                class="ui-button-secondary"></button>
      </p-footer>
    </p-dialog>
    <button type="button" (click)="loadGeneratedFiles()" pButton icon="fa fa-print" pTooltip="Generate Documents"
            tooltipPosition="top"
            class="ui-button-secondary col-sm-12" [disabled]="disableButton || file.quotation"></button>
    <br>
    <button pButton type="button" [disabled]="isEdiSent() || disableButton || exceedBalanceLimitDisable || file.quotation" icon="fa fa-paper-plane" pTooltip="Send Edi"
            tooltipPosition="top"
            class="ui-button-secondary col-sm-12"
            (click)="sendBillOfEntryToSARS()"></button>
    <br>
    <button pButton type="button" icon="fa fa-check" pTooltip="Mark as manual submission"
            tooltipPosition="top"
            class="ui-button-secondary col-sm-12"
            (click)="submitManually()" icon="fa fa-check" [disabled]="disableButton || selectedBillOfEntry.markedAsSubmitted || file.quotation"></button>
    <br>
    <p-dialog header="Confirm EDI" [(visible)]="displayManualSubmission" [modal]="true"
              [responsive]="true" [width]="600"
              [minWidth]="200" [minY]="70"
              [maximizable]="true" [baseZIndex]="10000">
      <div class="ui-g-12 ui-g-nopad">
        <p-toolbar>
          <div class="ui-toolbar-group-left">
            <div class="ui-g-12 ui-md-6">
              <digi-clearing-file-search></digi-clearing-file-search>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                      <input type="text" pInputText
                             value="{{file?.customOffice.label}}" readonly style="color: white;">
                      <label style="color: white;">Customs Office</label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-toolbar>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12 ui-md-12">
          <p>
            Please note that if you send this message, you will not be able to unlock this file
            until a reply has been received from Customs.
          </p>
          <p>
            Do you want to send the message?
          </p>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <p-table [columns]="cols" [value]="billsOfEntry.boes" [(selection)]="selectedBoes" dataKey="id">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 3em">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th *ngFor="let col of columns">
                {{col.header}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <td>
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </td>
              <td  class="ui-resizable-column">
                <label>{{rowData.houseNumber}}</label>
              </td>
              <td  class="ui-resizable-column">
                <label>{{rowData.cpc}}</label>
              </td>
              <td *ngIf="fileType==='imports'" class="ui-resizable-column">
                <label>{{rowData.destination.code}}</label>
              </td>
              <td  class="ui-resizable-column">
                <label>{{rowData.serialNumber}}</label>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="ui-g-12 ui-g-nopad align">
        <div class="ui-g-1 ui-md-1">
          <p-checkbox [(ngModel)]="agreed" binary="true"></p-checkbox>
        </div>
        <div class="ui-g-11 ui-md-11">
          <div>
            Entry checked prior to SARS submission.
          </div>
          <div>
            I hereby declare that the particulars herein are true and correct and comply with the latest official published
            provisions of the Customs and Excise Act, the Schedules thereto and the duly authorised Clearing Instruction.
          </div>
        </div>
      </div>
      <p-footer>
        <button type="button" pButton icon="pi pi-close" (click)="markBoeAsSubmitted()"
                label="Confirm"
                class="ui-button-secondary" [disabled]="!agreed"></button>
        <button type="button" pButton icon="pi pi-close" (click)="displayManualSubmission=false"
                label="Cancel"
                class="ui-button-secondary"></button>
        <button type="button" pButton icon="pi pi-close" (click)="deselectBoes()"
                label="Deselect All"
                class="ui-button-secondary"></button>
      </p-footer>
    </p-dialog>
  </div>
  <div class="ui-g-11" *ngIf="!isCorrection()">
    <digi-bill-of-entry-table [billsOfEntry]="billsOfEntry"
                              [fileType]="fileType"
                              (selectedBillOfEntryHeader)="selectedBillOfEntryHeader($event)"
                              [selectedBillOfEntry]="selectedBillOfEntry"></digi-bill-of-entry-table>
    <div class="ui-g-6">
      <p-panel header="Bill Of Entry">
        <div class="ui-g ui-fluid">

          <div class="ui-g-6">
            <digi-boe-payment-details [fileType]="fileType"></digi-boe-payment-details>
          </div>
          <div class="ui-g-6">
            <digi-boe-package-section [file]="file"></digi-boe-package-section>
            <digi-boe-bank-details></digi-boe-bank-details>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g-6 ui-md-6">
            <span class="md-inputfield">
              <span class="ui-float-label">
                <span class="md-inputfield">
                  <p-inputMask id="receiptDate" mask="99/99/9999"
                               slotChar="dd/mm/yyyy"
                               [(ngModel)]="selectedBillOfEntry.receiptDate">

                  </p-inputMask>
                     <label for="receiptDate">Receipt Date</label>
                </span>
              </span>
            </span>
            </div>
            <div class="ui-g-6 ui-md-6">
            <span class="md-inputfield">
              <input type="text" pInputText
                     [(ngModel)]="selectedBillOfEntry.receiptReference">
              <label>Receipt Reference</label>
            </span>
            </div>
          </div>
          <div class="ui-g-12 ui-md-6" *ngIf="hasRemovalTransportMethod() && billsOfEntry.boes[0]">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
            <span class="md-inputfield">
              <input type="text" pInputText
                     [(ngModel)]="billsOfEntry.boes[0].bondNo"
                     readonly>
              <label> Bond Number</label>
            </span>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>
    <digi-boe-permits-section [fileType]="fileType"></digi-boe-permits-section>
    <app-boe-total-schedule-amount *ngIf="fileType==='imports'"></app-boe-total-schedule-amount>
  </div>
  <div class="ui-g-11" *ngIf="isCorrection()">
    <digi-bill-of-entry-table [billsOfEntry]="billsOfEntry"
                              [fileType]="fileType"
                              (selectedBillOfEntryHeader)="selectedBillOfEntryHeader($event)"
                              [selectedBillOfEntry]="selectedBillOfEntry"></digi-bill-of-entry-table>
    <digi-before-and-after-payment-details [fileType]="fileType">
    </digi-before-and-after-payment-details>
  </div>
</div>
<p-confirmDialog header="Confirmation"
                 icon="pi pi-exclamation-triangle"
                 width="425"
                 [rejectLabel]="'No'"
                 [acceptLabel]="'Yes'"
></p-confirmDialog>
