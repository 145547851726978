<p-dialog
          [(visible)]="display"
          (onHide)="onClose()"
          [modal]="true"
          [responsive]="true"
          [width]="800"
          [minWidth]="400"
          [minY]="100"
          [maximizable]="false">
  <div class="ui-g">
      <div class="ui-g-12">
        <div class="ui-g ui-fluid">
          <p-panel header="Tracking Details" class="ui-g-12">
            <div class="ui-g form-group">

        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                               [(ngModel)]="trackingDetails.submissionDate"
                                               id="submissionDate"
                                               mask="99/99/9999 99:99"
                                               slotChar="dd/mm/yyyy HH:mm"
                                               disabled
                                               >
                                  </p-inputMask>
                                  <label
                                    for="submissionDate">Submission To Customs</label>
                                </span>
                              </span>
                            </span>
        </div>

        <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                               [(ngModel)]="trackingDetails.releaseDate"
                                               id="releaseDate"
                                               mask="99/99/9999 99:99"
                                               slotChar="dd/mm/yyyy HH:mm"
                                               disabled
                                  >
                                  </p-inputMask>
                                  <label
                                    for="releaseDate">Released By Customs</label>
                                </span>
                              </span>
                            </span>
        </div>

        <div class="ui-g-12 ui-md-12">
          <div [ngClass]="isHouseBill == true ? 'ui-g-12 ui-md-12': 'ui-g-8 ui-md-8'">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                               [(ngModel)]="trackingDetails.receivedAtORTIA"
                                               id="receivedAtORTIA"
                                               mask="99/99/9999 99:99"
                                               slotChar="dd/mm/yyyy HH:mm"
                                  >
                                  </p-inputMask>
                                  <label
                                    for="receivedAtORTIA">Received At Airport</label>
                                </span>
                              </span>
                            </span>
          </div>
          <div *ngIf="!isHouseBill" class="ui-g-4 ui-md-4">
            <button pButton type="button" label="Update House Bills" icon="fa fa-refresh"
                    (click)="onUpdateHAWBSArrivedATORTIA()"></button>
          </div>
        </div>



              <div class="ui-g-12 ui-md-12">
                <div [ngClass]="isHouseBill == true ? 'ui-g-12 ui-md-12': 'ui-g-8 ui-md-8'">
                  <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                    [(ngModel)]="trackingDetails.receivedAtJNBOPS"
                                    id="receivedAtJNBOPS"
                                    mask="99/99/9999 99:99"
                                    slotChar="dd/mm/yyyy HH:mm"
                                  >
                                  </p-inputMask>
                                  <label
                                    for="receivedAtJNBOPS">Received At JNB OPS</label>
                                </span>
                              </span>
                            </span>
                </div>
                <div *ngIf="!isHouseBill" class="ui-g-4 ui-md-4">
                  <button pButton type="button" label="Update House Bills" icon="fa fa-refresh"
                          (click)="onUpdateHAWBsArrivedAtJNBOPSFields()"></button>
                </div>
              </div>

              <div *ngIf="!isHouseBill" class="ui-g-12 ui-md-12" >
                <div [ngClass]="isHouseBill == true ? 'ui-g-12 ui-md-12': 'ui-g-8 ui-md-8'">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                         [(ngModel)]="currentStatus"
                                         id="status"
                                         disabled
                                  >
                                  <label for="status">Current Status</label>
                                </span>
                              </span>
                            </span>
                </div>
                <div class="ui-g-4 ui-md-4">
                  <button pButton type="button" label="Submit To API" icon="fa fa-update"
                          [disabled]="onSendParcelUpdateDisabled()"
                          (click)="onSendParcelUpdate()"
                  ></button>
                </div>
              </div>

              <ng-container *ngIf="isHouseBill">

                <div class="ui-g-12 ui-md-12">
                  <div [ngClass]="isHouseBill == true ? 'ui-g-12 ui-md-12': 'ui-g-8 ui-md-8'">
                  <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <p-inputMask
                                    [(ngModel)]="trackingDetails.podDate"
                                    id="podDate"
                                    mask="99/99/9999 99:99"
                                    slotChar="dd/mm/yyyy HH:mm"
                                  >
                                  </p-inputMask>
                                  <label
                                    for="podDate">Proof Of Delivery (POD)</label>
                                </span>
                              </span>
                            </span>
                  </div>
                </div>

              <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <textarea [rows]="3" pInputTextarea autoResize="autoResize"
                                    [(ngModel)]="trackingDetails.comment"
                                    id="comment"
                                  >
                                  </textarea>
                                  <label
                                    for="comment">Comment / Exception</label>
                                </span>
                              </span>
                            </span>
              </div>

              <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <span class="ui-float-label">
                                <span class="md-inputfield">
                                  <input type="text" pInputText
                                    [(ngModel)]="trackingDetails.hawbAndDetails"
                                    id="hawbAndDetails"
                                  >
                                  <label
                                    for="hawbAndDetails">HAWB & Details (If Not Manifested)</label>
                                </span>
                              </span>
                            </span>
              </div>

                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-12">
                    <span>Status Comments</span>
                  </div>
                  <div class="ui-g-3 ui-md-3">
                    <p-checkbox binary="true"
                                [disabled]="isPoBoxChecked"
                                [(ngModel)]="isPoBoxChecked"
                                name="poBoxCheck"
                                label="PO Box"
                                (onChange)="setStatusCommentTime($event, 'statusPoBoxTimestamp')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusPoBoxTimestamp" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusPoBoxTimestamp" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusPoBoxTimestamp}}</span>
                    </div>
                  </div>
                  <div class="ui-g-3 ui-md-3">
                    <p-checkbox binary="true"
                                [disabled]="isImporterCodeChecked"
                                [(ngModel)]="isImporterCodeChecked"
                                name="importerCodeCheck"
                                label="Importer Code"
                                (onChange)="setStatusCommentTime($event, 'statusImporterCodeTimestamp')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusImporterCodeTimestamp" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusImporterCodeTimestamp" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusImporterCodeTimestamp}}</span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-3 ui-md-3">
                    <p-checkbox binary="true"
                                [disabled]="isDutyConfChecked"
                                [(ngModel)]="isDutyConfChecked"
                                name="dutyConfCheck"
                                label="Duty Confirmation"
                                (onChange)="setStatusCommentTime($event, 'statusDutyConfTimestamp')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusDutyConfTimestamp" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusDutyConfTimestamp" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusDutyConfTimestamp}}</span>
                    </div>
                  </div>
                  <div class="ui-g-3 ui-md-3">
                    <p-checkbox binary="true"
                                [disabled]="isCustomsStopChecked"
                                [(ngModel)]="isCustomsStopChecked"
                                name="customsStopCheck"
                                label="Customs Stop"
                                (onChange)="setStatusCommentTime($event, 'statusCustomsStopTimestamp')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusCustomsStopTimestamp" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusCustomsStopTimestamp" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusCustomsStopTimestamp}}</span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-3 ui-md-3">
                    <p-checkbox binary="true"
                                [disabled]="isOagStopChecked"
                                [(ngModel)]="isOagStopChecked"
                                name="oagStopCheck"
                                label="OAG Stop"
                                (onChange)="setStatusCommentTime($event, 'statusOagStopTimestamp')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusOagStopTimestamp" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusOagStopTimestamp" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusOagStopTimestamp}}</span>
                    </div>
                  </div>

                  <div class="ui-g-3 ui-md-3">
                    <p-checkbox binary="true"
                                [disabled]="isMissingDocsChecked"
                                [(ngModel)]="isMissingDocsChecked"
                                name="missingDocsCheck"
                                label="Missing Docs"
                                (onChange)="setStatusCommentTime($event, 'statusMissingDocsTimestamp')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusMissingDocsTimestamp" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusMissingDocsTimestamp" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusMissingDocsTimestamp}}</span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-3 ui-md-3">
                    <p-checkbox binary="true"
                                [disabled]="isGeneralQueryChecked"
                                [(ngModel)]="isGeneralQueryChecked"
                                name="generalQueryCheck"
                                label="General Query"
                                (onChange)="setStatusCommentTime($event, 'statusGeneralQueryTimestamp')"></p-checkbox>
                  </div>
                  <div *ngIf="!trackingDetails.statusGeneralQueryTimestamp" class="ui-g-3">
                  </div>
                  <div *ngIf="trackingDetails.statusGeneralQueryTimestamp" class="ui-g-3">
                    <div class="ui-inputgroup">
                      <span class="ui-inputgroup-addon">{{trackingDetails.statusGeneralQueryTimestamp}}</span>
                    </div>
                  </div>
                  <div *ngIf="trackingDetails.statusGeneralQueryTimestamp" class="ui-g-6">
                  <span class="md-inputfield">
                    <span class="ui-float-label">
                      <span class="md-inputfield">
                        <textarea [rows]="3" pInputTextarea autoResize="autoResize"
                                  [(ngModel)]="trackingDetails.generalQuery"
                                  id="general-query-label">
                        </textarea>
                        <label
                          for="general-query-label">General Query</label>
                      </span>
                    </span>
                  </span>
                  </div>
                </div>

              </ng-container>
            </div>
          </p-panel>
          <div *ngIf="displayTrackingProgressBar" class="ui-g-12">
            <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
          </div>
      </div>
      </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="fa fa-close" (click)="onClose();"
            label="Cancel"></button>
    <button type="button" pButton icon="fa fa-check" (click)="onSave();"
            label="Save"></button>
  </p-footer>
</p-dialog>
