import {Component, Input, OnInit} from '@angular/core';
import {ExBondFile, ExBondLine} from '../../digi-common/models/exBondFile';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {Subscription} from 'rxjs';
import {BillOfEntry} from '../../digi-common/models/billOfEntries';
import {BillOfEntryService} from '../../digi-common/services/bill-of-entry.service';
import {PreviousBoeDetails, SupplierInvoiceLine, TariffCode, TariffFormula, TradeAgreement, CustomOffice} from '../../digi-common/models/file';
import {PackTypeService} from '../../digi-common/services/pack-type.service';
import {PackType} from '../../digi-common/models/roadManifest';
import {TariffService} from '../../digi-common/services/tariff.service';
import {Formula} from '../../digi-common/models/TariffCode';
import {TariffCodeFormulaeService} from '../../digi-common/services/tariffcode-formulae.service';

@Component({
  selector: 'digi-exbond-lines',
  templateUrl: './exbond-lines.component.html',
  styleUrls: ['./exbond-lines.component.scss']
})
export class ExbondLinesComponent implements OnInit {
  cols: any[];
  exBondFile: ExBondFile;
  selectedLine: ExBondLine;
  private exBondFileSubscription: Subscription;
  showDialog: boolean;
  billOfEntry = new BillOfEntry();
  lines: ExBondLine[];
  selectedLines: ExBondLine[];
  boeLinesCols: any[];
  filteredTradeAgreements: TradeAgreement[];
  filteredPackTypes: PackType[];
  tariffCode = new TariffCode();
  filteredTariffCodes: any[];
  additionalScheduleTariffs: TariffCode[];
  uomCols: any[];
  mrnFilter: string;
  lineFilter: string;

  constructor(
    private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
    private billOfEntryService: BillOfEntryService,
    private packTypeService: PackTypeService,
    private tariffService: TariffService,
    private tariffCodeFormulaeService: TariffCodeFormulaeService,
  ) {
  }

  ngOnInit() {
    this.uomCols = [
      {
        header: 'UOM Code',
      },
      {
        'header': 'value'
      }
    ];
    this.cols = [
      {
        header: 'Line No'
      },
      {
        header: 'Part Number'
      },
      {
        header: 'Line Value'
      },
      {
        header: 'Quantity'
      }
    ];
    this.boeLinesCols = [
      {
        header: 'Line No',
        width: '5%'
      },
      {
        header: 'MRN',
        width: '15%'
      },
      {
        header: 'CPC',
        width: '5%'
      },
      {
        header: 'Seq. No.',
        width: '4%'
      },
      {
        header: 'Customs Date',
        width: '10%'
      },
      {
        header: 'Part Number',
        width: '10%'
      },
      {
        header: 'Available Packs',
        width: '8%'
      },
      {
        header: 'Selected Packs',
        width: '8%'
      },
      {
        header: 'Pack Type',
        width: '10%'
      },
      {
        header: 'Value',
        width: '10%'
      },
      {
        header: 'Qty',
        width: '10%'
      }
    ];
    this.exBondFileSubscription = this.exBondFileBehaviourSubject.getExBondFile().subscribe(
      ef => {
        if (ef) {
          this.exBondFile = ef;
          if (this.exBondFile.lines && this.exBondFile.lines.length) {
            this.selectLine(this.exBondFile.lines[0]);
          }
        }
      }
    );
  }

  createNewFile() {
    this.showDialog = true;
    this.search();
  }

  selectLine(value: any) {
    this.selectedLine = value;
    // @ts-ignore
    this.additionalScheduleTariffs = this.selectedLine.additionalScheduleTariffs;
  }

  onRowDelete(rowIndex: number) {
    this.exBondFile.lines.splice(rowIndex, 1);
    this.exBondFile.lines = [...this.exBondFile.lines];
  }

  onClose() {
    this.showDialog = false;
  }

  search() {
    this.deselectBoes();
    this.lines = [];
    // Subscribe to either 40 or 42
    [40, 42].forEach(code => {
      this.billOfEntryService.findByCustomsProcedureCodeAndMrn(code, this.mrnFilter).subscribe(
        boes => {
          boes.forEach(boe => {
              let latestSequence = 0;
              boes.forEach(b => {
                if (b.mrn === boe.mrn && b.sequenceNumber > latestSequence){
                  latestSequence = b.sequenceNumber;
                }
              });
              if (boe.sequenceNumber === latestSequence) {
                const lines = boe.lines.filter(l => !this.lineFilter || l.lineNo === parseInt(this.lineFilter)).map(line => Object.assign({}, line));
                lines.forEach(line => {
                  const previousBoEDetails = new PreviousBoeDetails();
                  let noOfPacksAtWarehouse;
                  if (line.warehouseCaptureDetails) {
                    noOfPacksAtWarehouse = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
                  }
                  previousBoEDetails.cpc = boe.customsPurposeCode;
                  previousBoEDetails.sequenceNo = boe.sequenceNumber;
                  previousBoEDetails.customsBOENo = boe.mrn;
                  previousBoEDetails.date = boe.releaseDate;
                  previousBoEDetails.customOffice = new CustomOffice();
                  previousBoEDetails.customOffice.districtOfficeCode = boe.districtOfficeCode;
                  previousBoEDetails.lineNo = line.lineNo;
                  previousBoEDetails.customsValue = line.customsValue;
                  previousBoEDetails.customsDuty = line.customsDuty;
                  previousBoEDetails.customsVAT = line.customsVAT;
                  previousBoEDetails.localCustomsValue = line.localCustomsValue;
                  previousBoEDetails.invoiceGrossValue = line.invoiceGrossValue;
                  previousBoEDetails.localInvoiceGrossValue = line.localInvoiceGrossValue;
                  previousBoEDetails.actualValue = line.actualValue;
                  previousBoEDetails.noOfPacks = line.noOfPacks;
                  if (line.warehouseCaptureDetails) {
                    if (noOfPacksAtWarehouse > 0) {
                      line.noOfPacks = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
                      previousBoEDetails.remainingPacks = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
                    } else {
                      line.noOfPacks = 0;
                      previousBoEDetails.remainingPacks = 0;
                    }
                  } else {
                    previousBoEDetails.remainingPacks = line.noOfPacks;
                  }
                  previousBoEDetails.lineQuantity = line.lineQuantity;
                  previousBoEDetails.lineWeight = line.lineWeight;
                  previousBoEDetails.additionalScheduleTariffs = line.additionalScheduleTariffs;
                  previousBoEDetails.sequenceNumber = boe.sequenceNumber;
                  line.previousBoeDetails = Object.assign({}, previousBoEDetails);
                  line.type = 'ExBondLine';
                  this.recalculateValues(line);
                });
                this.lines.push(...lines);
                this.lines = [...this.lines];
              }
            }
          );
        }
      );
    });
  }


  deselectBoes() {
    this.selectedLines = [];
  }

  createExbondLines() {
    if (this.exBondFile.lines) {
      this.exBondFile.lines.push(...this.selectedLines);
    } else {
      this.exBondFile.lines = [...this.selectedLines];
    }
    this.exBondFile.lines.forEach((value: any, index: number) => {
      value.lineNo = index + 1;
    });
    if (this.exBondFile.lines.length) {
      this.selectLine(this.exBondFile.lines[0]);
    }
    this.showDialog = false;
  }

  selectCountry(value) {
    this.selectedLine.countryOfOrigin = value;
  }

  searchTradeAgreement(event) {
    this.filteredTradeAgreements = [];
    this.selectedLine.countryOfOrigin.tradeAgreements.filter(v => v.code.toLowerCase().indexOf(event) > -1).slice(0, 10);
    this.filteredTradeAgreements = [...this.selectedLine.countryOfOrigin.tradeAgreements];
  }

  selectTradeAgreement(value) {
    this.selectedLine.tradeAgreement.code = value.code;
    this.selectedLine.tradeAgreement.description = value.description;
  }

  searchPackTypes(event) {
    this.packTypeService.findByCodeOrDescription(event.query).subscribe(
      data => this.filteredPackTypes = data
    );
  }

  selectPackType(value) {
    this.selectedLine.packType = value;
  }

  searchTariffCode(event: any) {
    this.tariffService
      .findTariffByCodeStartsWith(event.query)
      .subscribe(data => {
        this.filteredTariffCodes = data;
      });
  }

  selectTariffCode(value: any) {
    this.selectedLine.partNumber = value.code;
    this.selectedLine.description = value.tariffSDesc;
    this.selectedLine.fullDescription = value.fullDescription;
    this.selectedLine.tariffCode.tariffID = value.tariffID;
    this.tariffCode = new TariffCode();
    this.tariffCode.code = value.code;

    const formula = value.formulae.find(f => {
      return !!f.uomCode;
    });

    if (formula) {
      this.selectedLine.tariffCode.formula = new Formula();
      this.selectedLine.tariffCode.formula.statisticalUOM = formula.uomCode;
    }

    this.updatedTariff(value);
  }

  updatedTariff(value: TariffCode) {
    if (value) {
      const effectiveFormulae = value.effectiveTradeAgreementFormulae;
      this.tariffCodeFormulaeService.effectiveTradeAgreementFormulae(
        effectiveFormulae,
        this.selectedLine
      );
      const effectiveParameters = value.effectiveTradeAgreementParameters;
      this.tariffCodeFormulaeService.effectiveTradeAgreementParameters(
        effectiveParameters,
        this.selectedLine
      );

      this.selectedLine.tariffCode.description = value.tariffSDesc;
      this.selectedLine.tariffCode.checkDigit = value.tariffCheckDigit;
      this.selectedLine.tariffCode.code = value.code;
      this.selectedLine.tariffCode.fullDescription = value.fullDescription;

      if (value.additionalUoms && value.additionalUoms.length > 0) {
        value.additionalUoms.forEach(uom => {
          if (this.selectedLine.additionalUoms) {
            const foundUom = this.selectedLine.additionalUoms.find(el => el.uomCode === uom.uomCode);
            if (!foundUom) {
              this.selectedLine.additionalUoms.push({uomCode: uom.uomCode});
            }
          } else {
            this.selectedLine.additionalUoms = [];
            this.selectedLine.additionalUoms.push({uomCode: uom.uomCode});
          }
        });
      } else {
        this.selectedLine.additionalUoms = [];
      }

      if (!this.selectedLine.fullDescription) {
        this.selectedLine.fullDescription = value.fullDescription;
      }
    }
  }

  recalculateValues(rowData: ExBondLine) {
    let ratio = 1;
    if (rowData.noOfPacks !== undefined) {
      ratio = rowData.noOfPacks / rowData.previousBoeDetails.noOfPacks;
    }
    rowData.customsValue = Math.round((rowData.previousBoeDetails.customsValue * ratio) * 100) / 100;
    rowData.localCustomsValue = Math.round(rowData.previousBoeDetails.localCustomsValue * ratio);
    rowData.actualValue = Math.round((rowData.previousBoeDetails.actualValue * ratio) * 100) / 100;
    rowData.lineQuantity = Math.round((rowData.previousBoeDetails.lineQuantity * ratio) * 100) / 100;
    rowData.lineWeight = Math.round((rowData.previousBoeDetails.lineWeight * ratio) * 100) / 100;
    rowData.localInvoiceGrossValue = Math.round((rowData.previousBoeDetails.localInvoiceGrossValue * ratio) * 100) / 100;
    rowData.invoiceGrossValue = Math.round((rowData.previousBoeDetails.invoiceGrossValue * ratio) * 100) / 100;

    if (rowData.previousBoeDetails) {
      const schedules = [];
      rowData.previousBoeDetails.additionalScheduleTariffs.forEach(schedule => {
        const newSchedule = Object.assign({}, schedule);
        newSchedule.calculatedValue = Math.round((schedule.calculatedValue * ratio) * 100) / 100;
        schedules.push(newSchedule);
      });
      rowData.additionalScheduleTariffs = schedules;
    }
  }

  updateAdditionalSchedules(schedules: TariffCode[]) {
    // @ts-ignore
    this.selectedLine.additionalScheduleTariffs = schedules;
  }
}
