import {BusinessEntity, NominatedBusinessEntity} from './../models/business-entity';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) {
  }

  saveBusinessEntity(businessEntity: BusinessEntity): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(businessEntity);
    if (businessEntity._links && businessEntity._links.self) {
      console.log('Inside put side');
      console.log(businessEntity);

      return this.http.put(businessEntity._links.self.href.replace(businessEntity._links.self.href, this.config.filesApiEndpoint + `api/businessEntities/` + businessEntity.id), JSON.stringify(businessEntity), {headers});

    } else {
      console.log('Inside post side');
      return this.http.post(this.config.filesApiEndpoint + 'api/businessEntities', JSON.stringify(businessEntity), {headers});
    }
  }

  findBusinessEntityStartsWith(name) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/findByNameOrCode?name=${name}`)
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findBusinessEntityStartsWithAndRoleType(name: string, roleTypeId: number) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/findByNameOrCodeAndRoleType?name=${name}&id=${roleTypeId}`)
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  findBusinessEntityImporterCode(customsCode: string) {
      return this.http
        .get(`${this.config.filesApiEndpoint}api/businessEntities/search/findByRoleTypeAndCustomsCode?customsCode=${customsCode}&id=6`)
        .pipe(
          map(response => response['_embedded'].businessEntities)
        );
    }

  findBusinessEntityCreditorDocumentNumber(documentNumber: string) {
     return this.http
       .get(`${this.config.filesApiEndpoint}api/businessEntities/search/findByRoleTypeAndCreditorDocumentNumber?documentNumber=${documentNumber}&id=11`)
       .pipe(
         map(response => response['_embedded'].businessEntities)
       );
   }

  uploadEntitiesFromSpreadsheet(file: any) {
      const formData = new FormData();
      formData.append('file', file);

      return this.http.put(this.config.filesApiEndpoint + `api/businessEntities/consigneesUpload`, formData);
    }

  saveBusinessEntityWithRole(code: string, roleId: number, roleNominationId: number , nominatedBusinessEntity: NominatedBusinessEntity): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put( this.config.filesApiEndpoint + `api/businessEntities/${code}/roles/${roleId}/roleNominations/${roleNominationId}` , JSON.stringify(nominatedBusinessEntity), {headers});
  }

}
