import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ManifestWaybill} from '../../models/roadManifest';
import {BillOfEntryMarksAndNumbers} from '../../models/billOfEntries';

@Component({
  selector: 'digi-manifest-marks-and-numbers',
  templateUrl: './marks-and-numbers.component.html',
  styleUrls: ['./marks-and-numbers.component.scss']
})
export class MarksAndNumbersComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() marksAndNumbers = [];
  @Input() displayEndorsements = true;
  @Output() updateMarksAndNumbers = new EventEmitter();
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.addMarksAndNumber();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.marksAndNumbers) {
      const marksAndNumbers = new BillOfEntryMarksAndNumbers();
      marksAndNumbers.marksAndNumbers = [];
      marksAndNumbers.endorsements = [];
      this.marksAndNumbers = [marksAndNumbers];
    }
    if (changes.marksAndNumbers && changes.marksAndNumbers.currentValue) {
      this.marksAndNumbers = changes.marksAndNumbers.currentValue;
      this.addMarksAndNumber();
    }
    if (changes.displayEndorsements && changes.displayEndorsements.currentValue) {
      this.displayEndorsements = changes.displayEndorsements.currentValue;
    }
  }

  initMarksAndNumbers() {
    return this.fb.group({
      markOrNumber: ['']
    });
  }

  initEndorsements() {
    return this.fb.group({
      endorsement: ['']
    });
  }

  addMarkOrNumber() {
    const control = <FormArray>this.form.get('marksAndNumbers');
    control.push(this.initMarksAndNumbers());
  }

  addEndorsement() {
    const control = <FormArray>this.form.get('endorsements');
    control.push(this.initEndorsements());
  }

  removeMarkOrNumber(i) {
    const control = <FormArray>this.form.get('marksAndNumbers');
    control.removeAt(i);
    this.marksAndNumbers[0].marksAndNumbers.splice(i, 1);
  }

  removeEndorsement(j) {
    const control = <FormArray>this.form.get('endorsements');
    control.removeAt(j);
    this.marksAndNumbers[0].endorsements.splice(j, 1);
  }

  getMarksAndNumbers(form) {
    return form.controls.marksAndNumbers.controls;
  }

  getEndorsements(form) {
    return form.controls.endorsements.controls;
  }

  pushMarksAndNumbers(i) {
    const control = <FormArray>this.form.get('marksAndNumbers');
    const rawArray = control.getRawValue();
    if (this.marksAndNumbers && this.marksAndNumbers[0]) {
      this.marksAndNumbers[0].marksAndNumbers[i] = rawArray[i].markOrNumber;
    }
  }

  pushEndorsements(i) {
    const control = <FormArray>this.form.get('endorsements');
    const rawArray = control.getRawValue();
    this.marksAndNumbers[0].endorsements[i] = rawArray[i].endorsement;
  }

  private addMarksAndNumber() {
    this.form = this.fb.group({
      marksAndNumbers: this.fb.array([
        this.initMarksAndNumbers(),
      ]),
      endorsements: this.fb.array([
        this.initEndorsements(),
      ]),
    });

    if (this.marksAndNumbers && this.marksAndNumbers.length > 0) {
      const marksAndNumbersArray = <FormArray>this.form.get('marksAndNumbers');
      const endorsementsArray = <FormArray>this.form.get('endorsements');
      let count = 0;
      this.marksAndNumbers[0].marksAndNumbers.forEach(element => {
        this.addMarkOrNumber();
        marksAndNumbersArray.controls[count].get('markOrNumber').setValue(element);
        count++;
      });
      count = 0;
      this.marksAndNumbers[0].endorsements.forEach(element => {
        this.addEndorsement();
        endorsementsArray.controls[count].get('endorsement').setValue(element);
        count++;
      });
    }
    this.updateMarksAndNumbers.emit(this.marksAndNumbers);
  }
}
