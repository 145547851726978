import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { Address, ClearingInstruction, Guarantor } from '../models/file';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var GuarantorService = /** @class */ (function () {
    function GuarantorService(http, config) {
        this.http = http;
        this.config = config;
    }
    GuarantorService.prototype.findGuarantorNameOrCodeStartsWith = function (code) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=" + code + "&roleType=30&projection=flattenedGuarantor")
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    GuarantorService.prototype.returnDefault = function (clearingInstruction) {
        var guarantor = new Guarantor();
        guarantor.address = new Address();
        if (clearingInstruction) {
            clearingInstruction.guarantor = guarantor;
        }
        return of([guarantor]);
    };
    GuarantorService.prototype.findGuarantorFromClearingFile = function (clearingInstruction, guarantors, updateGuarantor) {
        if (updateGuarantor === void 0) { updateGuarantor = true; }
        if (clearingInstruction) {
            return this.findGuarantorNameOrCodeStartsWith(guarantors.code).pipe(tap(function (guarantorData) {
                if (updateGuarantor) {
                    clearingInstruction.guarantor = new Guarantor();
                    clearingInstruction.guarantor.name = guarantors.name;
                    clearingInstruction.guarantor.code = guarantors.code;
                    clearingInstruction.guarantor.label = guarantors.label;
                    // clearingInstruction.guarantor.guaranteeType = guarantorData[0].guaranteeType;
                }
                if (guarantorData && (!clearingInstruction.guarantor.address
                    || !clearingInstruction.guarantor.address.line1)) {
                    var guarantorAddresses = guarantorData[0].addresses;
                    var guarantorAddress = void 0;
                    if (guarantorAddresses.length === 1) {
                        guarantorAddress = guarantorAddresses[0];
                    }
                    else {
                        guarantorAddress = guarantorAddresses.find(function (address) {
                            var addressType = address.addressTypes.find(function (addressType) {
                                return addressType.id === 'CUS';
                            });
                            if (addressType) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });
                    }
                    if (guarantorAddress) {
                        clearingInstruction.guarantor.address = new Address();
                        clearingInstruction.guarantor.address.line1 = guarantorAddress.line1;
                        clearingInstruction.guarantor.address.line2 = guarantorAddress.line2;
                        clearingInstruction.guarantor.address.line3 = guarantorAddress.line3;
                        clearingInstruction.guarantor.address.line4 = guarantorAddress.line4;
                        clearingInstruction.guarantor.address.zipCode = guarantorAddress.zipCode;
                    }
                }
            }));
        }
        else {
            return this.returnDefault(clearingInstruction);
        }
    };
    GuarantorService.ngInjectableDef = i0.defineInjectable({ factory: function GuarantorService_Factory() { return new GuarantorService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: GuarantorService, providedIn: "root" });
    return GuarantorService;
}());
export { GuarantorService };
