import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var BusinessEntityService = /** @class */ (function () {
    function BusinessEntityService(http, config) {
        this.http = http;
        this.config = config;
    }
    BusinessEntityService.prototype.saveBusinessEntity = function (businessEntity) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        console.log(businessEntity);
        if (businessEntity._links && businessEntity._links.self) {
            console.log('Inside put side');
            console.log(businessEntity);
            return this.http.put(businessEntity._links.self.href.replace(businessEntity._links.self.href, this.config.filesApiEndpoint + "api/businessEntities/" + businessEntity.id), JSON.stringify(businessEntity), { headers: headers });
        }
        else {
            console.log('Inside post side');
            return this.http.post(this.config.filesApiEndpoint + 'api/businessEntities', JSON.stringify(businessEntity), { headers: headers });
        }
    };
    BusinessEntityService.prototype.findBusinessEntityStartsWith = function (name) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/findByNameOrCode?name=" + name)
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    BusinessEntityService.prototype.findBusinessEntityStartsWithAndRoleType = function (name, roleTypeId) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/findByNameOrCodeAndRoleType?name=" + name + "&id=" + roleTypeId)
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    BusinessEntityService.prototype.findBusinessEntityImporterCode = function (customsCode) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/findByRoleTypeAndCustomsCode?customsCode=" + customsCode + "&id=6")
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    BusinessEntityService.prototype.findBusinessEntityCreditorDocumentNumber = function (documentNumber) {
        return this.http
            .get(this.config.filesApiEndpoint + "api/businessEntities/search/findByRoleTypeAndCreditorDocumentNumber?documentNumber=" + documentNumber + "&id=11")
            .pipe(map(function (response) { return response['_embedded'].businessEntities; }));
    };
    BusinessEntityService.prototype.uploadEntitiesFromSpreadsheet = function (file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.http.put(this.config.filesApiEndpoint + "api/businessEntities/consigneesUpload", formData);
    };
    BusinessEntityService.prototype.saveBusinessEntityWithRole = function (code, roleId, roleNominationId, nominatedBusinessEntity) {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.put(this.config.filesApiEndpoint + ("api/businessEntities/" + code + "/roles/" + roleId + "/roleNominations/" + roleNominationId), JSON.stringify(nominatedBusinessEntity), { headers: headers });
    };
    BusinessEntityService.ngInjectableDef = i0.defineInjectable({ factory: function BusinessEntityService_Factory() { return new BusinessEntityService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: BusinessEntityService, providedIn: "root" });
    return BusinessEntityService;
}());
export { BusinessEntityService };
