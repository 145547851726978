<div class="ui-g-12">
  <p-toolbar>
    <div class="ui-toolbar-group-left">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-4 ui-md-4">
          <span class="md-inputfield">
            <div class="ui-inputgroup">

              <p-autoComplete [suggestions]="filteredDebtorEntities"
                              [ngModel]="debtorParentEntity"
                              field="label"
                              (onSelect)="onSelectDebtorEntity($event)" [minLength]="3"
                              (completeMethod)="search($event)"
                              [forceSelection]="true"
                              [autoHighlight]="true"
                              [inputStyle]="{color:'white'}"
                              placeholder="Debtor Code/Name">
                required = true>
                <ng-template let-option pTemplate="item">
                  <div>{{option.code}}:{{option.name}}</div>
                </ng-template>
              </p-autoComplete>

            </div>
          </span>
        </div>

        <span *ngIf="activeIndex === 1" class="ui-g-1 ui-md-1">
        </span>

        <div *ngIf="activeIndex === 1" class="ui-g-3 ui-md-3">
          <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask class="color-white" id="fromDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                             [(ngModel)]="fromDate"
                             placeholder="From">
                </p-inputMask>
            </span>
          </span>
        </div>

        <span *ngIf="activeIndex === 1" class="ui-g-1 ui-md-1">
        </span>

        <div *ngIf="activeIndex === 1" class="ui-g-3 ui-md-3">
          <span class="md-inputfield">
            <span class="ui-float-label">
                <p-inputMask class="color-white" id="toDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                             [(ngModel)]="toDate"
                             placeholder="To">
                </p-inputMask>
            </span>
          </span>
        </div>

      </div>

    </div>
    <div *ngIf="activeIndex === 0" class="ui-toolbar-group-right" style="color: black;">
      <button pButton type="button" (click)="clearDebtor()" label="Clear" icon="fa fa-file">
      </button>
      <button pButton type="button" (click)="saveTransactions()" label="Save" icon="fa fa-save">
      </button>
    </div>

    <div *ngIf="activeIndex === 1" class="ui-toolbar-group-right" style="color: black;">
        <button pButton type="button" label="Generate Statement"
                [disabled]="disableButton"
                (click)="generate()"></button>
        <button *ngIf="pdfSrc" pButton type="button" label="Print/Download" (click)="openLink()"></button>
    </div>


  </p-toolbar>

  <p-tabView orientation="left" styleClass="stepMenu" (onChange)="handleIndexChange($event)" [activeIndex]="activeIndex">
    <p-tabPanel header="Transactions" [selected]="true">
      <ng-template pTemplate="content">
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g">
            <div class="ui-g-7">
              <p-table [columns]="cols"
                       [value]="invoicesSelection"
                       selectionMode="single"
                       [(selection)]="selectedInvoice"
                       (onRowSelect)="selectInvoice($event.data)"
                       [paginator]="true"
                       [rows]="5">
                <ng-template pTemplate="caption">
                  Invoices
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                  <tr [pSelectableRow]="rowData">
                    <td>
                      {{rowData.invoiceDate}}
                    </td>
                    <td>
                      {{rowData.fileReferenceNumber}}
                    </td>
                    <td>
                      {{rowData.invoiceNo}}
                    </td>
                    <td>
                      {{rowData.currency.code === 'ZAR' ? rowData.localAmount : rowData.foreignAmount}}
                    </td>
                    <td>
                      {{getInvoiceBalance(rowData)}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div class="ui-g-5">
              <p-panel header="Transaction Details">

                <div class="ui-g form-group">

                  <div class="ui-g-6 ui-md-6">
                    <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input [(ngModel)]="selectedInvoice?.transactionDetails.cashBookAccountNo" type="text" pInputText>
                            <label>Cash Book Account</label>
                          </span>
                    </div>
                  </div>

                  <div class="ui-g-6 ui-md-6">
                    <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input [(ngModel)]="selectedInvoice?.transactionDetails.zarAmount" type="text" readonly pInputText>
                            <label>Amount {{selectedInvoice?.transactionDetails?.currencyCode}}</label>
                          </span>
                    </div>
                  </div>

                  <div class="ui-g-6 ui-md-6">
                    <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input [(ngModel)]="selectedInvoice?.transactionDetails.currencyCode" type="text" readonly pInputText>
                            <label>Payment Currency</label>
                          </span>
                    </div>
                  </div>

                  <div class="ui-g-6 ui-md-6">
                    <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input [(ngModel)]="selectedInvoice?.transactionDetails.referenceNo" type="text"pInputText>
                            <label>Reference #</label>
                          </span>
                    </div>
                  </div>

                  <div class="ui-g-6 ui-md-6">
                    <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input [(ngModel)]="selectedInvoice?.transactionDetails.roeForUAValues" type="text" pInputText>
                            <label>R.o.E for U/A values</label>
                          </span>
                    </div>
                  </div>

                  <div class="ui-g-6 ui-md-6">
                    <div class="ui-inputgroup">
                          <span class="md-inputfield">
                            <input [(ngModel)]="selectedInvoice?.transactionDetails.statementDescription" type="text" pInputText>
                            <label>Statement Desc.</label>
                          </span>
                    </div>
                  </div>

                </div>

              </p-panel>
            </div>

            <div class="ui-g-5">
              <p-panel header="New Transaction">
                <div class="ui-g ui-fluid">
                  <div class="ui-g-7 ui-md-7">
                  </div>
                  <div class="ui-g-7 ui-md-7">
                          <span class="ui-float-label ui-fluid">
                            <p-dropdown [options]="transactionTypes"
                                        [(ngModel)]="tranTypeToAdd"
                                        [autoWidth]="true" [style]="{'width':'100%','height':'25px'}">
                            </p-dropdown>
                            <label>Transaction Type</label>
                          </span>
                  </div>

                  <div *ngIf="tranTypeToAdd === 'CR JOURNAL' || tranTypeToAdd === 'DR JOURNAL'" class="ui-g-7 ui-md-7">
                  </div>
                  <div *ngIf="tranTypeToAdd === 'CR JOURNAL' || tranTypeToAdd === 'DR JOURNAL'" class="ui-g-7 ui-md-7">
                          <span class="md-inputfield">
                            <input type="text" [(ngModel)]="journalDescriptionToAdd" pInputText>
                            <label>Statement Description</label>
                          </span>
                  </div>

                  <div *ngIf="tranTypeToAdd === 'CR JOURNAL' || tranTypeToAdd === 'DR JOURNAL'" class="ui-g-7 ui-md-7">
                  </div>
                  <div *ngIf="tranTypeToAdd === 'CR JOURNAL' || tranTypeToAdd === 'DR JOURNAL'" class="ui-g-7 ui-md-7">
                          <span class="md-inputfield">
                            <input type="text" [(ngModel)]="journalReferenceToAdd" pInputText>
                            <label>Journal Reference</label>
                          </span>
                  </div>

                  <div class="ui-g-7 ui-md-7">
                  </div>
                  <div class="ui-g-7 ui-md-7">
                          <span class="md-inputfield">
                            <input type="text" [(ngModel)]="tranAmountToAdd" pInputText>
                            <label>Amount</label>
                          </span>
                  </div>

                  <div class="ui-g-5">
                    <button pButton type="button" label="Add Transaction"
                            [disabled]="!hasValidDetails()"
                            (click)="addNewTransaction()"></button>
                  </div>

                </div>
              </p-panel>
            </div>

            <div class="ui-g-7">
              <p-table [columns]="transactionCols"
                       [value]="transactionsSelection"
                       selectionMode="single"
                       [(selection)]="selectedTransaction"
                       (onRowSelect)="selectTransaction($event)"
                       [paginator]="true"
                       [rows]="5">
                <ng-template pTemplate="caption">
                  Transactions
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
                      {{col.header}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                  <tr [pSelectableRow]="rowData">
                    <td>
                      {{rowData.transactionDate}}
                    </td>
                    <td>
                      {{rowData.transactionType}}
                    </td>
                    <td>
                      {{rowData.transactionAmount}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>


    <p-tabPanel header="Statements">
      <div *ngIf="disableButton" class="ui-g-12">
        <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
      </div>
      <div class="card card-w-title">
        <div class="ui-g">
          <pdf-viewer class="ui-g-12 ui-md-12"
                      *ngIf="pdfSrc"
                      [src]="pdfSrc"
                      [render-text]="true"
                      [original-size]="true"
                      [show-all]="true"
                      [stick-to-page]="true"
                      (after-load-complete)="disableButton=false"
          ></pdf-viewer>
        </div>
      </div>
    </p-tabPanel>


  </p-tabView>
</div>
<p-confirmDialog header="Confirmation"
                 icon="pi pi-exclamation-triangle"
                 width="425"
                 [rejectLabel]="'No'"
                 [acceptLabel]="'Yes'"
></p-confirmDialog>
