import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PaymentsReportService} from '../../services/payments-report.service';
import {NotificationService} from '../../services/notification.service';
import {DebtorService} from '../../services/debtor.service';
import {CustomOfficeService} from '../../services/custom-office.service';
import {BillOfEntryService} from '../../services/bill-of-entry.service';
import {AuthorisationService} from '../../../../subjects/authorisation-behaviour-subject';
import {CustomsOffice, Warehouse, Debtor} from '../../../digi-business-entity/models/business-entity';
import {Subscription} from 'rxjs';
import {BillOfEntry, SequenceData} from '../../models/billOfEntries';
import {ImportsReportService} from '../../services/imports-report.service';
import {BusinessEntityService} from '../../../digi-business-entity/services/business-entity.service';

@Component({
  selector: 'digi-debtors-age-analysis-reports',
  templateUrl: './debtors-age-analysis-reports.component.html'
})
export class DebtorsAgeAnalysisReportsComponent implements OnInit, OnDestroy {
  fromDate: string;
  toDate: string;
  display = false;
  pdfSrc: string;
  disableButton: boolean;
  generatedFiles: any[];
  private reportSubscription: Subscription;

  constructor(
    private notificationService: NotificationService,
    private paymentsReportService: PaymentsReportService,
  ) {
  }

  ngOnInit() {
  }

  generate() {
    this.disableButton = true;
    this.reportSubscription = this.paymentsReportService.generateDebtorsAgeReports(this.fromDate, this.toDate).subscribe(data => {
      this.pdfSrc = data._embedded.supportingDocuments[0]._links.self.href + `?date=${Date.now()}`;
    },
    error => {
      this.notificationService.errorNotify(error.error.message, error.error.errors);
      this.pdfSrc = null;
      this.disableButton = false;
    });
  }

  showDialog() {
    this.display = true;
  }

  openLink() {
    window.open(this.pdfSrc, '_blank');
  }

  ngOnDestroy(): void {
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
  }
}
