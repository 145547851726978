<div class="ui-g">
  <div class="ui-g-12">
    <p-toolbar>
      <div class="ui-toolbar-group-left">
        <div class="ui-g-6 ui-md-6">
          <div class="ui-inputgroup">

            <p-autoComplete [suggestions]="filteredDebtorEntities"
                            [ngModel]="debtorParentEntity"
                            field="label"
                            (onSelect)="onSelectDebtorEntity($event)" [minLength]="3"
                            (completeMethod)="search($event)"
                            [forceSelection]="true"
                            [autoHighlight]="true"
                            [inputStyle]="{color:'white'}"
                            placeholder="Debtor Code/Name">
              required = true>
              <ng-template let-option pTemplate="item">
                <div>{{option.code}}:{{option.name}}</div>
              </ng-template>
            </p-autoComplete>

          </div>
        </div>

      </div>
      <div class="ui-toolbar-group-right" style="color: black;">
        <button pButton type="button" (click)="clearDebtor()" label="New" icon="fa fa-file">
        </button>
        <button pButton type="button" (click)="saveDebtorEntity()" label="Save" icon="fa fa-save">
        </button>
      </div>
    </p-toolbar>

    <p-tabView orientation="left" styleClass="stepMenu">
      <p-tabPanel header="Debtor" [selected]="true">

<!--        <ng-template pTemplate="content">-->
<!--          <div class="ui-g-12 ui-md-12">-->
<!--            <div class="ui-g-12 ui-g-nopad">-->
              <div class="ui-g-12 ui-md-12">

                <div class="ui-g-6 ui-md-6">
                  <p-panel header="Debtor Details">
                    <div class="ui-g ui-fluid">
                      <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-12">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input type="text" value={{debtorParentEntity?.code}} pInputText
                                       readonly>
                                <label>Debtor Code</label>
                            </span>
                          </div>
                        </div>
                        <div class="ui-g-12 ui-md-12" style="color: black;">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input type="text" value={{debtorParentEntity?.name}} pInputText
                                       readonly>
                                <label>Debtor name</label>
                            </span>
                          </div>
                        </div>
                        <div class="ui-g-12 ui-md-12">
                          <digi-currency [currency]="debtorEntity?.currency" (onSelect)="selectCurrency($event)"></digi-currency>
                        </div>

                        <div class="ui-g-12 ui-md-12">
                          <digi-country-of-origin [country]="debtorEntity?.country" (onSelect)="selectCountry($event)"></digi-country-of-origin>
                        </div>

                        <div class="ui-g-12 ui-md-12" style="color: black;">
                          <div class="ui-inputgroup">
                            <span class="ui-float-label">
                              <p-dropdown [autoDisplayFirst]="false"
                                          [options]="statuses"
                                          [(ngModel)]="debtorEntity.status"
                                          [showClear]="true"
                                          [autoWidth]="false"
                                          id="float-status-label">
                              </p-dropdown>
                              <label for="float-status-label">Status</label>
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </p-panel>
                </div>


                <div class="ui-g-6 ui-md-6">
                  <p-panel header="Financial Details">
                    <div class="ui-g form-group">
                      <div class="ui-g-12 ui-md-6">
                        <div class="ui-inputgroup">
                          <span class="ui-float-label">
                            <p-dropdown [autoDisplayFirst]="false"
                                        [options]="creditTerms"
                                        [(ngModel)]="debtorEntity.creditTerms"
                                        [showClear]="true"
                                        [autoWidth]="false"
                                        [style]="{ width: '75%' }"
                                        id="float-creditTerms-label">
                            </p-dropdown>
                            <label for="float-creditTerms-label">Credit Terms</label>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-6">
                        <div class="ui-inputgroup">
                          <span class="md-inputfield">
                              <input type="text"  pInputText [(ngModel)]="debtorEntity.creditBalance">
                              <label>Credit Balance</label>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-12 ui-md-6">
                        <div class="ui-inputgroup">
                          <span class="md-inputfield">
                              <input type="text"  pInputText [(ngModel)]="debtorEntity.insCreditLimit">
                              <label>Ins. Credit Limit</label>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-6 ui-md-6">
                        <div class="ui-inputgroup">
                          <span class="md-inputfield">
                              <input type="text"  pInputText [(ngModel)]="debtorEntity.creditLimit">
                              <label>Credit Limit</label>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-6 ui-md-6">
                        <div class="ui-inputgroup">
                          <span class="ui-float-label">
                              <p-dropdown [autoDisplayFirst]="false"
                                          [options]="includeFreightInCreditLimit"
                                          [(ngModel)]="debtorEntity.includeFreightInCreditLimit"
                                          [showClear]="true"
                                          [autoWidth]="false"
                                          [style]="{ width: '100%' }"
                                          id="float-includeFreightInCreditLimit"
                              >
                              </p-dropdown>
                            <label for="float-includeFreightInCreditLimit">Include Freight In Credit Limit? (Y/N)</label>
                          </span>
                        </div>
                      </div>
                      <div class="ui-g-6 ui-md-6">
                        <div class="ui-inputgroup">
                          <span class="md-inputfield">
                              <input type="text"  pInputText [(ngModel)]="debtorEntity.externalDebtorAccountNumber">
                              <label>External Debtor Account Number</label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>

              </div>
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
