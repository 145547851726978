import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {BillOfEntryMarksAndNumbers} from '../../../models/billOfEntries';
import {PackType} from '../../../models/roadManifest';
import {
  Address,
  Airline,
  CollectPrepaid,
  Currency,
  CustomsRadioCallSign,
  DepotTerminal,
  Freight,
  OceanLine,
  PortDetail,
  TariffCode,
  Container
} from '../../../models/file';
import {BusinessEntityService} from '../../../../digi-business-entity/services/business-entity.service';
import {ForwardingFileBehaviourSubject} from '../../../../../subjects/forwarding-file-behaviour-subject';
import {AgentService} from '../../../services/agent.service';
import {PackTypeService} from '../../../services/pack-type.service';
import {NotificationService} from '../../../services/notification.service';
import {
  ForwardingFile,
  MasterBillMeasures,
  RunningTotals,
  TrackingDetails,
  ForwardingTransportDocument,
  TransShipmentDetails
} from '../../../models/ForwardingFile';
import {DepotTerminalService} from '../../../services/depot-terminal.service';
import {TariffService} from '../../../services/tariff.service';
import {ForwardingFileService} from '../../../services/forwarding-file.service';
import {TransportService} from '../../../../digi-air/services/transport.service';
import {GoodsDescription, Package} from '../../../models/voyageFile';
import {Importer} from '../../../models/clearingFile';
import {OceanlineService} from '../../../../digi-ocean/services/oceanline.service';
import {CustomsRadioCallSignService} from '../../../services/customs-radio-call-sign.service';
import {APP_CONFIG, AppConfig} from '../../../../app.config.module';
import {Router} from '@angular/router';
import {ContainerBehaviorSubject} from '../../../../../subjects/container-behavior-subject';
import {ShareDataService} from '../../../services/share-data.service';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';

@Component({
  selector: 'digi-manifests',
  templateUrl: './manifests.component.html',
  styleUrls: ['./manifests.component.scss']
})
export class ManifestsComponent implements OnInit, OnDestroy {

  activeIndex = 0;
  forwardingFileServiceSubscription: Subscription;
  forwardingFile: ForwardingFile;
  callPurposes: string[];
  selectedHouseBill: ForwardingTransportDocument;
  cols: any[];
  generatedFiles: any[];
  selectedPackage: Package;

  filteredAirlines: any[];
  filteredLocalForwarders: any[];
  filteredDepotTerminals: DepotTerminal[];
  filteredBusinessEntities: any[];
  filteredPackTypes: PackType[];
  filteredTariffCodes: TariffCode[];
  filteredOceanLines: OceanLine[];
  filteredCustomsRadioCallSigns: CustomsRadioCallSign[];

  displayTrackingDialog: any;
  isHouseBill: boolean;
  transportDocumentToTrack: ForwardingTransportDocument;
  showTrackingProgressBar: any = false;
  display = false;

  private file: any;
  private hawbReportsSubscription: Subscription;
  showProgressBar: boolean;

  status: string;
  currentStatus: string;
  showTrackingButton = false;
  tdSubscription: Subscription;
  validationMessagesForHawbNo: any[] = [];
  hawbFilter: string;
  houseBillSelection: ForwardingTransportDocument[];
  tableFirst = 0;

  constructor(
    private shareDataService: ShareDataService,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private containerBehaviorSubject: ContainerBehaviorSubject,
    private messageService: NotificationService,
    private forwardingFileBehaviourSubject: ForwardingFileBehaviourSubject,
    private forwardingFileService: ForwardingFileService,
    private localAgentService: AgentService,
    private depotTerminalService: DepotTerminalService,
    private businessEntityService: BusinessEntityService,
    private transportService: TransportService,
    private packTypeService: PackTypeService,
    private tariffService: TariffService,
    private oceanLineService: OceanlineService,
    private customsRadioCallSignService: CustomsRadioCallSignService,
    @Inject(APP_CONFIG) public config: AppConfig,
    private router: Router
  ) { }

  ngOnInit() {
    this.cols = [
      {header: 'HAWB No'},
      {header: 'Importer'},
      {header: 'Client Ref No'},
      {header: 'Amount'}
    ];

    if (this.config.enableTracking) {
      this.cols.push({header: 'Tracking'});
    }

    this.createNewHawb();

    this.forwardingFileServiceSubscription = this.forwardingFileBehaviourSubject.getForwardingFile()
      .subscribe(file => {
        this.forwardingFile = file;

        if (this.forwardingFile.houseBills) {
          if (this.forwardingFile.houseBills.length > 0) {
                this.selectHouseBill(this.forwardingFile.houseBills[0]);
          } else {
            const transportDocument: ForwardingTransportDocument = this.shareDataService.addTransportDocument('forwarding');
                      this.forwardingFile.houseBills.push(transportDocument);
                      this.forwardingFile.houseBills = [...this.forwardingFile.houseBills];
                this.transportDocumentBehaviourSubject.addTransportDocument(transportDocument);
          }
          this.showTrackingButton = true;
        } else {
          this.createNewHawb();
          this.forwardingFile.houseBills = [this.selectedHouseBill];
          this.selectHouseBill(this.selectedHouseBill);
          this.transportDocumentBehaviourSubject.addTransportDocument(this.forwardingFile.houseBills[0]);
        }
        this.houseBillSelection = this.forwardingFile.houseBills;
      });

      this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
      .subscribe((td: ForwardingTransportDocument) => {
        if (td) {
          this.selectedHouseBill = td;
        }
      });
  }

  setTrackingStatus() {
    this.currentStatus = 'No Status Available';
    if (this.forwardingFile.masterBill) {
      if (this.forwardingFile.masterBill.trackingDetails) {
        if (this.forwardingFile.masterBill.trackingDetails.receivedAtJNBOPS) {
          this.currentStatus = 'Received At JNB OPS';
        } else if (this.forwardingFile.masterBill.trackingDetails.receivedAtORTIA) {
          this.currentStatus = 'Received At Airport';
        } else if (this.forwardingFile.masterBill.trackingDetails.releaseDate) {
          this.currentStatus = 'Received At Customs';
        } else if (this.forwardingFile.masterBill.trackingDetails.submissionDate) {
          this.currentStatus = 'Sent To Customs';
        }
      }
    }
  }

  track(transportDocument: ForwardingTransportDocument, isHouseBill: boolean) {
    this.setTrackingStatus();
    this.transportDocumentToTrack = transportDocument;
    if (!this.transportDocumentToTrack.trackingDetails) {
      this.transportDocumentToTrack.trackingDetails = new TrackingDetails();
    }

    if (this.forwardingFile.contrl) {
      this.transportDocumentToTrack.trackingDetails.submissionDate = this.forwardingFile.contrl.receivedDate;
    }

    if (this.forwardingFile.cusres) {
      this.transportDocumentToTrack.trackingDetails.releaseDate = this.forwardingFile.cusres.receivedDate;
    }
    this.isHouseBill = isHouseBill;
    this.displayTrackingDialog = true;
  }

  private createNewHawb() {
    this.selectedHouseBill = new ForwardingTransportDocument();
    this.selectedHouseBill.measures = new MasterBillMeasures();
    this.selectedHouseBill.freight = new Freight();
    this.selectedHouseBill.freight.collectPrepaid = new CollectPrepaid();
    this.selectedHouseBill.freight.currency = new Currency();
    this.selectedHouseBill.departurePort = new PortDetail();
    this.selectedHouseBill.destinationPort = new PortDetail();
    this.selectedHouseBill.notifyPartyAddress = new Address();
    this.selectedHouseBill.transShipmentDetails = new TransShipmentDetails();
    this.selectedHouseBill.transShipmentDetails.transferPort = new PortDetail();
    this.selectedHouseBill.containers = new Array<Container>();
  }

  handleIndexChange(index: number) {
    if (index === 1) {
      this.updateRunningTotals();
    }
  }

  createNewFile() {
    if (this.router.url === '/forwarding/sea/manifests') {
      this.forwardingFileService.createNewFile('SEA');
    }
    if (this.router.url === '/forwarding/air/manifests') {
      this.forwardingFileService.createNewFile('AIR');
    }
  }

  addNewHawb() {
    this.createNewHawb();
    if (this.forwardingFile.houseBills) {
      this.forwardingFile.houseBills.push(this.selectedHouseBill);
    } else {
      this.forwardingFile.houseBills = [this.selectedHouseBill];
    }
    this.houseBillSelection = this.forwardingFile.houseBills;
  }

  searchHousebill() {
    const searchedHawb = this.forwardingFile.houseBills.find(houseBill => houseBill.hawbNo === this.hawbFilter);
    if (searchedHawb) {
      this.selectHouseBill(searchedHawb);
      this.houseBillSelection = [searchedHawb];
    } else {
      this.houseBillSelection = this.forwardingFile.houseBills;
      this.selectHouseBill(this.forwardingFile.houseBills[0]);
      this.tableFirst = 0;
    }
  }

  clearHawbSearch() {
    this.houseBillSelection = this.forwardingFile.houseBills;
    this.selectHouseBill(this.forwardingFile.houseBills[0]);
    this.hawbFilter = undefined;
    this.tableFirst = 0;
  }

  generateReport() {
    this.showProgressBar = true;
    this.hawbReportsSubscription = this.forwardingFileService.generateHawbReport(this.forwardingFile.id, this.hawbFilter ? this.hawbFilter : null).subscribe(data => {
      this.generatedFiles = data._embedded.supportingDocuments;
      this.showDialog();
      this.showProgressBar = false;
    }, error => {
      this.showProgressBar = false;
      this.messageService.errorNotify(error.error.message, error.error.errors);
    });
  }

  selectHouseBill(value) {
    this.selectedHouseBill = value;
    this.transportDocumentBehaviourSubject.addTransportDocument(value);
    if (!this.selectedHouseBill.marksNumbersAndEndorsement) {
      const marksAndNumbers = new BillOfEntryMarksAndNumbers();
      marksAndNumbers.marksAndNumbers = [];
      marksAndNumbers.endorsements = [];
      this.selectedHouseBill.marksNumbersAndEndorsement = [marksAndNumbers];
    }

    if (!this.selectedHouseBill.packages || (this.selectedHouseBill.packages && !this.selectedHouseBill.packages.length)) {
      this.addNewPackage();
    } else {
      this.selectedPackage = this.selectedHouseBill.packages[0];
    }

    if (this.selectedHouseBill.containers && !this.selectedHouseBill.containers.length) {
      this.addContainer();
    }
  }
  searchAirline(event) {
    this.transportService.findAllAirLineStartsWith(event.query).subscribe(data => {
      this.filteredAirlines = data;
    });
  }

  selectAirline(value: any) {
    this.forwardingFile.masterBill.airline = value;
    this.forwardingFile.masterBill.airline.prefix = value.airlinePrefix;
  }

  selectMasterAirline(value: Airline) {
    this.forwardingFile.masterBill.masterAirline = value;
    this.forwardingFile.masterBill.masterAirline.prefix = value.airlinePrefix;
  }

  selectOverseasAgent(value: any) {
    this.forwardingFile.masterBill.overseasAgent = value;
  }

  selectCoLoader(value: any) {
    this.forwardingFile.masterBill.coLoader = value;
  }

  searchLocalForwarder(event: any) {
    this.localAgentService
      .findLocalForwarderByNameOrCargoCarrierCodeAndTransportMethod(event.query, 'AIR')
      .subscribe(data => {
        this.filteredLocalForwarders = data;
      });
  }

  searchDepotTerminal(event, depotTerminal) {
    this.depotTerminalService.findAllDepotTerminalStartsWith(event.query, depotTerminal).subscribe(data => {
      this.filteredDepotTerminals = data;
    });
  }

  updateCustomsOffice(customOffice) {
    this.forwardingFile.customOffice = customOffice;
  }

  selectWarehouse(value) {
    this.forwardingFile.warehouse = value;
  }

  onRowDelete(rowIndex: number) {
    this.forwardingFile.houseBills.splice(rowIndex, 1);
    this.forwardingFile.houseBills = [...this.forwardingFile.houseBills];
  }

  selectImporter(value: Importer) {
    this.selectedHouseBill.importer = value;
  }

  selectLocalAgent(value: any) {
    this.selectedHouseBill.localAgent = value;
  }

  selectSupplier(value: any) {
    this.selectedHouseBill.supplier = value;
  }

  selectDebtor(value: any) {
    this.selectedHouseBill.debtor = value;
  }

  selectHouseOverseas(value: any) {
    this.selectedHouseBill.overseasAgent = value;
  }

  updateMarksAndNumbers(value) {
    this.selectedHouseBill.marksNumbersAndEndorsement = value;
  }

  addNewPackage() {
    this.selectedPackage = new Package();
    this.selectedPackage.goodsDescription = new GoodsDescription();
    this.selectedHouseBill.packages = [this.selectedPackage];
  }

  addContainer() {
    const container: Container = this.shareDataService.addContainer();
    this.selectedHouseBill.containers.push(container);
    this.selectedHouseBill.containers = [...this.selectedHouseBill.containers];
    this.containerBehaviorSubject.addContainer(container);
  }

  searchPackTypes(event) {
    this.packTypeService.findByCodeOrDescription(event.query).subscribe(
      data => this.filteredPackTypes = data
    );
  }

  selectPackType(value) {
    this.selectedPackage.packageType = value;
  }

  searchTariffCode(event) {
    this.tariffService
      .findTariffByCodeStartsWithCurrentlyEffectiveAndIsTariff(event.query)
      .subscribe(data => {
        this.filteredTariffCodes = data;
      });
  }

  selectTariffCode(value) {
    this.selectedPackage.tariffCode = value;
  }

  showDialog() {
    this.display = true;
  }

  updateRunningTotals() {
    this.forwardingFile.masterBill.runningTotals = new RunningTotals();

    this.forwardingFile.masterBill.runningTotals.capturedNoOfHouseBills = this.forwardingFile.houseBills.length;

    this.forwardingFile.masterBill.runningTotals.remainingNoOfHouseBills =
      this.forwardingFile.masterBill.measures.numberOfHawbs -
      (this.forwardingFile.masterBill.runningTotals.capturedNoOfHouseBills ?
        this.forwardingFile.masterBill.runningTotals.capturedNoOfHouseBills : 0);

    // noOfPacks
    this.forwardingFile.masterBill.runningTotals.capturedNoOfPacks = Math.round(this.forwardingFile.houseBills
      .map(hawb => hawb.measures.numberOfPackages)
      .reduce((sum, current) => current ? +sum + +current : +sum + 0, 0) * 100) / 100;

    // volume
    this.forwardingFile.masterBill.measures.volume = Math.round(this.forwardingFile.houseBills
      .map(hawb => hawb.measures.volume)
      .reduce((sum, current) => current ? +sum + +current : +sum + 0, 0) * 100) / 100;

    this.forwardingFile.masterBill.runningTotals.remainingNoOfPacks = Math.round((this.forwardingFile.masterBill.measures.numberOfPackages -
      (this.forwardingFile.masterBill.runningTotals.capturedNoOfPacks ?
        this.forwardingFile.masterBill.runningTotals.capturedNoOfPacks : 0)) * 100) / 100;

    // weight
    this.forwardingFile.masterBill.runningTotals.capturedWeight = Math.round(this.forwardingFile.houseBills
      .map(hawb => hawb.measures.grossWeight)
      .reduce((sum, current) => current ? +sum + +current : +sum, 0) * 100) / 100;

    this.forwardingFile.masterBill.runningTotals.remainingWeight = Math.round((this.forwardingFile.masterBill.measures.grossWeight -
      (this.forwardingFile.masterBill.runningTotals.capturedWeight ?
        this.forwardingFile.masterBill.runningTotals.capturedWeight : 0)) * 100) / 100;

    // chargeableWeight
    this.forwardingFile.masterBill.runningTotals.capturedChargeableWeight = this.forwardingFile.houseBills
      .map(hawb => hawb.measures.chargeableWeight)
      .reduce((sum, current) => current ? +sum + +current : +sum, 0);

    // noOfContainers
    this.forwardingFile.masterBill.measures.noOfContainers = Math.round(this.forwardingFile.houseBills
      .map(hawb => hawb.measures.noOfContainers)
      .reduce((sum, current) => current ? +sum + +current : +sum + 0, 0) * 100) / 100;

    this.forwardingFile.masterBill.runningTotals.remainingChargeableWeight =
      this.forwardingFile.masterBill.measures.chargeableWeight -
      (this.forwardingFile.masterBill.runningTotals.capturedChargeableWeight ?
        this.forwardingFile.masterBill.runningTotals.capturedChargeableWeight : 0);
  }

  selectFiles(event: any) {
    const files = event.originalEvent.srcElement.files;
    this.file = files[0];
  }

  uploadManifest() {
    this.showProgressBar = true;
    if (this.forwardingFile && this.forwardingFile.id) {
      this.forwardingFileService.updateFromSpreadsheet(this.forwardingFile.id, this.file).subscribe(
        file => {
          this.forwardingFileBehaviourSubject.addForwardingFile(file);
          this.showProgressBar = false;
        }
      );
    } else {
      this.forwardingFileService.createFromSpreadsheet(this.file, this.forwardingFile.transportMethodCode).subscribe(
        file => {
          this.forwardingFileBehaviourSubject.addForwardingFile(file);
          this.showProgressBar = false;
        },
        error => {
          this.showProgressBar = false;
        }
      );
    }
  }

  isTransportMethodCodeSameAs(code: string): boolean {
    return this.forwardingFile && this.forwardingFile.transportMethodCode.toLowerCase() === code;
  }

  searchOceanLines(event: any) {
    this.oceanLineService.findAllOceanLineStartsWith(event.query).subscribe(data => {
      this.filteredOceanLines = data;
    });
  }

  searchRadioCallSigns(event, field) {
    this.customsRadioCallSignService.findAllCustomsRadioCallSignStartsWith(event.query, field).subscribe(data => {
      this.filteredCustomsRadioCallSigns = data;
    });
  }

  saveForwardingFile() {
    this.forwardingFileService.save(this.forwardingFile).subscribe(forwardingFile => {
      this.messageService.successNotify('File Saved Successfully');
      this.forwardingFileBehaviourSubject.addForwardingFile(forwardingFile);
    });
  }

  updateReceivedAtORTIAFieldsOnEachHouseBill() {
    this.updateHouseBills(this.forwardingFile.masterBill.trackingDetails.receivedAtORTIA, 'airport');
  }

  updateHouseBills(status: string, airportOpsIndicator: string) {
    if (this.forwardingFile.houseBills) {
      for (const houseBill of this.forwardingFile.houseBills) {
        if (!houseBill.trackingDetails) {
          houseBill.trackingDetails = new TrackingDetails();
        }
        if (airportOpsIndicator === 'ops') {
          houseBill.trackingDetails.receivedAtJNBOPS = status;
        } else {
          houseBill.trackingDetails.receivedAtORTIA = status;
        }
      }
      this.forwardingFileService.save(this.forwardingFile).subscribe(_ => {
        this.messageService.successNotify('House Bills Updated');
        this.setTrackingStatus();
      });
    }
  }

  updateReceivedAtJNOPSFieldsOnEachHouseBill() {
    this.updateHouseBills(this.forwardingFile.masterBill.trackingDetails.receivedAtJNBOPS, 'ops');
  }

  sendUpdate(status: any) {
    this.showTrackingProgressBar = true;
    const references: Array<string> = [];
    if (this.forwardingFile.houseBills) {
      for (const houseBill of this.forwardingFile.houseBills) {
        references.push(houseBill.hawbNo);
      }
      this.forwardingFileService.sendParcelUpdates( references, status)
        .subscribe(_ => {
          this.messageService.successNotify('Successfully Updated API.');
          this.showTrackingProgressBar = false;
        });
    }
  }

  validateHawbNo() {
    this.forwardingFileService.findForwardingFileNumbersByHawbNo(this.selectedHouseBill.hawbNo, this.forwardingFile.fileNumber).subscribe(
      res => {
        if (res.length > 0) {
          const message = ' [' + this.selectedHouseBill.hawbNo + ']' + ' is also used in [' + res.join(', ') + ']';
          const foundDetail = this.validationMessagesForHawbNo.find(el => el.detail === message);
          if (!foundDetail) {
            this.validationMessagesForHawbNo.push({severity: 'warn', summary: 'Duplicate HAWB No.', detail: message});
          }
        } else {
          this.validationMessagesForHawbNo.length = 0;
        }
      }, _ => this.validationMessagesForHawbNo.length = 0
    );
  }

  getStatusAndEvents() {
    this.forwardingFileService.getStatusCodesAndEvents()
      .subscribe(_ => {});
  }

  ngOnDestroy() {
      this.forwardingFileServiceSubscription.unsubscribe();
      this.tdSubscription.unsubscribe();
    }
}
