<div class="ui-g form-group" *ngIf="clearingFile">
  <div class="ui-g-12 ui-md-12">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
    <p-dropdown [options]="roles" [(ngModel)]="selectedRole" (onChange)="roleChanged()" [autoWidth]="false"></p-dropdown>
    <label>Select Role for Guarantor</label>
    </span>
    </div>
  </div>
  <div class="ui-g-12 ui-md-12">
    <span class="md-inputfield" *ngIf="selectedRole !== 'Local Agent'">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
         <p-autoComplete id="guarantor"
                         [(ngModel)]="clearingInstruction.guarantor"
                         field="label"
                         [suggestions]="filteredGuarantors"
                         (completeMethod)="search($event)"
                         [autoHighlight]="true"
                         (onSelect)="selectGuarantor($event)"
                         minLength="1"
                         required=true>
        <ng-template let-option pTemplate="item">
          <div>{{option.label}}</div>
        </ng-template>
      </p-autoComplete>
        <label for="guarantor"> Search Remover</label>
      </span>
      <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
    <div *ngIf="!clearingInstruction?.guarantor?.code" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
    <span class="md-inputfield" *ngIf="selectedRole === 'Local Agent'">
        <input
          type="text" pInputText
          [value]="clearingInstruction.guarantor.label"
          readonly>
        <label>Local Agent</label>
      <div *ngIf="!clearingInstruction?.guarantor?.code&& selectedRole !== 'Local Agent'" class="ui-message ui-messages-error ui-corner-all">
      This field is required
    </div>
        <div *ngIf="!clearingInstruction?.guarantor?.code && selectedRole === 'Local Agent'" class="ui-message ui-messages-error ui-corner-all">
      Local Agent not a Remover
    </div>
      </span>
  </div>
  <digi-address [addresses]="guarantorAddresses"
                [address]="clearingInstruction?.guarantor?.address"></digi-address>
  <div class="ui-g-12 ui-md-12" *ngIf="selectedRole === 'Remover' || selectedRole === 'Local Agent'">
    <div class="ui-g-12 ui-md-12">
      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="clearingInstruction.guarantor.bondValue">
                            <label>Bond Value</label>
                        </span>
      </div>
    </div>
    <div class="ui-g-12 ui-md-12">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="clearingInstruction.guarantor.percentageUnderSplit">
                            <label>Percentage Under Split %</label>
                        </span>
        </div>
      </div>
    </div>
  </div>
</div>
