import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ShareDataService} from '../../../services/share-data.service';
import {combineLatest, Subscription} from 'rxjs';
import {ClearingFile} from '../../../models/clearingFile';
import {ClearingFileService} from '../../../services/clearing-file.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {DebtorService} from '../../../services/debtor.service';
import {
  ChargeType,
  Department,
  Invoice,
  InvoiceSection,
  InvoiceSubSection,
  SupplierInvoiceLine,
  TranType
} from '../../../models/financials';
import {ChargeTypeService} from '../../../services/charge-type.service';
import {TranTypeService} from '../../../services/tran-type.service';
import {DepartmentService} from '../../../services/department.service';
import {InvoiceSectionService} from '../../../services/invoice-section.service';
import {TaxType} from '../../../models/TariffCode';
import {TaxTypeService} from '../../../services/tax-type.service';
import {KeyValue} from '@angular/common';
import {BillOfEntry} from '../../../models/billOfEntries';
import {BillOfEntryBehaviourSubject} from '../../../../../subjects/billOfEntry-behaviour-subject';
import {Table} from 'primeng/table';
import {InvoiceService} from '../../../services/invoice.service';
import {BusinessEntityService} from '../../../../digi-business-entity/services/business-entity.service';
import {BusinessEntity} from '../../../../digi-business-entity/models/business-entity';
import {NotificationService} from '../../../services/notification.service';
import {AuthorisationService} from '../../../../../subjects/authorisation-behaviour-subject';
import {CompanyService} from '../../../services/company.service';
import {BankingDetails, Company} from '../../../models/company';
import {FileType} from '../../../models/enumerations';
import {Address, File} from '../../../models/file';
import {ExportFileBehaviourSubject} from '../../../../../subjects/exportfile-behaviour-subject';
import {DomSanitizer} from '@angular/platform-browser';

export class Entry {
  invoiceSection: string;
  lines: SupplierInvoiceLine[];
}

@Component({
  selector: 'digi-fin-supplrinv-headers',
  templateUrl: './fin-supplrinv-headers.component.html',
  styleUrls: ['./fin-supplrinv-headers.component.scss']
})
export class FinSupplrinvHeadersComponent implements OnInit, OnDestroy {
  file: File;
  invoice = new Invoice();
  invoices: Invoice[];
  invoicePhysicalAddress: Address;
  invoicePostalAddress: Address;
  filteredChargeTypes: ChargeType[];
  filteredTaxTypes: TaxType[];
  filteredTranTypes: TranType[];
  filteredDepartments: Department[];
  filteredInvoiceSections: InvoiceSection[];
  filteredInvoiceSubSections: InvoiceSubSection[];
  selectedBillOfEntry: BillOfEntry;
  @Input() fileType: FileType = 'imports';
  transportMethod: string;

  clearingFileSubscription: Subscription;
  BOESubscription: Subscription;
  invoiceSubscription: Subscription;
  invoicesSubscription: Subscription;
  taxTypeSubscription: Subscription;
  chargeTypeSubscription: Subscription;
  companySubscription: Subscription;
  invoiceSectionSubscription: Subscription;
  invoiceSubSectionSubscription: Subscription;
  departmentsSubscription: Subscription;
  tranTypeSubscription: Subscription;
  defaultValues: Subscription;

  editingLine = -1;
  showPrintView = false;

  invoiceSectionID: number;

  cols: any[];

  vat: number;

  @ViewChild('templateRef') templateRef: Table;

  ctrlIsPressed = false;
  loggedInUser: string;
  debtor: any;
  logo: any;
  bankingDetails: BankingDetails[];

  @HostListener('window:keydown', ['$event.keyCode'])
  onKeyDown(keyCode) {
    if (keyCode === 17) {
      this.ctrlIsPressed = true;
    }
    if (keyCode === 65 && this.ctrlIsPressed && !this.showPrintView) {
      this.addNewLine();
    }
  }

  @HostListener('window:keyup', ['$event.keyCode'])
  onKeyUp(keyCode) {
    if (keyCode === 17) {
      this.ctrlIsPressed = false;
    }
  }

  constructor(
    private shareDataService: ShareDataService,
    private debtorService: DebtorService,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private chargeTypeService: ChargeTypeService,
    private tranTypeService: TranTypeService,
    private departmentService: DepartmentService,
    private clearingFileService: ClearingFileService,
    private invoiceSectionService: InvoiceSectionService,
    private taxTypeService: TaxTypeService,
    private invoiceService: InvoiceService,
    private businessEntityService: BusinessEntityService,
    private messageService: NotificationService,
    private authorisationService: AuthorisationService,
    private companyService: CompanyService,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.authorisationService
      .getLoggedInUser()
      .subscribe(user => {
        if (user) {
          this.loggedInUser = user.name;
        }
      });

    this.getCompany();

    this.cols = [
      {field: 'invoiceNo', header: 'Invoice No'},
      {field: 'date', header: 'Invoice Date'}
    ];
    this.BOESubscription = this.billOfEntryBehaviourSubject.getBillOfEntry().subscribe(
      boe => {
        this.selectedBillOfEntry = boe;
      }
    );

    if (this.fileType === 'imports') {
      this.clearingFileSubscription = this.clearingFileBehaviourSubject.getClearingFile().subscribe(
        cf => {
          this.file = cf;
          this.transportMethod = this.file.clearingInstructions[0].transportMethod.method;
          this.searchDebtor(this.file.debtor.code);
          this.invoicesSubscription = this.invoiceService.getInvoices(cf).subscribe(
            invoices => {
              this.invoices = invoices;
              if (invoices && invoices.length) {
                this.invoice = invoices[0];
              }

              if (this.invoice && this.invoice.confirmed) {
                this.showPrintView = true;
              }

              if (this.invoice && !this.invoice.confirmed) {
                this.getValuesFromBOE();
              }
            }
          );
        }
      );
    } else {
      this.clearingFileSubscription = this.exportFileBehaviourSubject.getExportFile().subscribe(
        ef => {
          this.file = ef;
          this.transportMethod = this.file.clearingInstructions[0].transportMethod.method;
          this.searchDebtor(this.file.debtor.code);
          this.invoicesSubscription = this.invoiceService.getInvoices(ef).subscribe(
            invoices => {
              this.invoices = invoices;
              if (invoices && invoices.length) {
                this.invoice = invoices[0];
              }

              if (this.invoice && this.invoice.confirmed) {
                this.showPrintView = true;
              }

              if (this.invoice && !this.invoice.confirmed) {
                this.getValuesFromBOE();
              }
            }
          );
        }
      );
    }
  }

  order = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  searchDebtor(query) {
    this.businessEntityService.findBusinessEntityStartsWithAndRoleType(query, 1).subscribe(
      (businessEntities: BusinessEntity[]) => {
        if (businessEntities[0]) {
          this.file.debtor.vatNo = businessEntities[0].defaultVatRegNo;
          this.invoice.currency = businessEntities[0].roles.find(role => role.roleType === 1)['currency'];
          if (this.invoice.currency.code === 'ZAR') {
            this.invoice.exchangeRate = 1;
          }
          this.debtor = businessEntities[0];
          this.invoice.debtorCodeRef = this.debtor.code;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.clearingFileSubscription) {
      this.clearingFileSubscription.unsubscribe();
    }
    if (this.BOESubscription) {
      this.BOESubscription.unsubscribe();
    }
    if (this.invoiceSubscription) {
      this.invoiceSubscription.unsubscribe();
    }
    if (this.invoicesSubscription) {
      this.invoicesSubscription.unsubscribe();
    }
    if (this.taxTypeSubscription) {
      this.taxTypeSubscription.unsubscribe();
    }
    if (this.chargeTypeSubscription) {
      this.chargeTypeSubscription.unsubscribe();
    }
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
    if (this.invoiceSectionSubscription) {
      this.invoiceSectionSubscription.unsubscribe();
    }
    if (this.invoiceSubSectionSubscription) {
      this.invoiceSubSectionSubscription.unsubscribe();
    }
    if (this.departmentsSubscription) {
      this.departmentsSubscription.unsubscribe();
    }
    if (this.tranTypeSubscription) {
      this.tranTypeSubscription.unsubscribe();
    }

    if (this.defaultValues) {
      this.defaultValues.unsubscribe();
    }
  }

  saveInvoice() {
    if (this.invoice.confirmed) {
      this.messageService.errorNotify('Cannot Save Invoice - Invoice has already been confirmed');
    } else {
      this.invoice.companyPostalAddress = this.getFormattedAddress(this.invoicePostalAddress);
      this.invoice.companyPhysicalAddress = this.getFormattedAddress(this.invoicePhysicalAddress);
      this.invoice.fileReferenceNumber = this.file.clearingFileNumber;
      this.invoiceSubscription = this.invoiceService.saveInvoice(this.invoice, this.file).subscribe(
        data => {
          this.messageService.successNotify('Invoice Saved Successfully');
          this.invoice = data;
          if (!this.invoices.find(el => el.invoiceNo === this.invoice.invoiceNo)) {
            this.invoices.push(this.invoice);
          }
        }
      );
    }
  }

  searchTaxTypeCode(event) {
    this.taxTypeSubscription = this.taxTypeService.findTaxTypeByCodeStartWith(event.query).subscribe(taxTypes => {
      this.filteredTaxTypes = taxTypes;
    });
  }

  searchTranType(event) {
    this.tranTypeSubscription = this.tranTypeService.findTranTypesByCodeStartWith(event.query).subscribe(tranTypes => {
      this.filteredTranTypes = tranTypes;
    });
  }

  searchChargeType(event) {
    this.chargeTypeSubscription = this.chargeTypeService.findChargeTypesByCodeStartWith(event.query).subscribe(chargeTypes => {
      this.filteredChargeTypes = chargeTypes;
    });
  }

  searchDepartmentCode(event) {
    this.departmentsSubscription = this.departmentService.findDepartmentsByCodeStartWith(event.query).subscribe(
      departments => {
        this.filteredDepartments = departments;
      }
    );
  }

  searchInvoiceSection(event) {
    this.invoiceSectionSubscription = this.invoiceSectionService.findInvoiceSection(event.query).subscribe(
      invoiceSections => {
        this.filteredInvoiceSections = invoiceSections;
      }
    );
  }

  searchInvoiceSubSection(query, invoiceSection: InvoiceSection) {
    if (invoiceSection.id) {
      this.invoiceSubSectionSubscription = this.invoiceSectionService
        .findInvoiceSubSectionsByInvoiceSectionId(invoiceSection.id, query)
        .subscribe(
          invoiceSubSections => {
            this.filteredInvoiceSubSections = [];
            invoiceSubSections.forEach(invoiceSubSection => this.filteredInvoiceSubSections.push(invoiceSubSection.description));
          }
        );
    } else {
      this.invoiceSectionSubscription = this.invoiceSectionService
        .findInvoiceSection(invoiceSection.description)
        .subscribe(
          invoiceSections => {
            this.invoiceSubSectionSubscription = this.invoiceSectionService
              .findInvoiceSubSectionsByInvoiceSectionId(invoiceSections[0].id, query)
              .subscribe(
                invoiceSubSections => {
                  this.filteredInvoiceSubSections = [];
                  invoiceSubSections.forEach(invoiceSubSection => this.filteredInvoiceSubSections.push(invoiceSubSection.description));
                }
              );
          }
        );
    }
  }

  getLines() {
    const entries: Entry[] = [];
    if (this.invoice.lineDetails) {
      this.invoice.lineDetails.forEach(el => {
        if (el.invoiceSection) {
          const foundEntry = entries.find(entry => entry.invoiceSection === el.invoiceSection.description);
          if (foundEntry) {
            const foundLine = foundEntry.lines.find(l => l.invoiceSubSection === el.invoiceSubSection);
            // do not add if line already exists (consolidated already)
            if (!foundLine) {
              const consolidatedLine = this.consolidate(el.invoiceSubSection, el.taxType.sarsTaxCode);
              foundEntry.lines.push(consolidatedLine);
            }
          } else {
            entries.push({
              invoiceSection: el.invoiceSection.description,
              lines: [this.consolidate(el.invoiceSubSection, el.taxType.sarsTaxCode)]
            });
          }
        }
      });
    }

    return entries;
  }

  getTotal(lines, value) {
    let sum = 0;
    let isLocalInv = this.isLocalInvoice();
    if (lines) {
      lines.forEach(el => {
        if (value === 'vat' && isLocalInv) {
          el.localTax ? sum += Number(el.localTax) : sum += 0;
        }

        if (value === 'localAmount') {
          if (isLocalInv) {
            el.localAmount ? sum += Number(el.localAmount) : sum += 0;
          } else {
            el.foreignAmount ? sum += Number(el.foreignAmount) : sum += 0;
          }
        }

      });
    } else {
      if (value === 'totalExclVat' && this.invoice.lineDetails) {
        this.invoice.lineDetails.forEach(lineDetail => {
          if (isLocalInv) {
            lineDetail.localAmount ? sum += Number(lineDetail.localAmount) : sum += 0;
          } else {
            lineDetail.foreignAmount ? sum += Number(lineDetail.foreignAmount) : sum += 0;
          }
        });
      }

      if (value === 'totalVat' && this.invoice.lineDetails && isLocalInv) {
        this.invoice.lineDetails.forEach(lineDetail => {
          lineDetail.localTax ? sum += Number(lineDetail.localTax) : sum += 0;
        });
      }
    }
    return Number(sum.toFixed(2));
  }

  getTaxSummary() {
    const taxSummaryArr = {S: 0, E: 0, Z: 0, N: 0};
    let isLocal = this.isLocalInvoice();
    if (this.invoice.lineDetails && isLocal) {
      this.invoice.lineDetails.forEach(
        el => {
          if (el.taxType) {
            taxSummaryArr[el.taxType.sarsTaxCode] += Number(el.localAmount);
          }
        }
      );
    }
    return taxSummaryArr;
  }

  addNewLine() {
    const line = new SupplierInvoiceLine();
    if (this.invoice.taxType) {
      line.taxType = this.invoice.taxType;
    }
    if (this.isLocalInvoice()) {
      line.localAmount = 0;
      line.localTax = 0;
    } else {
      line.foreignAmount = 0;
      line.foreignTax = 0;
    }

    if (this.invoice.lineDetails && this.invoice.lineDetails.length) {
      this.invoice.lineDetails.push(line);
    } else {
      this.invoice.lineDetails = [];
      this.invoice.lineDetails.push(line);
    }

    // autofocus on charge type column of a new line
    setTimeout(() => {
      this.templateRef.tableViewChild.nativeElement.getElementsByTagName('tr')
        .item(this.templateRef.value.length).getElementsByTagName('input').item(0).focus();
    }, 300);
  }

  deleteLine(index) {
    this.invoice.lineDetails = this.invoice.lineDetails.filter(el => this.invoice.lineDetails.indexOf(el) !== index);
    this.editingLine = -1;
  }

  print() {
    window.print();
  }

  createNewInvoice() {
    this.invoice = new Invoice();
    this.searchDebtor(this.file.debtor.code);
    this.getCompany();
    this.getValuesFromBOE();
  }

  private isLocalInvoice() {
    return !this.invoice.currency || this.invoice.currency && this.invoice.currency.code === 'ZAR';
  }

  private getCaptured() {
    return (this.getTotal(null, 'totalExclVat') + this.getTotal(null, 'totalVat')).toFixed(2);
  }

  confirmInvoice() {
    const errors = this.validateInvoice();
    if (errors.length === 0) {
      this.invoice.confirmed = true;
      this.invoice.paidAmount = 0;
      this.invoiceSubscription = this.invoiceService.saveInvoice(this.invoice, this.file).subscribe(
        invoice => {
          if (invoice.confirmed) {
            this.messageService.successNotify('Invoice Confirmed Successfully');
            this.showPrintView = true;
          }
        }
      );
    } else {
      this.messageService.errorNotify('Invalid Invoice', errors);
    }
  }

  private getCompany() {
    this.companySubscription = this.companyService.getCompany().subscribe(
      (companies: Company[]) => {
        if (companies && companies.length) {
          const company = companies[0];
          const branch = this.file.branch ? company.branches.find(el => el.code === this.file.branch) : company.branches[0];
          this.logo = this.domSanitizer.bypassSecurityTrustStyle(`url(${'data:image/png;base64,' + company.logo})`);
          this.invoice.companyName = branch.name;
          this.invoice.companyPostalAddress = this.getFormattedAddress(branch.invoiceDetails.postalAddress);
          this.invoicePostalAddress = branch.invoiceDetails.postalAddress;
          this.invoice.companyPhysicalAddress = this.getFormattedAddress(branch.invoiceDetails.physicalAddress);
          this.invoicePhysicalAddress = branch.invoiceDetails.physicalAddress;
          this.invoice.companyVatNo = branch.invoiceDetails.vatNo;
          this.invoice.companyTel = branch.invoiceDetails.tel;
          this.invoice.companyFax = branch.invoiceDetails.fax;
          this.invoice.companyEmail = branch.invoiceDetails.email;

          this.invoice.companyReg = branch.invoiceDetails.regNo;
          this.bankingDetails = branch.invoiceDetails.bankingDetails;

          this.vat = company.companyDefaults.vat;

          const invoiceLines = branch.invoiceLines;
          if (invoiceLines) {
            let filteredItems = [];
            if (this.fileType === 'imports') {
              if (this.transportMethod.trim() === 'AIR') {
                filteredItems = invoiceLines.filter(el => el.forAirImports);
              } else if (this.transportMethod.trim() === 'SEA') {
                filteredItems = invoiceLines.filter(el => el.forSeaImports);
              } else {
                filteredItems = invoiceLines.filter(el => el.forRoadImports);
              }
            }

            if (this.fileType === 'exports') {
              if (this.transportMethod.trim() === 'AIR') {
                filteredItems = invoiceLines.filter(el => el.forAirExports);
              } else if (this.transportMethod.trim() === 'SEA') {
                filteredItems = invoiceLines.filter(el => el.forSeaExports);
              } else {
                filteredItems = invoiceLines.filter(el => el.forRoadExports);
              }
            }

            if (this.invoice.lineDetails) {
              this.invoice.lineDetails.concat(filteredItems);
            } else {
              this.invoice.lineDetails = filteredItems;
            }
          }
        }
      }
    );
  }

  private getValuesFromBOE() {
    let defaultTaxType;
    let defaultTranType;
    let defaultInvoiceSection;
    const taxTypeObservable = this.taxTypeService.findByCode('vna');
    const invoiceSectionObservable = this.invoiceSectionService.findInvoiceSection('disbursement');
    const tranTypeObservable = this.tranTypeService.findTranTypesByCodeStartWith('rec');
    this.defaultValues = combineLatest(taxTypeObservable, invoiceSectionObservable, tranTypeObservable).subscribe(
      (defaults: any[]) => {
        if (defaults) {
          defaultTaxType = defaults[0];
          if (defaults[1]) {
            defaultInvoiceSection = defaults[1][0];
            this.invoiceSectionID = defaultInvoiceSection.id;
          }
          if (defaults[2]) {
            defaultTranType = defaults[2][0];
          }

          let foundCusDChargeType;
          let foundCusVChargeType;
          let foundCusOChargeType;
          let foundSc1p2aChargeType;
          let foundSc1p2bChargeType;
          let foundSc1p3dChargeType;
          let foundSc1p3eChargeType;

          if (this.invoice && this.invoice.lineDetails) {
            foundCusDChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'CUSD  ');
            foundCusVChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'CUSV  ');
            foundCusOChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'CUSOTH');
            foundSc1p2aChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'SC1P2A');
            foundSc1p2bChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'SC1P2B');
            foundSc1p3dChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'SC1P3D');
            foundSc1p3eChargeType = this.invoice.lineDetails.find(line => line.chargeType && line.chargeType.code === 'SC1P3E');

          }

          if (this.selectedBillOfEntry.totalCustomsDuty > 0 && !foundCusDChargeType) {
            this.invoiceSectionService.findInvoiceSubSectionsByInvoiceSectionId(this.invoiceSectionID, 'Customs - Duty').subscribe(
              data => this.addChargeTypeFromBOE('cusd', defaultTaxType, defaultInvoiceSection, data[0].description,
                defaultTranType, this.selectedBillOfEntry.totalCustomsDuty, 0)
            );
          }

          if (this.selectedBillOfEntry.totalCustomsVAT > 0 && !foundCusVChargeType) {
            this.invoiceSectionService.findInvoiceSubSectionsByInvoiceSectionId(this.invoiceSectionID, 'Customs - VAT').subscribe(
              data => this.addChargeTypeFromBOE('cusv', defaultTaxType, defaultInvoiceSection, data[0].description,
                defaultTranType, 0, this.selectedBillOfEntry.totalCustomsVAT)
            );
          }

          // tslint:disable-next-line:max-line-length
          if (this.selectedBillOfEntry.schedulePartTotals && this.selectedBillOfEntry.schedulePartTotals.length > 0 && !foundSc1p2aChargeType || !foundSc1p2bChargeType || !foundSc1p3dChargeType || !foundSc1p3eChargeType) {
            // Todo : refactor to be cleaner and handle more additional schedules beyond part 1's
            const sch1p2a = this.selectedBillOfEntry.schedulePartTotals.find(schedulePart => schedulePart.schedulePart.code === '12A');
            const sch1p2b = this.selectedBillOfEntry.schedulePartTotals.find(schedulePart => schedulePart.schedulePart.code === '12B');
            const sch1p3d = this.selectedBillOfEntry.schedulePartTotals.find(schedulePart => schedulePart.schedulePart.code === '13D');
            const sch1p3e = this.selectedBillOfEntry.schedulePartTotals.find(schedulePart => schedulePart.schedulePart.code === '13E');
            if (sch1p2a || sch1p2b || sch1p3d || sch1p3e && this.selectedBillOfEntry.customsProcedureCode !== 40) {
              this.invoiceSectionService.findInvoiceSubSectionsByInvoiceSectionId(this.invoiceSectionID, 'Customs - Schedule').subscribe(
                data => {
                  if (sch1p2a) {
                    this.addChargeTypeFromBOE('SC1P2A', defaultTaxType, defaultInvoiceSection, data.find(obj => obj.description.includes('2A')).description, defaultTranType, sch1p2a.totalValue, 0);
                    console.log('sch1p2a:', sch1p2a.totalValue);
                  }
                  if (sch1p2b) {
                    this.addChargeTypeFromBOE('SC1P2B', defaultTaxType, defaultInvoiceSection, data.find(obj => obj.description.includes('2B')).description, defaultTranType, sch1p2b.totalValue, 0);
                    console.log('sch1p2b:', sch1p2b.totalValue);
                  }
                  if (sch1p3d) {
                    this.addChargeTypeFromBOE('SC1P3D', defaultTaxType, defaultInvoiceSection, data.find(obj => obj.description.includes('3D')).description, defaultTranType, sch1p3d.totalValue, 0);
                    console.log('sch1p3d:', sch1p3d.totalValue);
                  }
                  if (sch1p3e) {
                    this.addChargeTypeFromBOE('SC1P3E', defaultTaxType, defaultInvoiceSection, data.find(obj => obj.description.includes('3E')).description, defaultTranType, sch1p3e.totalValue, 0);
                    console.log('sch1p3e:', sch1p3e.totalValue);
                  }
                });
            }
          }
        }
      }
    );
  }

  private addChargeTypeFromBOE(code: string, defaultTaxType: TaxType, defaultInvoiceSection: InvoiceSection,
                               defaultInvoiceSubSection: string, defaultTranType: TranType, value: number, tax: number) {
    this.chargeTypeSubscription = this.chargeTypeService.findChargeTypesByCodeStartWith(code).subscribe(
      (chargeTypes: ChargeType[]) => {
        const line = new SupplierInvoiceLine();
        line.taxType = defaultTaxType;
        line.invoiceSection = defaultInvoiceSection;
        line.invoiceSubSection = defaultInvoiceSubSection;
        line.tranType = defaultTranType;
        line.chargeType = chargeTypes[0];

        line.localAmount = value;
        line.localTax = tax;
        if (this.invoice.lineDetails && this.invoice.lineDetails.length) {
          this.invoice.lineDetails.splice(0, 0, line);
        } else {
          this.invoice.lineDetails = [];
          this.invoice.lineDetails.push(line);
        }
      }
    );
  }

  private consolidate(invoiceSubSectionDesc: string, code: string) {
    const line: SupplierInvoiceLine = new SupplierInvoiceLine();
    this.invoice.lineDetails
      .forEach(
        l => {
          if (l.invoiceSubSection === invoiceSubSectionDesc && l.taxType.sarsTaxCode === code) {
            line.invoiceSection = l.invoiceSection;
            line.invoiceSubSection = l.invoiceSubSection;
            line.localAmount = line.localAmount ? Number(line.localAmount) + Number(l.localAmount) : Number(l.localAmount);
            line.foreignAmount = line.foreignAmount ? Number(line.foreignAmount) + Number(l.foreignAmount) : Number(l.foreignAmount);
            line.localTax = line.localTax ? Number(line.localTax) + Number(l.localTax) : Number(l.localTax);
            line.taxType = l.taxType;
          }
        }
      );
    return line;
  }

  calculateLocalTax(taxType: TaxType, localAmount: number) {
    if (taxType.code.trim() === 'VAT') {
      return (localAmount * this.vat).toFixed(2);
    } else {
      return 0;
    }
  }

  getRounded(value) {
    if (value) {
      if (typeof value === 'string') {
        return Number(value).toFixed(2);
      } else {
        return value.toFixed(2);
      }
    } else {
      return '0.00';
    }

  }

  getForeignRounded(value) {
      if (value) {
        if (typeof value === 'string') {
          return Number(value).toFixed(2);
        } else {
          return value.toFixed(2);
        }
      } else {
        return '0.00';
      }

    }

  private validateInvoice(): string[] {
    const errors = [];
    this.invoice.lineDetails.forEach((lineDetail, index) => {
      if (!lineDetail.invoiceSection || (lineDetail.invoiceSection && !lineDetail.invoiceSection.description)) {
       errors.push(`No Invoice Section on line ${index + 1}`);
      }
    });
    return errors;
  }

  private getFormattedAddress(address: Address): string {
    let formattedAddress = '';
    if (address) {
      formattedAddress = Object.values(address).filter(el => el != null).join(', ');
    }
    return formattedAddress;
  }
}
