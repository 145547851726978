import {Inject, Injectable} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {Address, ClearingInstruction, Guarantor, Remover} from '../models/file';
import {EMPTY, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuarantorService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG) private config: AppConfig) { }

  findGuarantorNameOrCodeStartsWith(code) {
    return this.http
      .get(`${this.config.filesApiEndpoint}api/businessEntities/search/byNameOrCodeAndOnlyRoleType?nameOrCode=${code}&roleType=30&projection=flattenedGuarantor`)
      .pipe(
        map(response => response['_embedded'].businessEntities)
      );
  }

  private returnDefault(clearingInstruction: ClearingInstruction) {
    const guarantor = new Guarantor();
    guarantor.address = new Address();
    if (clearingInstruction) {
      clearingInstruction.guarantor = guarantor;
    }
    return of([guarantor]);
  }

  findGuarantorFromClearingFile(clearingInstruction: ClearingInstruction, guarantors: Guarantor, updateGuarantor: boolean = true) {
    if (clearingInstruction) {
      return this.findGuarantorNameOrCodeStartsWith(guarantors.code).pipe(
        tap(guarantorData => {
          if (updateGuarantor) {
            clearingInstruction.guarantor = new Guarantor();
            clearingInstruction.guarantor.name = guarantors.name;
            clearingInstruction.guarantor.code = guarantors.code;
            clearingInstruction.guarantor.label = guarantors.label;
           // clearingInstruction.guarantor.guaranteeType = guarantorData[0].guaranteeType;
          }
          if (guarantorData && (!clearingInstruction.guarantor.address
            || !clearingInstruction.guarantor.address.line1)) {
            const guarantorAddresses = guarantorData[0].addresses;
            let guarantorAddress;
            if (guarantorAddresses.length === 1) {
              guarantorAddress = guarantorAddresses[0];
            } else {
              guarantorAddress = guarantorAddresses.find(address => {
                const addressType = address.addressTypes.find(addressType => {
                  return addressType.id === 'CUS';
                });
                if (addressType) {
                  return true;
                } else {
                  return false;
                }
              });
            }

            if (guarantorAddress) {
              clearingInstruction.guarantor.address = new Address();
              clearingInstruction.guarantor.address.line1 = guarantorAddress.line1;
              clearingInstruction.guarantor.address.line2 = guarantorAddress.line2;
              clearingInstruction.guarantor.address.line3 = guarantorAddress.line3;
              clearingInstruction.guarantor.address.line4 = guarantorAddress.line4;
              clearingInstruction.guarantor.address.zipCode = guarantorAddress.zipCode;
            }
          }

        }));
    } else {
      return this.returnDefault(clearingInstruction);
    }
  }
}
