<div class="ui-g-1">
  <p-dialog header="Generated Documents" [(visible)]="display" [modal]="true"
            [responsive]="true" [width]="600"
            [minWidth]="200" [minY]="70"
            [maximizable]="true" [baseZIndex]="10000">
    <ng-template pTemplate="content" [ngIf]="generatedFiles">
      <li *ngFor="let generatedDocument of generatedFiles">
        <a class="uploaded-file-color"
           [href]="generatedDocument._links.self.href" target="_blank">{{generatedDocument.fileName}}</a>
      </li>
    </ng-template>
    <p-footer>
      <button type="button" pButton icon="pi pi-close" (click)="display=false"
              label="Close"
              class="ui-button-secondary"></button>
    </p-footer>
  </p-dialog>
</div>

<div class="ui-g">
  <div class="ui-g-12">
    <p-toolbar>
      <div class="ui-toolbar-group-left">
        <div class="ui-g-6 ui-md-6">
          <div class="ui-inputgroup">

        <p-autoComplete [suggestions]="filteredCreditorEntities"
                        [ngModel]="creditorParentEntity"
                        field="label"
                        (onSelect)="onSelectCreditorEntity($event)" [minLength]="3"
                        (completeMethod)="search($event)"
                        [forceSelection]="true"
                        [autoHighlight]="true"
                        [inputStyle]="{color:'white'}"
                        placeholder="Creditor Code/Name">
                        required = true>
          <ng-template let-option pTemplate="item">
            <div>{{option.code}}:{{option.name}}</div>
          </ng-template>
        </p-autoComplete>

          </div>
        </div>
        <div class="ui-g-6 ui-md-6">
          <div class="ui-inputgroup">
            <p-autoComplete [suggestions]="filteredCreditorEntities"
                            field="label"
                            (onSelect)="onSelectCreditorEntity($event)" [minLength]="3"
                            (completeMethod)="searchDocumentNumber($event)"
                            [forceSelection]="false"
                            [autoHighlight]="true"
                            [inputStyle]="{color:'white'}"
                            placeholder="Document Number">
                            required = true>
              <ng-template let-option pTemplate="item">
                <div>{{option.code}}:{{selectedDocumentNumber}}</div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

      </div>
      <div class="ui-toolbar-group-right" style="color: black;">
        <button pButton type="button" (click)="newFinancialDetails()" label="New" icon="fa fa-file">
        </button>
        <button pButton type="button" (click)="saveCreditorEntity()" label="Save" icon="fa fa-save">
        </button>
      </div>
    </p-toolbar>

    <div *ngIf="disableButton" class="ui-g-12">
      <p-progressBar mode="indeterminate" [style]="{'height': '10px'}"></p-progressBar>
    </div>

<p-tabView orientation="left" styleClass="stepMenu">
  <p-tabPanel header="Creditor Invoices" [selected]="true">

<ng-template pTemplate="content">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g">

        <div class="ui-g-3">
          <p-panel header="Creditor Details">
            <div class="ui-g ui-fluid">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.creditorNumber" pInputText>
              <label>Creditor Number</label>
            </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12" style="color: black;">
                  <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" value="{{creditorParentEntity?.name}}" pInputText readonly>
                    <label>Creditor</label>
                  </span>
                  </div>

                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.extCreditorCode" pInputText>
                <label>Ext Creditor Code</label>
              </span>
                  </div>
                </div>

                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.userCode" pInputText>
                    <label>User Code</label>
                  </span>
                  </div>
                </div>

                <div class="ui-g-12 ui-md-12" style="color: black;">
                  <digi-customs-office [customOffice]="selectedCreditorFinancialDetail.customOffice"
                                       [isRequired]=false (onSelectedCustomOffice)="updateCustomsOffice($event)"></digi-customs-office>
                </div>

              </div>
            </div>
          </p-panel>
        </div>

        <div class="ui-g-6">
          <p-panel header="Invoice Details">
            <div class="ui-g form-group">
              <div class="ui-g-6 ui-md-6">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.documentNumber" pInputText>
                    <label>Document Number</label>
                  </span>
                </div>
              </div>
              <div class="ui-g-12 ui-md-6">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.paymentReference" pInputText>
                    <label>Payment Ref</label>
                  </span>
                </div>
              </div>
              <div class="ui-g-6 ui-md-6">
                <div class="ui-inputgroup">
                  <span class="ui-float-label">
              <p-inputMask id="documentDate" mask="99/99/9999" slotChar="dd/mm/yyyy" [(ngModel)]="selectedCreditorFinancialDetail.documentDate">
              </p-inputMask>
              <label for="documentDate">Document Date</label>
            </span>
                </div>
              </div>
              <div class="ui-g-6 ui-md-6">
                <div class="ui-inputgroup">
                  <span class="ui-float-label">
              <p-inputMask id="financialDate" mask="99/99/9999" slotChar="dd/mm/yyyy" [(ngModel)]="selectedCreditorFinancialDetail.financialDate">
              </p-inputMask>
              <label for="financialDate">Financial Date</label>
            </span>
                </div>
              </div>
              <div class="ui-g-6 ui-md-6">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.totalInvoiceValue" (change)="updateBalanceTotal()" pInputText>
                    <label>Total Invoice</label>
                  </span>
                </div>
              </div>
              <div class="ui-g-6 ui-md-6">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.homeValue" pInputText>
                    <label>Home Value</label>
                  </span>
                </div>
              </div>
              <div class="ui-g-6 ui-md-6">
                <div class="ui-inputgroup">
                  <span class="md-inputfield">
                    <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.documentStatus" pInputText>
                    <label>Document Status</label>
                  </span>
                </div>
              </div>
              <div class="ui-g-6 ui-md-6">
                <div class="ui-inputgroup">
                  <span class="ui-float-label">
              <p-inputMask id="confirmDate" mask="99/99/9999" slotChar="dd/mm/yyyy" [(ngModel)]="selectedCreditorFinancialDetail.confirmDate">
              </p-inputMask>
              <label for="confirmDate">Confirm Date</label>
            </span>
                </div>
              </div>
              <div class="ui-g-6 ui-md-6">
                <span class="md-inputfield">
                          <p-autoComplete [(ngModel)]="selectedCreditorFinancialDetail.defaultTaxType"
                                          [dropdown]="true"
                                          field="label"
                                          [suggestions]="filteredTaxTypes"
                                          (completeMethod)="searchTaxTypeCode($event)"
                                          [autoHighlight]="true"
                                          (onSelect)="selectedCreditorFinancialDetail.defaultTaxType=$event"
                                          minLength="0"
                                          required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                          <label>Default Tax Type</label>
                        </span>
              </div>
              <div class="ui-g-6 ui-g-nopad" style="padding-left: 5px;">
                <digi-currency [currency]="selectedCreditorFinancialDetail.currency"
                               (onSelect)="selectCurrency($event)"
                               [required]=false></digi-currency>
              </div>
              <div class="ui-g-12 ui-md-12">
              </div>
            </div>
          </p-panel>
        </div>

        <div class="ui-g-3">
          <p-panel header="Balance Details">
            <div class="ui-g ui-fluid">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input type="text" [ngModel]="selectedCreditorFinancialDetail.balanceTotalValue" pInputText readonly>
                  <label>Total</label>
                </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.balanceCapturedValue" pInputText readonly>
                  <label>Captured</label>
                </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                <span class="md-inputfield">
                  <input type="text" [(ngModel)]="selectedCreditorFinancialDetail.balanceCurrentValue" pInputText readonly>
                  <label>Balance</label>
                </span>
                  </div>
                </div>
              </div>
                  <div class="ui-g-12">
                    <div class="ui-g-12">
                      <button pButton type="button" label="Confirm Creditors Invoice"
                              [disabled]="disableButton || !isBalanced() || isConfirmed()"
                              (click)="confirm()"></button>
                    </div>

                    <div class="ui-g-12">
                      <button pButton type="button" label="Print Requisition"
                              [disabled]="disableButton || !isConfirmed()"
                              (click)="generatePaymentRequisitionReport()"></button>
                    </div>
                    <div class="ui-g-12">
                      <button pButton type="button" label="Print Invoice"
                              [disabled]="disableButton || !isConfirmed()"
                              (click)="generateCreditorsInvoiceReport()"></button>
                    </div>
                  </div>
            </div>
          </p-panel>
        </div>

        <div class="ui-g-6">

            <p-table [columns]="cols"
                     [value]="selectedCreditorFinancialDetail.creditorChargeTypeDetails"
                     selectionMode="single"
                     [(selection)]="selectedChargeTypeDetails"
                     (onRowSelect)="selectChargeTypeDetails($event)"
                     [paginator]="true"
                     [rows]="5">
              <ng-template pTemplate="caption">
                Charge Types
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
                    {{col.header}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <td>
                    {{rowData.invoiceNumber}}
                  </td>
                  <td>
                    {{rowData.chargeType?.code}}
                  </td>
                  <td>
                    {{rowData.chargeAmount}}
                  </td>
                  <td>
                    {{rowData.sourceAmount}}
                  </td>
                  <td>
                    {{rowData.localTax}}
                  </td>
                  <td>
                    <button pButton type="button" icon="fa fa-times" class="danger"
                            (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
        </div>

        <div class="ui-g-1">
          <div class="ui-g-12">
            <button pButton type="button" icon="fa fa-walking" pTooltip="New Line"
                    tooltipPosition="top"
                    class="ui-button-secondary col-sm-12"
                    (click)="addNewChargeTypeDetails()" icon="fa fa-plus"></button>
          </div>
          <div class="ui-g-12">
            <button pButton type="button" icon="fa fa-walking" pTooltip="Search Invoice"
                    tooltipPosition="top"
                    class="ui-button-secondary col-sm-12"
                    (click)="loadFromInvoice()" icon="fa fa-search"></button>
          </div>
        </div>

        <div *ngIf="selectedChargeTypeDetails" class="ui-g-12 ui-md-5">
          <p-panel header="Line Details">
            <div class="ui-g ui-fluid">
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <span class="md-inputfield">
                    <div class="ui-inputgroup">
                      <span class="ui-float-label ui-fluid">
                        <p-dropdown [options]="fileTypeOptions"
                                    [(ngModel)]="searchFileType"
                                    [autoWidth]="true" [style]="{'width':'100%','height':'25px'}">
                        </p-dropdown>
                        <label>Search File Type</label>
                      </span>
                    </div>
                    </span>
                  </div>
                  <div class="ui-g-12 ui-md-6">
                    <div class="ui-inputgroup">
                      <p-checkbox [(ngModel)]="waybillSearch" label="MAWB/BOL Search?"
                                  binary="true" [disabled]="!searchFileType || searchFileType === '' || searchFileType === 'exbond'"></p-checkbox>
                    </div>
                  </div>
                  <div class="ui-g-12 ui-md-1">
                  </div>
                  <div class="ui-g-6 ui-md-6">
                    <div *ngIf="!waybillSearch" class="ui-inputgroup">
                      <p-autoComplete id="clearingFileNumber" [(ngModel)]="searchedFile" field="clearingFileNumber"
                                      [disabled]="!searchFileType || searchFileType === ''"
                                      [suggestions]="filteredSearchFiles"
                                      (completeMethod)="searchFile($event)"
                                      [autoHighlight]="true"
                                      minLength="2"
                                      delay="600"
                                      placeholder="Search File No.">
                        <ng-template let-option pTemplate="item">
                          <div>{{option.clearingFileNumber}}</div>
                        </ng-template>
                      </p-autoComplete>
                    </div>
                    <div *ngIf="waybillSearch"class="ui-inputgroup">
                      <span class="md-inputfield">
                        <input type="text" [(ngModel)]="searchedMawbRef" (input)="searchWaybillReference()"
                               [disabled]="!searchFileType || searchFileType === ''"pInputText>
                        <label>MAWB/BOL Search</label>
                      </span>
                    </div>
                  </div>
                  <div class="ui-g-6 ui-md-6">
                    <div class="ui-inputgroup">
                    <span class="md-inputfield">
                      <input type="text" [(ngModel)]="selectedChargeTypeDetails.mawbNo" [disabled]="true" pInputText>
                      <label>MAWB/BOL Reference</label>
                    </span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-6 ui-md-6">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                      <input type="text" [(ngModel)]="selectedChargeTypeDetails.fileNumber" pInputText>
                      <label>File Number</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-6 ui-md-6">
                  <span class="md-inputfield">
                    <div class="ui-inputgroup">
                      <span class="ui-float-label ui-fluid">
                                  <p-autoComplete id="creditorDetailsChargeType"
                                                  [(ngModel)]="selectedChargeTypeDetails.chargeType"
                                                  field="label"
                                                  [suggestions]="filteredChargeTypes"
                                                  (completeMethod)="searchChargeType($event)"
                                                  [dropdown]="true"
                                                  [autoHighlight]="true">
                                  </p-autoComplete>
                              <label for="creditorDetailsChargeType">Charge Type</label>
                            </span>

                    </div>
                  </span>
                </div>
                <div class="ui-g-6 ui-md-6">
                        <span class="md-inputfield">
                    <span class="ui-inputgroup">
                      <span class="ui-float-label ui-fluid">
                            <p-autoComplete id="tranType"
                                            [(ngModel)]="selectedChargeTypeDetails.tranType"
                                            field="label"
                                            [suggestions]="filteredTranTypes"
                                            (completeMethod)="searchTranType($event)"
                                            [dropdown]="true"
                                            [autoHighlight]="true">
                            </p-autoComplete>
                        <label for="tranType">Tran. Type</label>
                      </span>
                    </span>
                  </span>
                </div>
                <div class="ui-g-6 ui-md-6">
                  <span class="md-inputfield">
                    <span class="ui-inputgroup">
                      <span class="ui-float-label ui-fluid">
                            <p-autoComplete id="taxType"
                                            [(ngModel)]="selectedChargeTypeDetails.taxType"
                                            field="label"
                                            [suggestions]="filteredTaxTypes"
                                            (completeMethod)="searchTaxTypeCode($event)"
                                            [dropdown]="true"
                                            [autoHighlight]="true">
                            </p-autoComplete>
                        <label for="taxType">Tax Type</label>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div class="ui-g form-group">
                <div class="ui-g-6 ui-md-6">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                      <input type="text" [(ngModel)]="selectedChargeTypeDetails.sourceAmount" (ngModelChange)="calculateBalanceAmounts()" pInputText>
                      <label>Source Amount</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-6">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                      <input type="text" [(ngModel)]="selectedChargeTypeDetails.sourceTax" (ngModelChange)="calculateBalanceAmounts()" pInputText>
                      <label>Source Tax</label>
                    </span>
                  </div>
                </div>

                <div class="ui-g-6 ui-md-6">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                      <input type="text" [(ngModel)]="selectedChargeTypeDetails.localAmount" [disabled]="true" pInputText>
                      <label>Invoice Amount</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-6">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                      <input type="text" [(ngModel)]="selectedChargeTypeDetails.localTax" [disabled]="true" pInputText>
                      <label>Invoice Tax</label>
                    </span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <button pButton type="button" (click)="saveCreditorEntity()" label="Save" icon="fa fa-save">
                  </button>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
      </div>
    </div>
  </div>
</ng-template>
  </p-tabPanel>
</p-tabView>
  </div>
</div>
