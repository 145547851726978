import { OnInit } from '@angular/core';
import { ExBondFileBehaviourSubject } from '../../../subjects/exbondfile-behaviour-subject';
import { BillOfEntry } from '../../digi-common/models/billOfEntries';
import { BillOfEntryService } from '../../digi-common/services/bill-of-entry.service';
import { PreviousBoeDetails, TariffCode, TradeAgreement, CustomOffice } from '../../digi-common/models/file';
import { PackTypeService } from '../../digi-common/services/pack-type.service';
import { TariffService } from '../../digi-common/services/tariff.service';
import { Formula } from '../../digi-common/models/TariffCode';
import { TariffCodeFormulaeService } from '../../digi-common/services/tariffcode-formulae.service';
var ExbondLinesComponent = /** @class */ (function () {
    function ExbondLinesComponent(exBondFileBehaviourSubject, billOfEntryService, packTypeService, tariffService, tariffCodeFormulaeService) {
        this.exBondFileBehaviourSubject = exBondFileBehaviourSubject;
        this.billOfEntryService = billOfEntryService;
        this.packTypeService = packTypeService;
        this.tariffService = tariffService;
        this.tariffCodeFormulaeService = tariffCodeFormulaeService;
        this.billOfEntry = new BillOfEntry();
        this.tariffCode = new TariffCode();
    }
    ExbondLinesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.uomCols = [
            {
                header: 'UOM Code',
            },
            {
                'header': 'value'
            }
        ];
        this.cols = [
            {
                header: 'Line No'
            },
            {
                header: 'Part Number'
            },
            {
                header: 'Line Value'
            },
            {
                header: 'Quantity'
            }
        ];
        this.boeLinesCols = [
            {
                header: 'Line No',
                width: '5%'
            },
            {
                header: 'MRN',
                width: '15%'
            },
            {
                header: 'CPC',
                width: '5%'
            },
            {
                header: 'Seq. No.',
                width: '4%'
            },
            {
                header: 'Customs Date',
                width: '10%'
            },
            {
                header: 'Part Number',
                width: '10%'
            },
            {
                header: 'Available Packs',
                width: '8%'
            },
            {
                header: 'Selected Packs',
                width: '8%'
            },
            {
                header: 'Pack Type',
                width: '10%'
            },
            {
                header: 'Value',
                width: '10%'
            },
            {
                header: 'Qty',
                width: '10%'
            }
        ];
        this.exBondFileSubscription = this.exBondFileBehaviourSubject.getExBondFile().subscribe(function (ef) {
            if (ef) {
                _this.exBondFile = ef;
                if (_this.exBondFile.lines && _this.exBondFile.lines.length) {
                    _this.selectLine(_this.exBondFile.lines[0]);
                }
            }
        });
    };
    ExbondLinesComponent.prototype.createNewFile = function () {
        this.showDialog = true;
        this.search();
    };
    ExbondLinesComponent.prototype.selectLine = function (value) {
        this.selectedLine = value;
        // @ts-ignore
        this.additionalScheduleTariffs = this.selectedLine.additionalScheduleTariffs;
    };
    ExbondLinesComponent.prototype.onRowDelete = function (rowIndex) {
        this.exBondFile.lines.splice(rowIndex, 1);
        this.exBondFile.lines = this.exBondFile.lines.slice();
    };
    ExbondLinesComponent.prototype.onClose = function () {
        this.showDialog = false;
    };
    ExbondLinesComponent.prototype.search = function () {
        var _this = this;
        this.deselectBoes();
        this.lines = [];
        // Subscribe to either 40 or 42
        [40, 42].forEach(function (code) {
            _this.billOfEntryService.findByCustomsProcedureCodeAndMrn(code, _this.mrnFilter).subscribe(function (boes) {
                boes.forEach(function (boe) {
                    var latestSequence = 0;
                    boes.forEach(function (b) {
                        if (b.mrn === boe.mrn && b.sequenceNumber > latestSequence) {
                            latestSequence = b.sequenceNumber;
                        }
                    });
                    if (boe.sequenceNumber === latestSequence) {
                        var lines = boe.lines.filter(function (l) { return !_this.lineFilter || l.lineNo === parseInt(_this.lineFilter); }).map(function (line) { return Object.assign({}, line); });
                        lines.forEach(function (line) {
                            var previousBoEDetails = new PreviousBoeDetails();
                            var noOfPacksAtWarehouse;
                            if (line.warehouseCaptureDetails) {
                                noOfPacksAtWarehouse = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
                            }
                            previousBoEDetails.cpc = boe.customsPurposeCode;
                            previousBoEDetails.sequenceNo = boe.sequenceNumber;
                            previousBoEDetails.customsBOENo = boe.mrn;
                            previousBoEDetails.date = boe.releaseDate;
                            previousBoEDetails.customOffice = new CustomOffice();
                            previousBoEDetails.customOffice.districtOfficeCode = boe.districtOfficeCode;
                            previousBoEDetails.lineNo = line.lineNo;
                            previousBoEDetails.customsValue = line.customsValue;
                            previousBoEDetails.customsDuty = line.customsDuty;
                            previousBoEDetails.customsVAT = line.customsVAT;
                            previousBoEDetails.localCustomsValue = line.localCustomsValue;
                            previousBoEDetails.invoiceGrossValue = line.invoiceGrossValue;
                            previousBoEDetails.localInvoiceGrossValue = line.localInvoiceGrossValue;
                            previousBoEDetails.actualValue = line.actualValue;
                            previousBoEDetails.noOfPacks = line.noOfPacks;
                            if (line.warehouseCaptureDetails) {
                                if (noOfPacksAtWarehouse > 0) {
                                    line.noOfPacks = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
                                    previousBoEDetails.remainingPacks = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
                                }
                                else {
                                    line.noOfPacks = 0;
                                    previousBoEDetails.remainingPacks = 0;
                                }
                            }
                            else {
                                previousBoEDetails.remainingPacks = line.noOfPacks;
                            }
                            previousBoEDetails.lineQuantity = line.lineQuantity;
                            previousBoEDetails.lineWeight = line.lineWeight;
                            previousBoEDetails.additionalScheduleTariffs = line.additionalScheduleTariffs;
                            previousBoEDetails.sequenceNumber = boe.sequenceNumber;
                            line.previousBoeDetails = Object.assign({}, previousBoEDetails);
                            line.type = 'ExBondLine';
                            _this.recalculateValues(line);
                        });
                        (_a = _this.lines).push.apply(_a, lines);
                        _this.lines = _this.lines.slice();
                    }
                    var _a;
                });
            });
        });
    };
    ExbondLinesComponent.prototype.deselectBoes = function () {
        this.selectedLines = [];
    };
    ExbondLinesComponent.prototype.createExbondLines = function () {
        if (this.exBondFile.lines) {
            (_a = this.exBondFile.lines).push.apply(_a, this.selectedLines);
        }
        else {
            this.exBondFile.lines = this.selectedLines.slice();
        }
        this.exBondFile.lines.forEach(function (value, index) {
            value.lineNo = index + 1;
        });
        if (this.exBondFile.lines.length) {
            this.selectLine(this.exBondFile.lines[0]);
        }
        this.showDialog = false;
        var _a;
    };
    ExbondLinesComponent.prototype.selectCountry = function (value) {
        this.selectedLine.countryOfOrigin = value;
    };
    ExbondLinesComponent.prototype.searchTradeAgreement = function (event) {
        this.filteredTradeAgreements = [];
        this.selectedLine.countryOfOrigin.tradeAgreements.filter(function (v) { return v.code.toLowerCase().indexOf(event) > -1; }).slice(0, 10);
        this.filteredTradeAgreements = this.selectedLine.countryOfOrigin.tradeAgreements.slice();
    };
    ExbondLinesComponent.prototype.selectTradeAgreement = function (value) {
        this.selectedLine.tradeAgreement.code = value.code;
        this.selectedLine.tradeAgreement.description = value.description;
    };
    ExbondLinesComponent.prototype.searchPackTypes = function (event) {
        var _this = this;
        this.packTypeService.findByCodeOrDescription(event.query).subscribe(function (data) { return _this.filteredPackTypes = data; });
    };
    ExbondLinesComponent.prototype.selectPackType = function (value) {
        this.selectedLine.packType = value;
    };
    ExbondLinesComponent.prototype.searchTariffCode = function (event) {
        var _this = this;
        this.tariffService
            .findTariffByCodeStartsWith(event.query)
            .subscribe(function (data) {
            _this.filteredTariffCodes = data;
        });
    };
    ExbondLinesComponent.prototype.selectTariffCode = function (value) {
        this.selectedLine.partNumber = value.code;
        this.selectedLine.description = value.tariffSDesc;
        this.selectedLine.fullDescription = value.fullDescription;
        this.selectedLine.tariffCode.tariffID = value.tariffID;
        this.tariffCode = new TariffCode();
        this.tariffCode.code = value.code;
        var formula = value.formulae.find(function (f) {
            return !!f.uomCode;
        });
        if (formula) {
            this.selectedLine.tariffCode.formula = new Formula();
            this.selectedLine.tariffCode.formula.statisticalUOM = formula.uomCode;
        }
        this.updatedTariff(value);
    };
    ExbondLinesComponent.prototype.updatedTariff = function (value) {
        var _this = this;
        if (value) {
            var effectiveFormulae = value.effectiveTradeAgreementFormulae;
            this.tariffCodeFormulaeService.effectiveTradeAgreementFormulae(effectiveFormulae, this.selectedLine);
            var effectiveParameters = value.effectiveTradeAgreementParameters;
            this.tariffCodeFormulaeService.effectiveTradeAgreementParameters(effectiveParameters, this.selectedLine);
            this.selectedLine.tariffCode.description = value.tariffSDesc;
            this.selectedLine.tariffCode.checkDigit = value.tariffCheckDigit;
            this.selectedLine.tariffCode.code = value.code;
            this.selectedLine.tariffCode.fullDescription = value.fullDescription;
            if (value.additionalUoms && value.additionalUoms.length > 0) {
                value.additionalUoms.forEach(function (uom) {
                    if (_this.selectedLine.additionalUoms) {
                        var foundUom = _this.selectedLine.additionalUoms.find(function (el) { return el.uomCode === uom.uomCode; });
                        if (!foundUom) {
                            _this.selectedLine.additionalUoms.push({ uomCode: uom.uomCode });
                        }
                    }
                    else {
                        _this.selectedLine.additionalUoms = [];
                        _this.selectedLine.additionalUoms.push({ uomCode: uom.uomCode });
                    }
                });
            }
            else {
                this.selectedLine.additionalUoms = [];
            }
            if (!this.selectedLine.fullDescription) {
                this.selectedLine.fullDescription = value.fullDescription;
            }
        }
    };
    ExbondLinesComponent.prototype.recalculateValues = function (rowData) {
        var ratio = 1;
        if (rowData.noOfPacks !== undefined) {
            ratio = rowData.noOfPacks / rowData.previousBoeDetails.noOfPacks;
        }
        rowData.customsValue = Math.round((rowData.previousBoeDetails.customsValue * ratio) * 100) / 100;
        rowData.localCustomsValue = Math.round(rowData.previousBoeDetails.localCustomsValue * ratio);
        rowData.actualValue = Math.round((rowData.previousBoeDetails.actualValue * ratio) * 100) / 100;
        rowData.lineQuantity = Math.round((rowData.previousBoeDetails.lineQuantity * ratio) * 100) / 100;
        rowData.lineWeight = Math.round((rowData.previousBoeDetails.lineWeight * ratio) * 100) / 100;
        rowData.localInvoiceGrossValue = Math.round((rowData.previousBoeDetails.localInvoiceGrossValue * ratio) * 100) / 100;
        rowData.invoiceGrossValue = Math.round((rowData.previousBoeDetails.invoiceGrossValue * ratio) * 100) / 100;
        if (rowData.previousBoeDetails) {
            var schedules_1 = [];
            rowData.previousBoeDetails.additionalScheduleTariffs.forEach(function (schedule) {
                var newSchedule = Object.assign({}, schedule);
                newSchedule.calculatedValue = Math.round((schedule.calculatedValue * ratio) * 100) / 100;
                schedules_1.push(newSchedule);
            });
            rowData.additionalScheduleTariffs = schedules_1;
        }
    };
    ExbondLinesComponent.prototype.updateAdditionalSchedules = function (schedules) {
        // @ts-ignore
        this.selectedLine.additionalScheduleTariffs = schedules;
    };
    return ExbondLinesComponent;
}());
export { ExbondLinesComponent };
