import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrackingDetails} from '../../models/ForwardingFile';
import {SelectItem} from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'digi-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  @Input('display') display;
  @Input('isHouseBill') isHouseBill;
  @Input('displayTrackingProgressBar') displayTrackingProgressBar;
  @Input('currentStatus') currentStatus;
  @Output() displayChange = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() sendParcelUpdate = new EventEmitter();
  @Output() updateHAWBsArrivedAtJNBOPSFields = new EventEmitter();
  @Output() updateHAWBsReceivedAtAOTIAFields = new EventEmitter();
  @Input('trackingDetails') trackingDetails: TrackingDetails;
  commentStatusList: SelectItem[];
  isPoBoxChecked: boolean;
  isImporterCodeChecked: boolean;
  isDutyConfChecked: boolean;
  isCustomsStopChecked: boolean;
  isOagStopChecked: boolean;
  isMissingDocsChecked: boolean;
  isGeneralQueryChecked: boolean;

  constructor() {
  }

  ngOnInit() {
    this.isPoBoxChecked = this.trackingDetails.statusPoBoxTimestamp? true : false;
    this.isImporterCodeChecked = this.trackingDetails.statusImporterCodeTimestamp? true : false;
    this.isDutyConfChecked = this.trackingDetails.statusDutyConfTimestamp? true : false;
    this.isCustomsStopChecked = this.trackingDetails.statusCustomsStopTimestamp? true : false;
    this.isOagStopChecked = this.trackingDetails.statusOagStopTimestamp? true : false;
    this.isMissingDocsChecked = this.trackingDetails.statusMissingDocsTimestamp? true : false;
    this.isGeneralQueryChecked = this.trackingDetails.statusGeneralQueryTimestamp? true : false;
  }

  onSave() {
    this.save.emit(true);
  }

  onClose() {
    this.displayChange.emit(false);
  }

  onUpdateHAWBSArrivedATORTIA() {
    this.updateHAWBsReceivedAtAOTIAFields.emit(true);
  }

  onUpdateHAWBsArrivedAtJNBOPSFields() {
    this.updateHAWBsArrivedAtJNBOPSFields.emit(true);
  }

  onSendParcelUpdate() {
    this.sendParcelUpdate.emit(this.getNumericStatus());
  }

  onSendParcelUpdateDisabled() {
    return (this.getNumericStatus() !== 3) ? true : false;
  }

  setStatusCommentTime(event, statusComment: string) {
    if (event === true) {
      switch (statusComment) {
        case 'statusPoBoxTimestamp':
          this.trackingDetails.statusPoBoxTimestamp = moment().format('DD/MM/YYYY HH:mm');
          this.isPoBoxChecked = true;
          break;
        case 'statusImporterCodeTimestamp':
          this.trackingDetails.statusImporterCodeTimestamp = moment().format('DD/MM/YYYY HH:mm');
          this.isImporterCodeChecked = true;
          break;
        case 'statusDutyConfTimestamp':
          this.trackingDetails.statusDutyConfTimestamp = moment().format('DD/MM/YYYY HH:mm');
          this.isDutyConfChecked = true;
          break;
        case 'statusCustomsStopTimestamp':
          this.trackingDetails.statusCustomsStopTimestamp = moment().format('DD/MM/YYYY HH:mm');
          this.isCustomsStopChecked = true;
          break;
        case 'statusOagStopTimestamp':
          this.trackingDetails.statusOagStopTimestamp = moment().format('DD/MM/YYYY HH:mm');
          this.isOagStopChecked = true;
          break;
        case 'statusMissingDocsTimestamp':
          this.trackingDetails.statusMissingDocsTimestamp = moment().format('DD/MM/YYYY HH:mm');
          this.isMissingDocsChecked = true;
          break;
        case 'statusGeneralQueryTimestamp':
          this.trackingDetails.statusGeneralQueryTimestamp = moment().format('DD/MM/YYYY HH:mm');
          this.isGeneralQueryChecked = true;
          break;
      }
    }
  }

  getNumericStatus(): number {
    switch (this.currentStatus) {
      case 'Received At JNB OPS':
        return  3;
      case 'Received At Customs':
        return 2;
      case 'Sent To Customs':
        return 1;
    }
  }
}
