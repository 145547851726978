<p-panel header="References">
  <div class="ui-g ui-fluid">
    <div class="ui-g form-group">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="file.reference.referenceFileNo">
              <label>Reference File No</label>
            </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="file.reference.businessReference">
              <label>Business Reference</label>
            </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="file.reference.businessUnit">
              <label>Business Unit</label>
            </span>
        </div>
      </div>
      <div class="ui-g-12 ui-md-12">
        <div class="ui-inputgroup">
            <span class="md-inputfield">
              <input type="text" pInputText [(ngModel)]="file.reference.channel">
              <label>Channel Controller</label>
            </span>
        </div>
      </div>
    </div>
  </div>
</p-panel>
