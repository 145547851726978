<div class="container-fluid test-inner-border">
  <div class="border-bottom">
    <digi-exports-file-header-section step="transportDocument" *ngIf="type && type==='exports'" [canAdd]="true" (add)="addContainer()">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-12 ui-md-12"
             *ngIf="isSeaTransportDocument()">
          <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon">HBOL No</span>
            <input type="text" pInputText value="{{selectedTransportDocument.hawbNo}}" readonly>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="isRoadTransportDocument()">
          <span class="md-inputfield">
            <input type="text" pInputText readonly
                   value="{{clearingFile?.exporter?.code}} : {{clearingFile?.exporter?.name}}">
            <label style="color: white;">Exporter</label>
          </span>
        </div>
      </div>
    </digi-exports-file-header-section>
    <digi-forwarding-file-header-section step="manifests" *ngIf="type && type==='forwarding'" (add)="addContainer()">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon">HBOL No</span>
            <input type="text" pInputText value="{{selectedTransportDocument.hawbNo}}" readonly>
          </div>
        </div>
      </div>
    </digi-forwarding-file-header-section>
    <digi-clearing-file-section step="transportDocument" *ngIf="type && type==='imports'" [canAdd]="true" (add)="addContainer()">
      <div class="ui-g-12 ui-md-12">
        <div class="ui-g-12 ui-md-12"
             *ngIf="isSeaTransportDocument()">
          <div class="md-inputfield">
            <input type="text" pInputText value="{{selectedTransportDocument.hawbNo}}" readonly>
            <label style="color: white;">HBOL No</label>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12" *ngIf="isRoadTransportDocument()">
          <span class="md-inputfield">
            <input type="text" pInputText readonly
                   value="{{clearingFile?.importer?.code}} : {{clearingFile?.importer?.name}}">
            <label style="color: white;">Importer</label>
          </span>
        </div>
      </div>
    </digi-clearing-file-section>
  </div>
  <div class="ui-g-8 ui-md-6 ui-md-offset-6">
    <p-panel header="Total Weight & Quantity">
      <digi-running-total-section-container></digi-running-total-section-container>
    </p-panel>
  </div>
  <div class="ui-g-12 ui-fluid mt-2">
    <digi-container-section-table></digi-container-section-table>
  </div>
  <div class="ui-fluid">
    <div class="ui-g-12 ui-md-12" style="text-align:right">
      <div class="ui-inputgroup" style="float:right">
        <button pButton
                type="button"
                label="Add Container"
                (click)="addContainer()"></button>

      </div>
    </div>
    <ng-container *ngIf="selectedContainer">
      <div class="ui-g-12">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel header="General Container Details">
              <digi-container-type-section></digi-container-type-section>
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12" *ngIf="isSeaForwarding() && this.selectedContainer.containerType.description === 'Bulk' || isSeaTransportDocument() && this.selectedContainer.containerType.description === 'Bulk'">
                  <div class="ui-inputgroup">
                  <span class="ui-float-label">
                    <p-autoComplete id="cargoType" [(ngModel)]="selectedContainer.cargoType"
                                    field="label"
                                    [dropdown]="true"
                                    [suggestions]="filteredCargoTypes"
                                    (completeMethod)="searchCargoType($event)"
                                    (onSelect)="selectCargoType($event)"
                                    [autoHighlight]="true" minLength="1"
                                    required="true">
                      <ng-template let-option pTemplate="item">
                        <div>{{option.code}} :{{option.description}}</div>
                      </ng-template>
                    </p-autoComplete>
                  <label for="cargoType">Cargo Type</label>
                </span>
                  </div>
                </div>
              </div>
              <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-12" *ngIf="isSeaForwarding() || isRoadAndBulk()">
                  <p-checkbox name="nonIsoContainerNo"
                              value="nonIsoContainerNo"
                              [(ngModel)]="selectedContainer.nonIsoContainerNo"
                              label="Non - ISO Container Number"
                              binary="true"></p-checkbox>
                </div>
                <div class="ui-g-12 ui-md-12" *ngIf="isSeaForwarding() || isRoadAndBulk() || isRailTransportDocument()">
                <span class="md-inputfield">
                    <span class="md-inputfield">
                      <input type="text" pInputText [(ngModel)]="selectedContainer.containerNo">
                      <label>Container No.</label>
                    </span>
                    <div
                      *ngIf="(selectedContainer?.containerType?.description !== 'Break Bulk' && selectedContainer?.containerType?.description !== 'Bulk') && !selectedContainer?.containerNo"
                      class="ui-message ui-messages-error ui-corner-all">
                      This field is required
                    </div>
                </span>
                </div>
                <div class="ui-g-12 ui-md-12" *ngIf="isSeaForwarding() || isRoadAndBulk() || isRailTransportDocument()">
                <span class="md-inputfield">
                  <input type="text"
                         pInputText
                         [(ngModel)]="selectedContainer.serialNo">
                  <label>Seal No.</label>
                </span>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="General Weight and Measures">
              <digi-hawb-weight-measures-section
                *ngIf="isSeaForwarding() || isSeaTransportDocument()"
                view="containers" label="CBM"></digi-hawb-weight-measures-section>
              <div class="ui-g ui-fluid">
                <div class="ui-g form-group">
                  <div class="ui-g-12 ui-md-12"
                       *ngIf="!isSeaForwarding() && isRoadTransportDocument() || isRailTransportDocument()">
                <span class="md-inputfield">
                  <input type="text"
                         pInputText
                         [(ngModel)]="selectedContainer.noOfPackages">
                  <label>No of Packages</label>
                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12"
                       *ngIf="!isSeaForwarding() && isRoadTransportDocument() || isRailTransportDocument()">
                <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="selectedContainer.nettWeight"
                         pInputText>
                  <label>Net Weight KG</label>
                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12"
                       *ngIf="isRoadTransportDocument() || isRailTransportDocument()">
                <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="selectedContainer.cbm"
                         pInputText>
                  <label>CBM</label>
                </span>
                  </div>
                  <div class="ui-g-12 ui-md-12"
                       *ngIf="!isSeaForwarding() && isRoadTransportDocument()">
                <span class="md-inputfield">
                  <input type="text"
                         [(ngModel)]="selectedContainer.freightTons"
                         pInputText>
                  <label>Freight tons</label>
                </span>
                  </div>
                </div>
              </div>
            </p-panel>
          </div>
          <div class="ui-g-4">
            <p-panel header="General SOLAS">
              <digi-container-solas-section [selectedTransportDocument]="selectedTransportDocument"></digi-container-solas-section>
            </p-panel>
          </div>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g">
          <div class="ui-g-4">
            <p-panel header="General Seal Section">
              <digi-container-size-section *ngIf="isSeaForwarding() || isRoadAndBulk()"></digi-container-size-section>
              <digi-seal-detail-section></digi-seal-detail-section>
            </p-panel>
          </div>
          <div class="ui-g-4" *ngIf="isSeaForwarding() || isSeaTransportDocument() || isRailTransportDocument()">
            <p-panel header="General Terminal & Depot Section" class="pb-2">
              <digi-app-terminal></digi-app-terminal>
              <digi-app-depot *ngIf="!isRailTransportDocument()"></digi-app-depot>
            </p-panel>
          </div>
          <div class="ui-g-4" *ngIf="isSeaForwarding() || isSeaTransportDocument() || isRailTransportDocument()">
            <p-panel header="General MAS Section">
              <digi-mas-container-size></digi-mas-container-size>
              <digi-mas-container-type></digi-mas-container-type>
            </p-panel>
          </div>
          <div class="ui-g-4" *ngIf="!isSeaForwarding() && isRoadTransportDocument()">
            <p-panel header="General Storage Section" class="pb-2">
              <digi-road-storage-details [selectedContainer]="selectedContainer"
                                         [clearingFile]="clearingFile"></digi-road-storage-details>
            </p-panel>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>



