import {Component, OnDestroy, OnInit} from '@angular/core';
import {AgentService} from '../../../services/agent.service';
import {ClearingFile} from '../../../models/clearingFile';
import {Address, ClearingInstruction, Guarantor, LocalAgent, Remover} from '../../../models/file';
import {ClearingInstructionBehaviorSubject} from '../../../../../subjects/clearingInstruction-behavior-subject';
import {combineLatest, EMPTY, Subscription} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {RemoverService} from '../../../services/remover.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {SelectItem} from 'primeng/primeng';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'digi-app-remover',
  templateUrl: './remover.component.html',
  styleUrls: ['./remover.component.scss']
})
export class RemoverComponent implements OnInit, OnDestroy {
  filteredRemovers: Remover[];
  subscription: Subscription;
  subscribe: Subscription;
  clearingFile: ClearingFile;
  clearingInstruction: ClearingInstruction;
  removers: Remover[];
  removerAddresses = [];
  display: boolean;
  roles: SelectItem[];
  selectedRole = 'Remover';

  constructor(private localAgentService: AgentService,
              private removerService: RemoverService,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private messageService: NotificationService
  ) {
    this.roles = [
      {label: 'Remover', value: 'Remover'},
      {label: 'Local Agent', value: 'Local Agent'},
    ];
  }

  ngOnInit() {
    const clearingFileObservable = this.clearingFileBehaviourSubject.getClearingFile();
    const instructionsObservable = this.clearingInstructionBehaviorSubject.getInstruction();
    const removerObservable = this.clearingInstructionBehaviorSubject.getRemovers();
    this.subscription = combineLatest(clearingFileObservable, instructionsObservable, removerObservable).pipe(
      tap(
        (tuple: any[]) => {
          if (tuple[0]) {
            this.clearingFile = tuple[0];
          }
          if (tuple[1]) {
            this.clearingInstruction = tuple[1];

          }
          if (tuple[2]) {
            this.removers = tuple[2];
          }
        }),
      switchMap((tuple: any[]) => {
        if (this.clearingInstruction && this.removers && this.clearingInstruction && !this.clearingInstruction.remover) {
          return this.removerService.findRemoverFromClearingInstructionAndRemovers(this.clearingInstruction, this.removers);
        }
        return EMPTY;
      })
    ).subscribe(remover => {
      if (remover) {
        this.clearingInstruction.remover.roleType = this.selectedRole;
        this.removerAddresses = remover[0].addresses;
      }
    });

    if (this.clearingInstruction && this.clearingInstruction.remover
      && this.clearingInstruction.remover.roleType) {
      this.selectedRole = this.clearingInstruction.remover.roleType;
    }
  }

  search(event) {
    if (this.removers.length && this.selectedRole === 'Remover') {
      this.filteredRemovers = [];
      for (let i = 0; i < this.removers.length; i++) {
        const remover: Remover = this.removers[i];
        if (
          remover.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0 ||
          remover.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0
        ) {
          this.filteredRemovers.push(remover);
          this.filteredRemovers = [...this.filteredRemovers];
          console.log(this.filteredRemovers);
        }
      }
    } else if (this.selectedRole === 'Local Agent') {
      const localAgent = [];
      localAgent.push(this.clearingFile.localAgent);
      this.filteredRemovers = localAgent;
    }
  }

  selectRemover(remover) {
    if (this.removers.length && this.selectedRole === 'Remover') {
      this.removerService.findRemoverFromClearingInstruction(this.clearingInstruction, remover)
        .subscribe(result => {
          this.removerAddresses = result[0].addresses;
        });
    } else if (this.selectedRole === 'Local Agent') {
      this.updateSelectedLocalAgentToRemover(remover);
    }
  }

  private updateSelectedLocalAgentToRemover(value) {
    this.clearingInstruction.remover = new Remover();
    this.clearingInstruction.remover.customsCode = value.customsRegCode;
    this.clearingInstruction.remover.code = value.code;
    this.clearingInstruction.remover.name = value.name;
    this.clearingInstruction.remover.label = value.label;
    this.clearingInstruction.remover.bondValue = value.bondValue;
    this.removerAddresses = value.addresses;

    if (this.selectedRole === 'Remover') {
      this.clearingInstruction.remover.roleType = this.selectedRole;
      this.clearingInstruction.remover.address = new Address();
      this.clearingInstruction.remover.address.line1 = value.addresses[0].line1;
      this.clearingInstruction.remover.address.line2 = value.addresses[0].line2;
      this.clearingInstruction.remover.address.line3 = value.addresses[0].line3;
      this.clearingInstruction.remover.address.line4 = value.addresses[0].line4;
      this.clearingInstruction.remover.address.zipCode = value.addresses[0].zipCode;
    } else if (this.selectedRole === 'Local Agent') {
      this.clearingInstruction.remover.roleType = this.selectedRole;
      this.clearingInstruction.remover.address = value.address;
    }
  }

  private removerDetails(code) {
    this.removerService.findRemoverNameOrCodeStartsWith(code).subscribe(remover => {
      if (remover) {
        this.updateSelectedLocalAgentToRemover(remover[0]);
      }
    });
  }

  roleChanged() {
    this.clearingInstruction.remover = new Remover();
    if (this.removers.length === 1 && this.selectedRole === 'Remover') {
      this.removerDetails(this.removers[0].code);
    } else if (this.selectedRole === 'Local Agent') {
      if (this.clearingFile.localAgent.code === undefined) {
        this.clearingInstruction.remover = new Remover();
        this.clearingInstruction.remover.label = null;
        this.messageService.errorNotify('Enter a Local Agent');
      }
      if (this.clearingFile.localAgent.code) {
        this.updateSelectedLocalAgentToRemover(this.clearingFile.localAgent);
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
