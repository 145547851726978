var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
var SupplierInvoiceLine = /** @class */ (function () {
    function SupplierInvoiceLine() {
    }
    return SupplierInvoiceLine;
}());
export { SupplierInvoiceLine };
var CreditorFinancialDetail = /** @class */ (function () {
    function CreditorFinancialDetail() {
    }
    return CreditorFinancialDetail;
}());
export { CreditorFinancialDetail };
var CreditorChargeTypeDetails = /** @class */ (function () {
    function CreditorChargeTypeDetails() {
    }
    return CreditorChargeTypeDetails;
}());
export { CreditorChargeTypeDetails };
var CsvExportInvoice = /** @class */ (function () {
    function CsvExportInvoice() {
    }
    return CsvExportInvoice;
}());
export { CsvExportInvoice };
var InvoiceTransaction = /** @class */ (function () {
    function InvoiceTransaction() {
    }
    return InvoiceTransaction;
}());
export { InvoiceTransaction };
var InvoiceTransactionDetails = /** @class */ (function () {
    function InvoiceTransactionDetails() {
    }
    return InvoiceTransactionDetails;
}());
export { InvoiceTransactionDetails };
var Invoice = /** @class */ (function () {
    function Invoice() {
        this.localAmount = 0;
        this.invoiceDate = moment().format('DD/MM/YYYY');
    }
    return Invoice;
}());
export { Invoice };
var CreditNoteReason = /** @class */ (function () {
    function CreditNoteReason() {
    }
    return CreditNoteReason;
}());
export { CreditNoteReason };
var CreditNote = /** @class */ (function (_super) {
    __extends(CreditNote, _super);
    function CreditNote() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CreditNote;
}(Invoice));
export { CreditNote };
