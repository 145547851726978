<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-md-12">
    <p-panel header="Local Agent Details">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text" value="{{businessEntity.code}}"
                                   pInputText readonly>
                            <label>Local Agent Code</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text" value="{{businessEntity.name}}"
                                   pInputText readonly>
                            <label>Local Agent Name</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
                    <span class="md-inputfield">
                        <span class="ui-float-label">
                            <p-autoComplete id="depot"
                                            [(ngModel)]="localAgent.depotTerminal"
                                            [dropdown]="true"
                                            field="label"
                                            [suggestions]="filteredDepotTerminals"
                                            (completeMethod)="searchDeportTerminal($event)"
                                            [autoHighlight]="true" minLength="1"
                                            [forceSelection]="true"
                                            required=true>
                                <ng-template let-option pTemplate="item">
                                    <div>{{option.code}}: {{option.name}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <label for="depot">Degroup/Transit Depot</label>
                        </span>
                    </span>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text" [(ngModel)]="localAgent.customsRegCode"
                                   pInputText>
                            <label>Customs Registration Code</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
          <textarea [(ngModel)]="localAgent.warningMessage" [rows]="3" [cols]="30" placeholder="WarningMessage"
                    autoResize="autoResize" pInputTextarea></textarea>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text" [(ngModel)]="localAgent.iataCode"
                                   pInputText>
                            <label>IATA Code</label>
                        </span>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-12 ui-md-12">
    <div class="ui-g-12 ui-md-4">
      <button pButton type="button" (click)="createNewCredtiorLink()" label="Link new Creditor" icon="fa fa-file">
      </button>
    </div>
  </div>
  <div class="ui-g-12 ui-md-6">
    <p-table [value]="localAgent?.localAgentCustomOfficeCreditors"
             [(selection)]="selectedLocalAgentCustomOffice"
             selectionMode="single"
             (onRowSelect)="onCreditorRoleSelected($event)"
             (onRowUnselect)="onCreditorRoleUnSelected($event)">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" colspan="4">Customs Office</th>
          <th scope="col" colspan="4">Creditor</th>
          <th scope="col" colspan="4">Financial Acc No(FAN)</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
          <td scope="row" colspan="4">
            <label>{{rowData?.customsOffice.districtOfficeName}}</label>
          </td>
          <td scope="row" colspan="4">
            <label>{{rowData.creditorNominated?.businessEntityName}}</label>
          </td>
          <td scope="row" colspan="4">
            <label>{{rowData?.financialAccountNo}}</label>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="12">
            <label>No Creditors</label>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="ui-g-12 ui-md-6" *ngIf="showCreditorDetalsPanel">
    <p-panel header="Creditor And Other Details">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-6">
                    <span class="ui-float-label">
                        <p-autoComplete id="office" class="ng-dirty ng-invalid"
                                        [(ngModel)]="localAgentCustomOffice.customsOffice"
                                        field="districtOfficeName"
                                        [suggestions]="filteredCustomsOffices"
                                        (completeMethod)="searchCustomOffice($event)"
                                        [autoHighlight]="true" minLength="3"
                                        [dropdown]="true">
                            <ng-template let-option pTemplate="item">
                                <div>
                                    {{option.districtOfficeCode}}:{{option.districtOfficeName}}
                                </div>
                            </ng-template>
                        </p-autoComplete>
                        <label for="office">Custom Office</label>
                    </span>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="ui-float-label">
                            <p-autoComplete
                              [suggestions]="filteredBusinessEntity"
                              [(ngModel)]="localAgentCustomOffice.creditorNominated"
                              field="label"
                              [minLength]="3"
                              (onSelect)="selectBusinessEntity($event)"
                              (completeMethod)="searchEntityByRole($event)"
                              [dropdown]="true">
                                <ng-template let-option pTemplate="item">
                                    <div>{{option.code}}:{{option.name}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <label>Search Creditor</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
                        <span class="ui-float-label">
                    <p-autoComplete [(ngModel)]="localAgentCustomOffice.paymentType" field="label"
                                    [autoHighlight]="true" [dropdown]="true" [suggestions]="paymentTypes"
                                    (completeMethod)="filterPaymentTypes($event)"
                                    [forceSelection]="true">
                        <ng-template let-option pTemplate="item">
                            <div>{{option.paymentCode}}:{{option.paymentDesc}}</div>
                        </ng-template>
                    </p-autoComplete>
                    <label>Payment Code</label>
                        </span>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="localAgentCustomOffice.customsBoxNo"
                                   pInputText>
                            <label>Customs Box</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="localAgentCustomOffice.financialAccountNo"
                                   pInputText>
                            <label>Financial Account No:</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="localAgentCustomOffice.deferredStartDay"
                                   pInputText>
                            <label>Deferred Start Day</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="localAgentCustomOffice.deferredEndDay"
                                   pInputText>
                            <label>Deferred End Day</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="localAgentCustomOffice.deferredPaymentDay"
                                   pInputText>
                            <label>Deferred Payment Day</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="localAgentCustomOffice.deferredLimit"
                                   pInputText>
                            <label>Deferred Limit</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="localAgentCustomOffice.deferredBalance"
                                   pInputText>
                            <label>Deferred Balance</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="localAgentCustomOffice.waringLimit"
                                   pInputText>
                            <label>Warning Limit</label>
                        </span>
          </div>
        </div>
      </div>
    </p-panel>
  </div>
  <div class="ui-g-12 ui-md-12">
    <div class="ui-g-12 ui-md-4">
      <button pButton type="button" (click)="createNewBondLink()" label="Add Bond No" icon="fa fa-file">
      </button>
    </div>
  </div>
  <div class="ui-g-12 ui-md-6">
    <p-table selectionMode="single"
             [value]="localAgent?.localAgentCustomOfficeBonds"
             [(selection)]="selectedLocalAgentCustomBond"
             (onRowSelect)="onBondRoleSelected($event)"
             (onRowUnselect)="onBondRoleUnSelected($event)">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" colspan="4">Customs Office</th>
          <th scope="col" colspan="4">Bond No</th>
          <th scope="col" colspan="4">Expiry Date</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
          <td scope="row" colspan="4">
            <label>{{rowData?.customsOffice.districtOfficeName}}</label>
          </td>
          <td scope="row" colspan="4">
            <label>{{rowData?.bondNo}}</label>
          </td>
          <td scope="row" colspan="4">
            <label>{{rowData?.expiryDate}}</label>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="12">
            <label>No Bonds</label>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="ui-g-12 ui-md-6" *ngIf="showBondDetalsPanel">
    <p-panel header="Creditor And Other Details">
      <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-6">
                    <span class="ui-float-label">
                        <p-autoComplete id="customOffice" class="ng-dirty ng-invalid"
                                        [(ngModel)]="localAgentCustomOfficeBond.customsOffice"
                                        field="label"
                                        [suggestions]="filteredCustomsOffices"
                                        (completeMethod)="searchCustomOffice($event)"
                                        [autoHighlight]="true" minLength="3"
                                        [dropdown]="true">
                        </p-autoComplete>
                        <label for="customOffice">Custom Office</label>
                    </span>
        </div>
        <div class="ui-g-12 ui-md-6">
          <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input type="text"
                                   [(ngModel)]="localAgentCustomOfficeBond.bondNo"
                                   pInputText>
                            <label>Bond No (A,B etc)</label>
                        </span>
          </div>
        </div>
        <div class="ui-g-12 ui-md-6">
      <span class="md-inputfield">
             <span class="ui-float-label">
                <span class="md-inputfield">
                   <p-inputMask id="expiryDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                [(ngModel)]="localAgentCustomOfficeBond.expiryDate">
                   </p-inputMask>
                  <label for="expiryDate">Expiry Date (dd/mm/yyyy)</label>
                </span>
             </span>
      </span>
        </div>
      </div>
    </p-panel>
  </div>
</div>
