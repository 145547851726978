import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ExbondDashboardComponent} from './exbond-dashboard/exbond-dashboard.component';
import {ExbondRegisterComponent} from './exbond-register/exbond-register.component';
import {RouterModule} from '@angular/router';
import {EXBOND_ROUTES} from './digi-exbond.routes';
import {DigiCommonModule} from '../digi-common/digi-common.module';
import {
  AutoCompleteModule, ButtonModule, CheckboxModule, DialogModule,
  InputMaskModule, InputTextareaModule, InputTextModule,
  OverlayPanelModule,
  PanelModule,
  PickListModule,
  StepsModule,
  TabViewModule, ToolbarModule, TooltipModule
} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import { ExbondFileSectionComponent } from './exbond-file-section/exbond-file-section.component';
import { ExbondLinesComponent } from './exbond-lines/exbond-lines.component';
import {TableModule} from 'primeng/table';
import {FileUploadModule} from 'primeng/fileupload';
import { GenerateComponent } from './generate/generate.component';
import { ExbondFileSearchComponent } from './exbond-file-search/exbond-file-search.component';
import { BoeFileSectionComponent } from './boe-file-section/boe-file-section.component';
import { FinancialsComponent } from './financials/financials.component';
import { DocumentsComponent } from './exbond-documents/documents.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(EXBOND_ROUTES),
    DigiCommonModule,
    StepsModule,
    TabViewModule,
    PanelModule,
    PickListModule,
    InputMaskModule,
    FormsModule,
    AutoCompleteModule,
    OverlayPanelModule,
    ToolbarModule,
    ButtonModule,
    TableModule,
    FileUploadModule,
    DialogModule,
    InputTextModule,
    TooltipModule,
    InputTextareaModule,
    CheckboxModule,
  ],
  declarations: [ExbondDashboardComponent, ExbondRegisterComponent, ExbondFileSectionComponent, ExbondLinesComponent, GenerateComponent,
    ExbondFileSearchComponent,
    BoeFileSectionComponent,
    FinancialsComponent,
    DocumentsComponent]
})
export class DigiExbondModule { }
