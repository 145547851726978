<div class="ui-g form-group" *ngIf="clearingFile">
  <div class="ui-g-12 ui-md-12">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
    <p-dropdown [options]="roles" [(ngModel)]="selectedRole" (onChange)="roleChanged()" [autoWidth]="false"></p-dropdown>
         <label>Select Role for Remover</label>
      </span>
    </div>
  </div>
  <div class="ui-g-12 ui-md-12">
        <span class="md-inputfield" *ngIf="selectedRole === 'Remover'">
    <div class="ui-inputgroup">
      <span class="ui-float-label">
         <p-autoComplete id="remover"
                         [(ngModel)]="clearingInstruction.remover"
                         field="label"
                         [suggestions]="filteredRemovers"
                         (completeMethod)="search($event)"
                         [autoHighlight]="true"
                         (onSelect)="selectRemover($event)"
                         minLength="3"
                         required=true
         >
        <ng-template let-option pTemplate="item">
          <div>{{option.label}}</div>
        </ng-template>
      </p-autoComplete>
        <label for="remover">Search Remover</label>
      </span>
        <span class="ui-inputgroup-addon"><i class="fa fa-search"></i></span>
    </div>
    <div *ngIf="!clearingInstruction?.remover?.code" class="ui-message ui-messages-error ui-corner-all">
        This field is required
    </div>
    </span>
    <span class="md-inputfield" *ngIf="selectedRole === 'Local Agent'">
        <input
          type="text" pInputText
          [value]="clearingInstruction.remover.label"
          readonly>
        <label>Local Agent</label>
      <div *ngIf="!clearingInstruction?.remover?.code && selectedRole === 'Remover'"
           class="ui-message ui-messages-error ui-corner-all">
      This field is required
    </div>
      <div *ngIf="!clearingInstruction?.remover?.code && selectedRole === 'Local Agent'"
           class="ui-message ui-messages-error ui-corner-all">
      Local Agent not provided
    </div>
      </span>
  </div>
  <digi-address [addresses]="removerAddresses" [address]="clearingInstruction?.remover?.address"></digi-address>
</div>
