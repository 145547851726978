var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ForwardingFileService } from './forwarding-file.service';
import * as i0 from "@angular/core";
import * as i1 from "./forwarding-file.service";
var ForwardingFileValidationService = /** @class */ (function () {
    function ForwardingFileValidationService(forwardingFileService) {
        this.forwardingFileService = forwardingFileService;
    }
    ForwardingFileValidationService.prototype.validateManifests = function (forwardingFile, transportMethod) {
        return __awaiter(this, void 0, void 0, function () {
            var validationResult, validationResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(transportMethod.toLowerCase() === 'air')) return [3 /*break*/, 2];
                        validationResult = this.validateFlightInformationForAir(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        validationResult = this.validateAirportInformationForAir(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        validationResult = this.validateAgentInformationForAir(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        validationResult = this.validateCustomsOfficeForAir(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        validationResult = this.validateWeightsAndMeasuresForAir(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        return [4 /*yield*/, this.validateHouseBillNumbersForAir(forwardingFile)];
                    case 1:
                        validationResult = _a.sent();
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        validationResult = this.validateVoyageInformationForSea(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        validationResult = this.validatePortInformationForSea(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        validationResult = this.validateAgentInformationForSea(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        validationResult = this.validateCustomsOfficeForSea(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        validationResult = this.validateWeightsAndMeasuresForSea(forwardingFile);
                        if (!validationResult.isValid) {
                            return [2 /*return*/, validationResult];
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/, { isValid: true, messages: [] }];
                }
            });
        });
    };
    ForwardingFileValidationService.prototype.validateHouseBillNumbersForAir = function (forwardingFile) {
        return __awaiter(this, void 0, void 0, function () {
            var result, errorMessages;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = { isValid: true, messages: [] };
                        return [4 /*yield*/, this.forwardingFileService.validateHouseBills(forwardingFile.houseBills).toPromise()];
                    case 1:
                        errorMessages = _a.sent();
                        if (errorMessages.length) {
                            this.fail(result, errorMessages);
                        }
                        return [2 /*return*/, result];
                }
            });
        });
    };
    ForwardingFileValidationService.prototype.validateWeightsAndMeasuresForAir = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.masterBill.measures.numberOfPackages) {
            this.fail(result, 'Please provide No. of Packages.');
        }
        if (!forwardingFile.masterBill.measures.grossWeight) {
            this.fail(result, 'Please provide Gross Weight (kg).');
        }
        if (!forwardingFile.masterBill.measures.chargeableWeight) {
            this.fail(result, 'Please provide Chargeable Weight (kg).');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.validateCustomsOfficeForSea = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.customOffice) {
            this.fail(result, 'Please select Custom Office');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.validateAgentInformationForSea = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.masterBill.localForwarder) {
            this.fail(result, 'Please select Forwarding Agent.');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.validatePortInformationForSea = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.masterBill.departurePort.code) {
            this.fail(result, 'Please select Departure Port.');
        }
        if (!forwardingFile.masterBill.docsIssuedAtPort.code) {
            this.fail(result, 'Please select Docs Issued At Port.');
        }
        if (!forwardingFile.masterBill.dischargePort.code) {
            this.fail(result, 'Please select Discharge Port.');
        }
        if (!forwardingFile.masterBill.destinationPort.code) {
            this.fail(result, 'Please select Destination Port.');
        }
        if (!forwardingFile.masterBill.callPurpose) {
            this.fail(result, 'Please select Call Purpose.');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.validateVoyageInformationForSea = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.masterBill.oceanLineDetails.oceanLine.code) {
            this.fail(result, 'Please select Ocean BOL No.');
        }
        if (!forwardingFile.masterBill.mawbDate) {
            this.fail(result, 'Please provide SOB Date.');
        }
        if (!forwardingFile.masterBill.departureDate) {
            this.fail(result, 'Please provide E.T.S');
        }
        if (!forwardingFile.masterBill.arrivalDate) {
            this.fail(result, 'Please provide E.T.A');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.validateWeightsAndMeasuresForSea = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.masterBill.measures.numberOfPackages) {
            this.fail(result, 'Please provide No. of Packages.');
        }
        if (!forwardingFile.masterBill.measures.grossWeight) {
            this.fail(result, 'Please provide Gross Weight (kg).');
        }
        if (!forwardingFile.masterBill.measures.chargeableWeight) {
            this.fail(result, 'Please provide Chargeable Weight (kg).');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.validateCustomsOfficeForAir = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.customOffice.districtOfficeCode) {
            this.fail(result, 'Please select Custom Office.');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.validateAgentInformationForAir = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.masterBill.localForwarder) {
            this.fail(result, 'Please select Forwarding Agent.');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.validateAirportInformationForAir = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.masterBill.departurePort.code) {
            this.fail(result, 'Please select Departure Airport.');
        }
        if (!forwardingFile.masterBill.destinationPort.code) {
            this.fail(result, 'Please select Destination Airport.');
        }
        if (!forwardingFile.masterBill.callPurpose) {
            this.fail(result, 'Please select Call Purpose.');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.validateFlightInformationForAir = function (forwardingFile) {
        var result = { isValid: true, messages: [] };
        if (!forwardingFile.masterBill.airline) {
            this.fail(result, 'Please select Airline');
        }
        if (!forwardingFile.masterBill.masterAirline) {
            this.fail(result, 'Please select Master CC');
        }
        if (forwardingFile.masterBill.mawbNo) {
            var validMawbNo = forwardingFile.masterBill.mawbNo.split('').length;
            if (validMawbNo !== 8) {
                this.fail(result, 'MAWB No should be at least 8 digits long');
            }
        }
        else {
            this.fail(result, 'Please provide MAWB No');
        }
        if (!forwardingFile.masterBill.mawbDate) {
            this.fail(result, 'Please provide MAWB Date');
        }
        if (!forwardingFile.masterBill.flight) {
            this.fail(result, 'Please provide Flight No.');
        }
        if (!forwardingFile.masterBill.departureDate) {
            this.fail(result, 'Please provide Departure Date');
        }
        if (!forwardingFile.masterBill.arrivalDate) {
            this.fail(result, 'Please provide Arrival Date');
        }
        return result;
    };
    ForwardingFileValidationService.prototype.fail = function (result, message) {
        result.isValid = false;
        result.messages.push(message);
    };
    ForwardingFileValidationService.ngInjectableDef = i0.defineInjectable({ factory: function ForwardingFileValidationService_Factory() { return new ForwardingFileValidationService(i0.inject(i1.ForwardingFileService)); }, token: ForwardingFileValidationService, providedIn: "root" });
    return ForwardingFileValidationService;
}());
export { ForwardingFileValidationService };
