import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClearingFile} from '../../../models/clearingFile';
import {Address, ClearingInstruction, Guarantor, LocalAgent, Remover, Warehouse} from '../../../models/file';
import {combineLatest, EMPTY, Subscription} from 'rxjs';
import {AgentService} from '../../../services/agent.service';
import {GuarantorService} from '../../../services/guarantor.service';
import {switchMap, tap} from 'rxjs/operators';
import {ClearingInstructionBehaviorSubject} from '../../../../../subjects/clearingInstruction-behavior-subject';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import * as moment from 'moment';
import _date = moment.unitOfTime._date;
import {RemoverService} from '../../../services/remover.service';
import {SelectItem} from 'primeng/primeng';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'digi-guarantor',
  templateUrl: './guarantor.component.html',
  styleUrls: ['./guarantor.component.scss']
})
export class GuarantorComponent implements OnInit, OnDestroy {
  filteredGuarantors: Guarantor[];
  subscription: Subscription;
  subscribe: Subscription;
  clearingFile: ClearingFile;
  clearingInstruction: ClearingInstruction;
  guarantorAddresses = [];
  display: boolean;
  roles: SelectItem[];
  selectedRole = 'Remover';
  removers: Remover[];

  constructor(private localAgentService: AgentService,
              private guarantorService: GuarantorService,
              private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private removerService: RemoverService,
              private messageService: NotificationService) {
    this.roles = [
      {label: 'Remover', value: 'Remover'},
      {label: 'Local Agent', value: 'Local Agent'},
    ];
  }

  ngOnInit() {
    const clearingFileObservable = this.clearingFileBehaviourSubject.getClearingFile();
    const instructionsObservable = this.clearingInstructionBehaviorSubject.getInstruction();
    const removerObservable = this.clearingInstructionBehaviorSubject.getRemovers();
    this.subscription = combineLatest(clearingFileObservable, instructionsObservable, removerObservable).pipe(
      tap(
        (tuple: any[]) => {
          if (tuple[0]) {
            this.clearingFile = tuple[0];
          }
          if (tuple[1]) {
            this.clearingInstruction = tuple[1];
          }
          if (tuple[2]) {
            this.removers = tuple[2];
          }
        })
    ).subscribe(guarantor => {
    });
    if (this.clearingInstruction && this.clearingInstruction.guarantor
      && this.clearingInstruction.guarantor.roleType) {
      this.selectedRole = this.clearingInstruction.guarantor.roleType;
    }
    this.roleChanged();
  }

  search(event) {
    if (this.selectedRole === 'Remover') {
      this.filteredGuarantors = [];
      for (let i = 0; i < this.removers.length; i++) {
        const remover: Remover = this.removers[i];
        if (
          remover.name.toLowerCase().indexOf(event.query.toLowerCase()) === 0 ||
          remover.code.toLowerCase().indexOf(event.query.toLowerCase()) === 0
        ) {
          this.filteredGuarantors.push(remover);
          this.filteredGuarantors = [...this.filteredGuarantors];
        }
      }
    } else if (this.selectedRole === 'Local Agent') {
      const localAgent = [];
      localAgent.push(this.clearingFile.localAgent);
      this.filteredGuarantors = localAgent;
    }
  }

  selectGuarantor(value) {
    if (this.selectedRole === 'Local Agent') {
      this.removerDetails(value.code);
    } else if (this.selectedRole === 'Remover') {
      this.removerDetails(value.code);
    }
  }

  private removerDetails(code) {
    this.removerService.findRemoverNameOrCodeStartsWith(code).subscribe(remover => {
      if (remover) {
        this.updateSelectedLocalAgentOrRemoverAsGuarantor(remover[0]);
      } else {
        if (this.selectedRole === 'Local Agent') {
          this.messageService.errorNotify('Please add Remover for Local Agent');
        }
      }
    }, error => {
      this.messageService.errorNotify('warning ', error.error.message);
    });
  }

  private updateSelectedLocalAgentOrRemoverAsGuarantor(value) {
    this.clearingInstruction.guarantor = new Guarantor();
    this.clearingInstruction.guarantor.address = new Address();
    this.clearingInstruction.guarantor.code = value.code;
    this.clearingInstruction.guarantor.name = value.name;
    this.clearingInstruction.guarantor.label = value.label;
    this.clearingInstruction.guarantor.bondValue = value.bondValue;
    this.clearingInstruction.guarantor.customsCode = value.customsCode;
    this.guarantorAddresses = value.addresses;
    this.clearingInstruction.guarantor.roleType = this.selectedRole;

    this.clearingInstruction.guarantor.address.line1 = value.addresses[0].line1;
    this.clearingInstruction.guarantor.address.line2 = value.addresses[0].line2;
    this.clearingInstruction.guarantor.address.line3 = value.addresses[0].line3;
    this.clearingInstruction.guarantor.address.line4 = value.addresses[0].line4;
    this.clearingInstruction.guarantor.address.zipCode = value.addresses[0].zipCode;
  }

  roleChanged() {
    if (this.clearingInstruction) {
      this.clearingInstruction.guarantor = new Guarantor();
    }
    if (this.removers && this.removers.length === 1 && this.selectedRole === 'Remover') {
      this.removerDetails(this.removers[0].code);
    } else if (this.selectedRole === 'Local Agent') {
      this.clearingInstruction.guarantor = new Guarantor();
      this.clearingInstruction.guarantor.label = null;
      if (this.clearingFile.localAgent.code === undefined) {
        this.messageService.errorNotify('Enter a Local Agent');
      }
      if (this.clearingFile.localAgent.code) {
        this.removerDetails(this.clearingFile.localAgent.code);
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
