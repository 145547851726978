import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SchedulePart, SupplierInvoiceLine, TariffCode, TariffFormula} from '../../models/file';
import {Subscription} from 'rxjs';
import {SupplierInvoiceLineBehaviourSubject} from '../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {SupplierInvoiceBehaviourSubject} from '../../../../subjects/supplierInvoice-behaviour-subject';
import {SchedulePartService} from '../../services/schedule-part.service';
import {el} from '@angular/platform-browser/testing/src/browser_util';

@Component({
  selector: 'digi-additional-schedule-tariff',
  templateUrl: './additional-schedule-tariff.component.html',
  styleUrls: ['./additional-schedule-tariff.component.scss']
})
export class AdditionalScheduleTariffComponent implements OnInit, OnChanges {
  @Input('clearingInstruction') clearingInstruction;
  @Input('tariffCode') tariffCode: TariffCode;
  @Input('selectedAdditionalScheduleTariffs') selectedAdditionalScheduleTariffs: TariffCode[];
  @Output() selectedAdditionalScheduleTariffsChange = new EventEmitter<TariffCode[]>();
  subscription: Subscription;
  cols: any;
  dutyCols: any;
  additionalScheduleTariffs: TariffCode[];
  searchedAdditionalScheduleTariffs: TariffCode[];
  rebateTariffs: TariffCode[];
  parameters: TariffCode[];
  display = false;
  scheduleParts: TariffCode[];
  selectedRebate: TariffCode;

  constructor(private supplierInvoiceLineBehaviourSubjectComponent: SupplierInvoiceLineBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private schedulePartService: SchedulePartService
  ) {
  }

  ngOnInit() {
    this.rebateTariffs = [];
    this.searchedAdditionalScheduleTariffs = [];
    this.additionalScheduleTariffs = [];
    this.selectedRebate = new TariffCode();

    this.updateRebateTableDisplay();
    this.updateTableDisplay();

    this.cols = [
      {field: 'scheduleCode', header: '', width: '5%'},
      {field: 'scheduleCode', header: 'Schedule', width: '5%'},
      {field: 'dependantCode', header: 'Rebate Code', width: '15%'},
      {field: 'dependantCheckDigit', header: 'Check Digit', width: '5%'},
      {field: 'scheduleDescription', header: 'Description', width: '30%'},
      {field: 'description', header: 'Formula', width: '20%'}
    ];

    this.dutyCols = [
      {field: '', header: '', width: '2%'},
      {field: 'code', header: 'S/P', width: '2%'},
      {field: 'dependantCode', header: 'Dependant Code', width: '8%'},
      {field: 'tariffCheckDigit', header: '', width: '2%'},
      {field: 'tariffSDesc', header: 'Description', width: '30%'},
      {field: 'description', header: 'Formula', width: '20%'}
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
   if (changes.tariffCode && changes.tariffCode.currentValue) {
     this.tariffCode = changes.tariffCode.currentValue;
   }

    if (changes.clearingInstruction && changes.clearingInstruction.currentValue) {
      this.clearingInstruction = changes.clearingInstruction.currentValue;
    }

    if (changes.selectedAdditionalScheduleTariffs && changes.selectedAdditionalScheduleTariffs.currentValue) {
      this.selectedAdditionalScheduleTariffs = changes.selectedAdditionalScheduleTariffs.currentValue;
      this.selectedRebate = new TariffCode();
    }

    this.updateRebateTableDisplay();
    this.updateTableDisplay();
  }

  private updateTableDisplay() {
    this.schedulePartService.findRebateOrScheduleWithCustomsPurposeCodeAndTariffCodeAndChargeRebate(
      this.clearingInstruction.customsPurpose.customsPurposeCode, this.tariffCode.code, 'C')
      .subscribe(data => {
        this.searchedAdditionalScheduleTariffs = data.map((tariffCode) => {
          const additionalSchedule = new TariffCode();
          additionalSchedule.schedulePart = new SchedulePart();
          additionalSchedule.formula = new TariffFormula();
          additionalSchedule.code = tariffCode.code;
          additionalSchedule.checkDigit = tariffCode.tariffCheckDigit;
          additionalSchedule.dependantCode = tariffCode.dependantCode;
          additionalSchedule.schedule = tariffCode.schedule;
          additionalSchedule.fullDescription = tariffCode.fullDescription;
          additionalSchedule.tariff = tariffCode.tariff;
          additionalSchedule.tariffID = tariffCode.tariffID;
          additionalSchedule.dependantCheckDigit = tariffCode.dependantCheckDigit;
          additionalSchedule.schedulePart.code = tariffCode.schedulePart.code;
          additionalSchedule.schedulePart.description = tariffCode.schedulePart.description;
          additionalSchedule.schedulePart.calcOrder = tariffCode.schedulePart.calcOrder;
          additionalSchedule.schedulePart.chargeRebate = tariffCode.schedulePart.chargeRebate;

          if (tariffCode.effectiveTradeAgreementFormulae['STANDARD']) {
            additionalSchedule.formula.description = tariffCode.effectiveTradeAgreementFormulae['STANDARD'].description;
            additionalSchedule.formula.computations = tariffCode.effectiveTradeAgreementFormulae['STANDARD'].computations;
            additionalSchedule.formula.parameters = tariffCode.effectiveTradeAgreementFormulae['STANDARD'].parameters;
          }

          additionalSchedule.parameters = [];
          if (tariffCode.effectiveTradeAgreementParameters['STANDARD']) {
            Object.keys(tariffCode.effectiveTradeAgreementParameters['STANDARD']).forEach(function (effectiveParameterKeys) {
              additionalSchedule.parameters.push(
                  tariffCode.effectiveTradeAgreementParameters['STANDARD'][effectiveParameterKeys]);
            });
          } else {
            additionalSchedule.parameters = [];
          }
          return additionalSchedule;
        });

        this.additionalScheduleTariffs = [...this.searchedAdditionalScheduleTariffs];

        if (this.selectedAdditionalScheduleTariffs) {
          const newArr = [];
          const rebates = this.selectedAdditionalScheduleTariffs.filter(t => t.schedulePart.chargeRebate === 'R');
          this.additionalScheduleTariffs.push(...rebates);
          this.selectedAdditionalScheduleTariffs.forEach(additionalSchedule => {
            const found = this.additionalScheduleTariffs.find(tariff => tariff.schedule === additionalSchedule.schedule);
            if (found) {
              newArr.push(found);
            }
          });
          this.selectedAdditionalScheduleTariffs = [...newArr];
          this.update();
        }

        this.additionalScheduleTariffs = [...this.additionalScheduleTariffs];
      });
  }

  private updateRebateTableDisplay() {
    this.schedulePartService.findRebateOrScheduleWithCustomsPurposeCodeAndTariffCodeAndChargeRebate(
      this.clearingInstruction.customsPurpose.customsPurposeCode, this.tariffCode.code, 'R')
      .subscribe(data => this.scheduleParts = data);
  }

  openRebatesModel() {
    this.display = true;
  }

  onRowSelectRebate(event) {
    const rebate = this.updateRebate(event.data);
    if (this.selectedAdditionalScheduleTariffs) {
      this.selectedAdditionalScheduleTariffs = this.selectedAdditionalScheduleTariffs
        .filter(tariff => !this.rebateTariffs.includes(tariff)
        );
    }
    this.additionalScheduleTariffs.push(rebate);
    this.additionalScheduleTariffs = [...this.additionalScheduleTariffs];

    this.selectedAdditionalScheduleTariffs.push(rebate);
    this.selectedAdditionalScheduleTariffs = [...this.selectedAdditionalScheduleTariffs];
    this.update();
  }

  private updateRebate(data): TariffCode {
    return this.updateSchedulePartFormula(data);
  }

  private updateSchedulePartFormula(data): TariffCode {
    const rebate: TariffCode = data;
    rebate.formula = new TariffFormula();
    rebate.formula.description =
      data.effectiveTradeAgreementRebateFormulae['STANDARD'].description;
    rebate.formula.computations =
      data.effectiveTradeAgreementRebateFormulae['STANDARD'].computations;
    rebate.formula.parameters =
      data.effectiveTradeAgreementRebateFormulae['STANDARD'].parameters;
    rebate.parameters = [];
    if (data.effectiveTradeAgreementParameters.STANDARD) {
      Object.keys(data.effectiveTradeAgreementParameters['STANDARD']).forEach(function (effectiveParameterKeys) {
        rebate.parameters.push(
          data.effectiveTradeAgreementParameters['STANDARD'][effectiveParameterKeys]);
      });
    } else {
      rebate.parameters = [];
    }
    return rebate;
  }

  update() {
    this.selectedAdditionalScheduleTariffsChange.emit(this.selectedAdditionalScheduleTariffs);
  }
}
