import {Inject, Injectable} from '@angular/core';
import {ExBondFile} from '../../digi-common/models/exBondFile';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';
import {ExBondFileBehaviourSubject} from '../../../subjects/exbondfile-behaviour-subject';
import {ClearingFileBehaviourSubject} from '../../../subjects/clearingfile-behaviour-subject';
import {ClearingInstructionBehaviorSubject} from '../../../subjects/clearingInstruction-behavior-subject';
import {ShareDataService} from '../../digi-common/services/share-data.service';
import {BillOfEntryBehaviourSubject} from '../../../subjects/billOfEntry-behaviour-subject';
import {map, tap} from 'rxjs/operators';
import {BillOfEntryLineBehaviourSubject} from '../../../subjects/billOfEntryLine-behaviour-subject';
import {AdditionalInfoBehaviourSubject} from '../../../subjects/addittional-info-behaviour-subject';
import {ExBondBillOfEntryService} from './ex-bond-bill-of-entry.service';
import {ClearingFile} from '../../digi-common/models/clearingFile';

@Injectable({
  providedIn: 'root'
})
export class ExBondFileService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private exBondFileBehaviourSubject: ExBondFileBehaviourSubject,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private clearingInstructionBehaviorSubject: ClearingInstructionBehaviorSubject,
    private shareDataService: ShareDataService,
    private billOfEntryBehaviourSubject: BillOfEntryBehaviourSubject,
    private billOfEntryLineBehaviourSubject: BillOfEntryLineBehaviourSubject,
    private billOfEntryService: ExBondBillOfEntryService,
    private additionalInfoBehaviourSubject: AdditionalInfoBehaviourSubject,
  ) {
  }

  save(exbondFile: ExBondFile): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (!exbondFile.type) {
      exbondFile.type = 'ExBondFile';
    }
    if (exbondFile._links && exbondFile._links.self) {
      return this.http.put(exbondFile._links.self.href, JSON.stringify(exbondFile), {headers})
        .pipe(
          tap((ef: ExBondFile) => this.exBondFileBehaviourSubject.addExBondFile(ef)
          ),
        );
    } else {
      return this.http.post(this.config.filesApiEndpoint + 'api/exbondFiles', JSON.stringify(exbondFile), {headers})
        .pipe(
          tap((ef: ExBondFile) => this.exBondFileBehaviourSubject.addExBondFile(ef)
          ),
        );
    }
  }

  selectExBondFile(value) {
    this.createNewFile();
    this.exBondFileBehaviourSubject.addExBondFile(value);
    if (value.clearingInstructions.length) {
      this.clearingInstructionBehaviorSubject.addClearingInstructions(value.clearingInstructions);
    }

    this.billOfEntryService
      .findBillOfEntryByExBondFile_id(value.id)
      .subscribe(boes => {
        if (boes.length > 0) {
          this.billOfEntryBehaviourSubject.addBillOfEntry(boes[0]);
          if (boes[0].lines.length) {
            this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(boes[0].lines[0]);
            this.additionalInfoBehaviourSubject.addAdditionalInfo(boes[0].lines[0].additionalInformation);
          }
          this.billOfEntryBehaviourSubject.addBillsOfEntry(boes);
        }
      });
  }

  createNewFile() {
    this.exBondFileBehaviourSubject.addExBondFile(this.shareDataService.buildExBondFile());
    this.clearingInstructionBehaviorSubject.addClearingInstructions([]);
    this.billOfEntryBehaviourSubject.addBillOfEntry(this.shareDataService.buildBillOfEntry('exbond'));
    this.billOfEntryBehaviourSubject.addBillsOfEntry([]);
    this.billOfEntryLineBehaviourSubject.addBillOfEntryLine(null);
  }

  loadExBondFile(fileNumber: String): Observable<any> {
    if (fileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/exbondFiles/search/fileNumberStartsWith?fileNumber=` + fileNumber)
      .pipe(
        map(response => response['_embedded'].exbondFiles)
      );

  }

  findAllSequences(clearingFileNumber: String): Observable<any> {
    if (clearingFileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/exBondFileCorrections/search/findSequencesByClearingFileNumber?clearingFileNumber=`
        + clearingFileNumber + '&projection=sequenceAndClearingFileNumber')
      .pipe(
        map(response => response['_embedded'].exBondFileCorrections)
      );
  }

  findByClearingFileNumberAndSequence(fileNumber: String, sequence: number): Observable<any> {
    if (fileNumber === '' && sequence === null) {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/exBondFileCorrections/search/clearingFileNumberAndSequence?clearingFileNumber=` + fileNumber
        + '&sequence=' + sequence)
      .pipe(
        map(response => response['_embedded'].exBondFileCorrections)
      );
  }

  loadAllCorrectionsIncludingOriginalExBondFile(clearingFileNumber: string): Observable<ExBondFile[]> {
    if (clearingFileNumber === '') {
      return of([]);
    }
    return this.http
      .get(`${this.config.filesApiEndpoint}api/exBondFileCorrections/search/findByClearingFileNumber?clearingFileNumber=${clearingFileNumber}`)
      .pipe(
        map(response => response['_embedded'].exBondFileCorrections)
      );
  }
}

