<div class="ui-g-12">
  <p-panel header="Additional Duties and Rebates">
    <div class="ui-g ui-fluid">
      <div class="ui-g-8">
        <span>Select Any Specific Additional Schedules to be Applied.</span>
      </div>
      <div class="ui-g-12 ui-md-3">
        <div class="ui-inputgroup">
          <button pButton type="button" label="Select Rebate"
                  (click)="openRebatesModel()">
          </button>
        </div>
      </div>
      <p-table #additionalSchedules [columns]="cols" [value]="additionalScheduleTariffs"
               [(selection)]="selectedAdditionalScheduleTariffs"
               selectionMode="multiple"
               dataKey="tariffID"
               (onRowSelect)="update()"
               (onRowUnselect)="update()"
               [resizableColumns]="true"
               [scrollable]="true" scrollHeight="150px">
        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="text" pInputText size="50" placeholder="Global
                Filter"
                   (input)="additionalSchedules.filterGlobal($event.target.value, 'contains')"
                   style="width:auto">
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" [style.width]="col.width"/>
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <td>
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td>
              {{rowData?.schedulePart.code}}
            </td>
            <td>
              {{rowData?.dependantCode}}
            </td>
            <td>
              {{rowData?.dependantCheckDigit}}
            </td>
            <td>
              {{rowData?.schedulePart.description}}
            </td>
            <td>
              {{rowData?.formula?.description}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="12">
              <label>No schedules found</label>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-panel>
  <p-dialog header="Rebates"
            [(visible)]="display"
            [resizable]="false"
            [modal]="true"
            [responsive]="true"
            [height]="500"
            [minY]="80"
            [maximizable]="false"
            [contentStyle]="{'width':'80vw'}"
  >
    <div class="ui-g-12">
      <p-panel header="Additional Duties and Rebates">
        <div class="ui-g ui-fluid">
          <div class="ui-g-8">
            <span>Select Any Specific Additional Duties or Rebates to be Applied.</span>
          </div>
          <p-table #rebates [columns]="cols"
                   [value]="scheduleParts"
                   (onRowSelect)="onRowSelectRebate($event)"
                   [(selection)]="selectedRebate"
                   selectionMode="single"
                   [resizableColumns]="true"
                   [scrollable]="true" scrollHeight="200px">
            <ng-template pTemplate="caption">
              <div style="text-align: right">
                <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                <input type="text" pInputText size="50" placeholder="Global
                Filter"
                       (input)="rebates.filterGlobal($event.target.value, 'contains')"
                       style="width:auto">
              </div>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" [style.width]="col.width"/>
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr class="table-header-schedules">
                <th *ngFor="let col of columns">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <td>
                  <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                </td>
                <td>
                  {{rowData?.schedulePart.code}}
                </td>
                <td>
                  {{rowData?.dependantCode}}
                </td>
                <td>
                  {{rowData?.dependantCheckDigit}}
                </td>
                <td>
                  {{rowData?.schedulePart.description}}
                </td>
                <td>
                  {{rowData?.effectiveTradeAgreementRebateFormulae?.STANDARD?.description}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-panel>
    </div>
  </p-dialog>
</div>

